// Copied from Hocuspocus test utils
export const waitFor =
  (attempts = 10, interval = 20) =>
  async (predicate: () => boolean) => {
    if (predicate()) return Promise.resolve(true)
    let times = 0

    return new Promise((res, rej) => {
      function start() {
        if (predicate()) {
          res(true)
          return
        }
        if (times > attempts) {
          rej(false)
          return
        }
        times++
        setTimeout(start, interval)
      }
      start()
    })
  }

export const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time))
