import { config } from 'config'
import { UAParser } from 'utils/deviceDetection'

type UserMetadata = Record<string, any>

type UTMParamsObject = {
  name?: string
  source?: string
  medium?: string
  term?: string
  content?: string
  [key: string]: string | undefined
}
export const getUtmCampaignData = (url?: string): UTMParamsObject | null => {
  return parseUTMParameters(url)
}

export const parseUTMParameters = (url?: string): UTMParamsObject | null => {
  if (!url) {
    return null
  }
  const params: UTMParamsObject = {}
  const urlParts = url.split('?')

  if (urlParts.length > 1) {
    const queryString = urlParts[1]
    const paramPairs = queryString.split('&')

    for (const pair of paramPairs) {
      const [key, value] = pair.split('=')
      if (key.startsWith('utm_')) {
        const paramName = key.replace('utm_', '')
        if (paramName === 'campaign') {
          // Coerce this to "name" to match the Segment spec
          // https://segment.com/docs/connections/spec/common/#context
          params.name = decodeURIComponent(value)
        } else {
          params[paramName] = decodeURIComponent(value)
        }
      }
    }
  }

  if (Object.keys(params).length === 0) {
    return null
  }
  return params
}

export const getUserMetadataClientSide = (): UserMetadata => {
  if (!config.IS_CLIENT_SIDE) {
    return {}
  }
  const browser = UAParser.getBrowser()
  const device = UAParser.getDevice()
  const os = UAParser.getOS()
  const DEFAULT_DATA = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: navigator.userAgent,
    locale: navigator.language,
    browser: {
      name: browser.name,
      version: browser.version,
    },
  } as UserMetadata

  if (browser) {
    DEFAULT_DATA['browser'] = {
      name: browser.name,
      version: browser.version,
    }
  }
  if (device && device.type) {
    if (!('device' in DEFAULT_DATA)) {
      DEFAULT_DATA.device = {}
    }
    DEFAULT_DATA.device.type ||= device.type
    DEFAULT_DATA.device.model ||= device.model
  }

  if (os) {
    if (!('os' in DEFAULT_DATA)) DEFAULT_DATA['os'] = {}
    DEFAULT_DATA['os'].name ||= os.name
    DEFAULT_DATA['os'].version ||= os.version
  }

  return DEFAULT_DATA
}
