import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type CreditsState = {
  availableCredits: null | number
}

const initialState: CreditsState = {
  availableCredits: null,
}

const CreditsSlice = createSlice({
  name: 'Credits',
  initialState,
  reducers: {
    reset: () => initialState,
    setCredits(
      state: CreditsState,
      action: PayloadAction<{ availableCredits: number }>
    ) {
      const { availableCredits } = action.payload
      state.availableCredits = availableCredits
    },
  },
})

export const { setCredits, reset } = CreditsSlice.actions

type SliceState = Pick<RootState, 'Credits'>

// Selectors
export const selectAvailableCredits = (state: SliceState) =>
  state.Credits.availableCredits

// Reducer
export const CreditsReducer = CreditsSlice.reducer
