import { NormalizedCacheObject, StoreValue } from '@apollo/client'

export const cacheForEach = (
  cache: NormalizedCacheObject,
  fn: (key: string, value: StoreValue) => void
) => {
  for (const key in cache) {
    const val = cache[key]
    if (key === 'ROOT_QUERY') {
      for (const subKey in val) {
        fn(`${key}.${subKey}`, val[subKey])
      }
    } else {
      fn(key, val)
    }
  }
}
