import * as yaml from 'js-yaml'

import { getTextAmounts } from './constants'
import { TextAmount } from './types'
export const isContentEmpty = (contentMd: string) => {
  return contentMd.replaceAll('---', '').trim() === ''
}

export const countSectionDividers = (contentMd: string) => {
  const sections = contentMd.split('\n---\n')
  return sections.length
}

export const countContentParagraphs = (contentMd: string) => {
  const blocks = contentMd.split(/\n+/)
  return blocks.length
}

export const findTextAmount = (len: TextAmount) => {
  const TEXT_AMOUNTS = getTextAmounts()
  return TEXT_AMOUNTS[len] || TEXT_AMOUNTS.md
}

export const parseFencedCodeResponse = (
  response: string,
  fencedOnly?: boolean
): string => {
  // Remove surrounding text and code fence if present
  // TODO(Jordan) remove this part of the code when i'm not putting output in YAML lol
  const fencedCodeMatch = response.match(
    /```(?:html|xml|gml|text|md)?\n([\s\S]*?)(\n(?:\s*)```|$)/
  )
  const codeString = fencedCodeMatch
    ? fencedCodeMatch[1]
    : fencedOnly
    ? ''
    : response
  // Parse the string
  return codeString.trim()
}
export const parseFencedYamlResponse = (response: string): any => {
  // Remove surrounding text and code fence if present
  const fencedCodeMatch = response.match(/```(?:yaml)?\n([\s\S]*?)\n```/)
  const yamlString = fencedCodeMatch ? fencedCodeMatch[1] : response

  // Parse the YAML string
  try {
    return yaml.load(yamlString)
  } catch (e) {
    throw `Failed to parse YAML response.
      Response:
      ${response}
      Error:
      ${e}
      `
  }
}

// Parse out all <section> elements from the HTML
export const splitDocIntoSections = (response: string): string[] => {
  const dom = new DOMParser().parseFromString(response, 'text/html')
  return Array.from(dom.querySelectorAll('body > section')).map((section) =>
    section.outerHTML.trim()
  )
}
