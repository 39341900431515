import { FeatureFlags } from 'modules/featureFlags'
import { analytics, SegmentEvents } from 'modules/segment'

export const trackExperimentBucketed = <T extends keyof FeatureFlags>(
  flag: T,
  variation: FeatureFlags[T],
  properties?: Record<string, string | number | boolean>
) => {
  analytics?.track(SegmentEvents.EXPERIMENT_BUCKETED, {
    flag,
    variation,
    ...properties,
  })
}
