import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import {
  selectShowProsemirrorDevTools,
  setShowProsemirrorDevTools,
} from '../reducer'

export function useProsemirrorDevToolsSync() {
  const dispatch = useAppDispatch()
  const showProsemirrorDevTools = useAppSelector(selectShowProsemirrorDevTools)

  const [isActive, setActive] = useLocalStorage(
    'showProsemirrorDevTools',
    false
  )

  // Initialize from LS
  useEffect(() => {
    if (isActive) {
      dispatch(setShowProsemirrorDevTools({ showProsemirrorDevTools: true }))
    }
  }, [dispatch, isActive])

  // Keep LS in sync with app state
  useEffect(() => {
    setActive(showProsemirrorDevTools)
  }, [setActive, showProsemirrorDevTools])
}
