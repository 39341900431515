import { Node, NodeType } from 'prosemirror-model'

import {
  CONTENT_WIDTH_PX,
  MAX_IMAGE_HEIGHT_VIEWPORT,
} from 'modules/tiptap_editor/extensions/media/constants'

import {
  ClickBehavior,
  EmbedClickBehavior,
  MediaEmbedAttrs,
  ResizeAttrs,
  VideoEmbedAttrs,
  WebEmbedAttrs,
} from './types'

const MEDIA_NODES = ['image', 'video', 'embed', 'mediaPlaceholder']

export const isMediaNode = (node: Node) => {
  return isMediaNodeType(node.type)
}

export const isMediaNodeType = (type: NodeType) => {
  return MEDIA_NODES.includes(type.name)
}

export const isMediaEmbedNode = (
  node: Node
): node is Node & { attrs: MediaEmbedAttrs } => {
  return node.type.name === 'video' || node.type.name === 'embed'
}

// The source URL is the canonical link you would open in your browser
// Prefer this for interactions like "open this embed in new tab"
export const getMediaSourceUrl = (attrs: Node['attrs']): string | undefined => {
  return (
    attrs.href || attrs.url || attrs.sourceUrl || attrs.embedUrl || undefined
  )
}

// The embed URL is the iframely-friendly link that we can embed
// If we don't have one, fall back to the source URL
export const getMediaEmbedUrl = (attrs: VideoEmbedAttrs | WebEmbedAttrs) => {
  if (attrs.embed?.url) {
    return attrs.embed.url
  }

  if ('embedUrl' in attrs && attrs.embedUrl) {
    return attrs.embedUrl
  }

  return getMediaSourceUrl(attrs)
}

export const getMediaTitle = (node: Node) => {
  const attrs = node.attrs as MediaEmbedAttrs
  return [attrs.meta?.title, attrs.meta?.site].filter(Boolean).join(' - ')
}

export const getEmbedOrVideoClickBehavior = (
  attrs: VideoEmbedAttrs | WebEmbedAttrs,
  defaultNewTab: boolean
): Exclude<EmbedClickBehavior, null> => {
  return attrs.clickBehavior ?? (defaultNewTab ? 'newTab' : 'expand')
}

export const getImageClickBehavior = (
  clickBehavior: ClickBehavior | undefined
): Exclude<ClickBehavior, null> => {
  return clickBehavior ?? 'expand'
}

export const isSVG = (filename?: string | null) => {
  return filename?.split('.').pop()?.startsWith('svg') ?? false
}

export const resizeImageToFitViewport = (
  attrs: Record<string, any>
): Record<string, any> => {
  const { width, height, resize } = attrs.meta || {}
  if (!width || !height || resize?.isAuto === false) return attrs

  const baseWidth = CONTENT_WIDTH_PX
  const baseHeight = (height / width) * baseWidth
  const maxImageHeight = window.innerHeight * MAX_IMAGE_HEIGHT_VIEWPORT

  if (baseHeight > maxImageHeight) {
    const newResize: ResizeAttrs = {
      width: Math.min(baseWidth, maxImageHeight * (width / height)),
      clipType: null,
      clipPath: null,
      clipAspectRatio: null,
      isAuto: true,
    }
    return {
      ...attrs,
      resize: newResize,
    }
  }
  return attrs
}
