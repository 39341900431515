import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { useGammaBreakpoint } from 'utils/breakpoints/useGammaBreakpointValue'

import { selectShowBreakpoint } from '../reducer'

export const BreakpointWidget: React.FC = () => {
  const showBreakpoint = useAppSelector(selectShowBreakpoint)
  const currentBreakpoint = useGammaBreakpoint()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  if (!showBreakpoint) {
    return null
  }

  return (
    <Box
      position="fixed"
      bottom="0px"
      left="100px"
      px="4"
      py="2"
      bg="gray.800"
      color="white"
      zIndex="1500"
      fontSize="xs"
      borderTopRadius="lg"
      _hover={{ opacity: 1 }}
      textAlign="center"
      cursor="default"
    >
      <Flex alignItems="center" gap={2}>
        <Box>{currentBreakpoint}</Box>
        <Box>
          ({dimensions.width}px × {dimensions.height}px)
        </Box>
      </Flex>
    </Box>
  )
}
