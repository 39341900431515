/**
 * Handles Google logins and signups only.
 * Does NOT handle user/password logins and signups.
 */
import jsCookie from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { config } from 'config'
import { useStripQueryParams } from 'modules/history/useStripQueryParams'

import { handleLoginComplete, handleSignupComplete } from '../handleSuccess'

const LOGIN_SUCCESS_COOKIE_NAME = 'gamma_login_success'

export const useHandleLoginOrSignup = () => {
  const { query, isReady } = useRouter()

  const isLoginOrSignupHandled = useRef(false)

  useEffect(() => {
    // Only handle the login/signup flow in the presence of the explicit loginSuccess cookie
    if (
      isReady &&
      jsCookie.get(LOGIN_SUCCESS_COOKIE_NAME) === 'true' &&
      !isLoginOrSignupHandled.current
    ) {
      const {
        newUser,
        isFirstWorkspace,
        welcomeDocId,
        signupFlow,
        newWorkspace,
        userId,
        workspaceId,
      } = query

      const isFirstWorkspaceBoolean = isFirstWorkspace === 'true'
      const newWorkspaceBoolean = newWorkspace === 'true'
      const newUserBoolean = newUser === 'true'
      if (newUserBoolean) {
        handleSignupComplete({
          data: {
            newUser: newUserBoolean,
            userId: userId as string,
            workspaceId: workspaceId as string,
            isFirstWorkspace: isFirstWorkspaceBoolean,
            newWorkspace: newWorkspaceBoolean,
            signupFlow: signupFlow as string | '',
            welcomeDocId: welcomeDocId as string | '',
          },
          googleMethod: 'ONE_TAP',
        })
      } else {
        handleLoginComplete({
          data: {
            newUser: newUserBoolean,
            userId: userId as string,
            workspaceId: workspaceId as string,
            isFirstWorkspace: isFirstWorkspaceBoolean,
            newWorkspace: newWorkspaceBoolean,
            signupFlow: signupFlow as string | '',
            welcomeDocId: welcomeDocId as string | '',
          },
          googleMethod: 'ONE_TAP',
        })
      }
      isLoginOrSignupHandled.current = true
      jsCookie.remove(LOGIN_SUCCESS_COOKIE_NAME, {
        path: '/',
        domain: config.VISITOR_ID_COOKIE_DOMAIN,
        secure: true,
        httpOnly: false,
      })
    }
  }, [isReady, query])

  useStripQueryParams(
    'newUser',
    'newWorkspace',
    'isFirstWorkspace',
    'welcomeDocId',
    'signupFlow',
    'userId',
    'workspaceId'
  )
}
