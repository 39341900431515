import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type ImageEditorSliceState = Pick<RootState, 'ImageEditor'>

type ClipType = 'inset' | 'circle'

type RemoveBgStatus = 'idle' | 'loading' | 'complete' | 'error'

export interface ImageEditorState {
  clipType: ClipType
  isCropping: boolean
  croppingImageId: string | null

  removeBg: Record<string, { status: RemoveBgStatus; url: string | null }>
}

const initialState: ImageEditorState = {
  clipType: 'inset',
  isCropping: false,
  croppingImageId: null,
  removeBg: {},
}

const ImageEditorSlice = createSlice({
  name: 'ImageEditor',
  initialState,
  reducers: {
    reset: () => initialState,
    setClipType(
      state,
      action: {
        payload: { clipType: ClipType }
      }
    ) {
      const { clipType } = action.payload
      state.clipType = clipType
    },
    startCropping(
      state,
      action: {
        payload: {
          id: string
          clipType?: ClipType | null
        }
      }
    ) {
      const { id, clipType } = action.payload
      state.croppingImageId = id
      state.clipType = clipType || 'inset'
    },
    endCropping(state) {
      state.croppingImageId = null
    },
    startRemoveBg(
      state,
      action: {
        payload: {
          id: string
        }
      }
    ) {
      const { id } = action.payload
      state.removeBg[id] = {
        status: 'loading',
        url: null,
      }
    },
    setRemoveBgStatus(
      state,
      action: {
        payload: {
          id: string
          status: RemoveBgStatus
        }
      }
    ) {
      const { id, status } = action.payload
      if (!state.removeBg[id]) return
      state.removeBg[id].status = status
    },
    setRemoveBgUrl(
      state,
      action: {
        payload: {
          id: string
          url: string | null
        }
      }
    ) {
      const { id, url } = action.payload
      if (!state.removeBg[id]) return
      state.removeBg[id].url = url
    },
    endRemoveBg(
      state,
      action: {
        payload: {
          id: string
        }
      }
    ) {
      const { id } = action.payload
      delete state.removeBg[id]
    },
  },
})

export const {
  setClipType,
  endCropping,
  startCropping,
  startRemoveBg,
  setRemoveBgStatus,
  setRemoveBgUrl,
  endRemoveBg,
} = ImageEditorSlice.actions

// Selectors
export const selectClipType = (state: ImageEditorSliceState) =>
  state.ImageEditor.clipType

export const selectIsCropping = (state: ImageEditorSliceState) =>
  !!state.ImageEditor.croppingImageId

export const selectIsIdCropping =
  (id: string) => (state: ImageEditorSliceState) =>
    state.ImageEditor.croppingImageId === id

export const selectIsRemovingBg =
  (id: string) => (state: ImageEditorSliceState) =>
    Boolean(state.ImageEditor.removeBg[id])

export const selectRemoveBgUrl =
  (id: string) => (state: ImageEditorSliceState) =>
    state.ImageEditor.removeBg[id]?.url

export const selectRemoveBgStatus =
  (id: string) => (state: ImageEditorSliceState) =>
    state.ImageEditor.removeBg[id]?.status

// Reducer
export const reducer = ImageEditorSlice.reducer
