import { Badge, Link, Text } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'

import { config } from 'config'

const enabled =
  config.APPLICATION_ENVIRONMENT !== 'production' || config.DEBUG_ENABLED

export const GammaGitSHABadge = () => {
  if (!enabled) return null

  const label = config.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE ? (
    <Text>
      Commit message for this build: <br />
      {config.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE}
    </Text>
  ) : null

  return (
    <GammaTooltip placement="top" label={label}>
      <Link href={config.NEXT_PUBLIC_VERCEL_URL} target="_blank">
        <Badge colorScheme="orange">SHA: {config.GIT_SHA}</Badge>
      </Link>
    </GammaTooltip>
  )
}
