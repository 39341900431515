import { ApolloCache } from '@apollo/client'

import { ApolloCacheEventEmitter, patchCache } from './patchCache'

const DEFAULT_DEBOUNCE = 1000

export default class Trigger<T> {
  paused: boolean

  timeout: ReturnType<typeof setTimeout> | null = null

  emitter: ApolloCacheEventEmitter | null

  cleanup: (() => void) | null

  constructor(
    private readonly opts: {
      cache: ApolloCache<T>
      onPersist: (val: T) => void
      debounce?: number
    }
  ) {
    this.paused = false

    const { emitter, uninstall } = patchCache({ cache: opts.cache })

    emitter.on('write', () => this.fire())
    emitter.on('evict', () => this.fire())
    emitter.on('modify', () => this.fire())
    emitter.on('gc', () => this.fire())

    this.cleanup = () => {
      uninstall()
      this.cleanup = null
      this.paused = true
      if (this.emitter) {
        this.emitter.off('write')
        this.emitter.off('evict')
        this.emitter.off('modify')
        this.emitter.off('gc')
        this.emitter = null
      }
    }
    this.emitter = emitter
  }

  pause(): void {
    this.clearTimeout()
    this.paused = true
  }

  resume(): void {
    this.paused = false
  }

  uninstall(): void {
    if (this.cleanup) {
      this.cleanup()
    }
  }

  fire() {
    if (this.paused) {
      return
    }

    this.clearTimeout()

    const debounceTime = this.opts.debounce || DEFAULT_DEBOUNCE
    this.timeout = setTimeout(() => this.persist(), debounceTime)
  }

  persist() {
    if (this.paused) {
      return
    }
    this.opts.onPersist(this.opts.cache.extract())
  }

  clearTimeout() {
    if (this.timeout != null) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}
