import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef } from '@chakra-ui/system'
import React from 'react'

import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

interface ToolbarButtonProps extends ButtonProps {
  children: React.ReactNode
  icon?: JSX.Element
  onClick?: (e: any) => void
  rightIcon?: JSX.Element
}

export const ToolbarButton = forwardRef(
  (
    {
      children,
      icon,
      onClick,
      rightIcon,
      variant,
      ...rest
    }: ToolbarButtonProps,
    ref: React.MutableRefObject<HTMLButtonElement>
  ) => {
    const size = useGammaBreakpointValue(['xs', 'sm'])

    const hoverBgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100')

    const btnProps =
      variant !== 'solid'
        ? {
            variant: 'ghost',
            colorScheme: 'gray',
            _hover: {
              bg: hoverBgColor,
            },
          }
        : {}

    return (
      <Button
        ref={ref}
        variant={variant}
        fontWeight="normal"
        size={size}
        leftIcon={icon}
        onClick={onClick}
        rightIcon={rightIcon}
        {...btnProps}
        {...rest}
      >
        {children}
      </Button>
    )
  }
)

export const ToolbarIconButton = forwardRef(
  (
    { icon, variant, ...rest }: IconButtonProps,
    ref: React.MutableRefObject<HTMLButtonElement>
  ) => {
    const hoverBgColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100')
    const iconProps =
      variant !== 'solid'
        ? {
            variant: 'ghost',
            colorScheme: 'gray',
            _hover: {
              bg: hoverBgColor,
            },
          }
        : {}

    return (
      <IconButton
        ref={ref}
        variant={variant}
        fontWeight="normal"
        size="sm"
        icon={icon}
        {...iconProps}
        {...rest}
      />
    )
  }
)
