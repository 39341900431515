import jsCookie from 'js-cookie'
import { useEffect } from 'react'

import { config } from 'config'
import {
  LOGGED_IN_COOKIE_MAX_AGE,
  LOGGED_IN_COOKIE_NAME,
} from 'modules/user/utils'

// Set a logged in cookie for vercel custom versions and yarn dev:remote
export const useSetLoggedInCookieForCustomVersions = () => {
  const isStagingAPIHost = config.API_HOST.endsWith('api.staging.gamma.app')
  const isLocalOrCustomVersionURL =
    typeof window !== 'undefined' &&
    (window.location.hostname.match(/localhost/) !== null ||
      window.location.hostname.match(/\.*-gamma-app.vercel.app/) !== null)

  useEffect(() => {
    if (!isStagingAPIHost || !isLocalOrCustomVersionURL) {
      return
    }
    if (jsCookie.get(LOGGED_IN_COOKIE_NAME)) {
      return
    }

    // We dont have a logged in cookie AND were using yarn dev:remote or a custom version
    // Make an API call to `/user` to check if we're logged in to the staging API
    fetch(`${config.API_HOST}/user`, {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        return null
      })
      .then((user) => {
        // Set the logged in cookie since we have confirmed that we're logged in and reload
        // as we should see the dashboard not the marketing page
        if (user) {
          jsCookie.set(LOGGED_IN_COOKIE_NAME, 'true', {
            expires: LOGGED_IN_COOKIE_MAX_AGE,
            secure: true,
            httpOnly: false,
          })
          window.location.reload()
        }
      })
  }, [isStagingAPIHost, isLocalOrCustomVersionURL])
}
