import { NormalizedCacheObject, StoreValue } from '@apollo/client'
import { set, has, unset, get } from 'lodash'

import { OfflineCacheDbAdapter } from './OfflineCacheDbAdapter'
import { cacheForEach } from './utils'

const FOURTEEN_DAYS = 14 * 24 * 60 * 60 * 1000

export class MergeCacheOperation {
  public cache: NormalizedCacheObject & {
    ROOT_QUERY: any
  }

  public cacheTimes: Record<string, number>

  private loaded: Promise<void>

  /**
   * Loads the cache from persistent storage
   */
  constructor(
    private readonly db: OfflineCacheDbAdapter,
    private ttl: number = FOURTEEN_DAYS
  ) {
    this.loaded = this.load()
  }

  /**
   * Write the offline cache back to the db
   */
  public async write() {
    return Promise.all([
      this.db.writeNormalizedCache(this.cache),
      this.db.writeCacheTimes(this.cacheTimes),
    ])
  }

  private async load() {
    const cachePromise = this.db.getNormalizedCache().then((cache) => {
      this.cache = cache
        ? {
            ...cache,
            ROOT_QUERY: cache.ROOT_QUERY ? cache.ROOT_QUERY : {},
          }
        : {
            ROOT_QUERY: {},
          }
    })

    const cacheTimesPromise = this.db.getCacheTimes().then((cacheTimes) => {
      console.debug(
        'offlineMode - [MergeCacheOperation] loaded cache times',
        cacheTimes
      )
      this.cacheTimes = cacheTimes
    })

    return Promise.all([cachePromise, cacheTimesPromise]).then(() => {})
  }

  async merge(
    onlineCache: NormalizedCacheObject,
    opts: {
      expireEntries?: boolean
    } = {}
  ) {
    await this.loaded

    const newEntries: string[] = []

    cacheForEach(onlineCache, (key, value) => {
      if (!this.has(key)) {
        newEntries.push(key)
      }

      this.set(key, value)
    })

    const removed = opts.expireEntries ? this.expireEntries() : null

    console.debug('[ApolloOfflineCache] merged offline <- online cache ', {
      newEntries,
      expiredKeys: removed,
    })

    return this.cache
  }

  protected expireEntries() {
    const now = Date.now()
    const toRemove: string[] = []

    cacheForEach(this.cache, (key) => {
      const cacheTime = this.cacheTimes[key]
      if (!cacheTime) {
        return
      }
      if (cacheTime + this.ttl < now) {
        toRemove.push(key)
      }
    })

    toRemove.forEach((key) => {
      this.del(key)
    })

    return toRemove
  }

  protected has(key: string) {
    return has(this.cache, key)
  }

  protected set(key: string, value: StoreValue) {
    set(this.cache, key, value)
    // cache times is a flat map not a nested object
    this.cacheTimes[key] = Date.now()
  }

  protected get(key: string) {
    return get(this.cache, key)
  }

  protected del(key: string) {
    unset(this.cache, key)
    delete this.cacheTimes[key]
  }
}
