import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FilmstripNavItemData } from 'sections/docs/editor/components/TableOfContents/Filmstrip/dragAndDrop/types'

export interface FilmstripDragAndDropState {
  isDragging: boolean
  allCardIdsWhileDragging: string[]
  selectedCardIdsWhileDragging: string[]
  leaderCardId: string | null
  dragStartIndices: Record<string, number>
}

const initialState: FilmstripDragAndDropState = {
  isDragging: false,
  allCardIdsWhileDragging: [],
  selectedCardIdsWhileDragging: [],
  leaderCardId: null,
  dragStartIndices: {},
}

const FilmstripDragAndDropSlice = createSlice({
  name: 'FilmstripDragAndDrop',
  initialState,
  reducers: {
    startDragging: (
      state,
      action: PayloadAction<{
        cardIds: string[]
        selectedCardIds: string[]
        leaderCardId: string
      }>
    ) => {
      const { cardIds, selectedCardIds, leaderCardId } = action.payload

      state.isDragging = true
      state.allCardIdsWhileDragging = cardIds
      state.leaderCardId = leaderCardId
      state.dragStartIndices = cardIds.reduce((acc, id, index) => {
        acc[id] = index + 1
        return acc
      }, {})
      // Ensure selected cards while dragging are in the same order as they appear in the filmstrip
      const orderedSelectedCardIds = cardIds.filter((id) =>
        selectedCardIds.includes(id)
      )
      state.selectedCardIdsWhileDragging = orderedSelectedCardIds
    },
    onReorder: (
      state,
      action: PayloadAction<{ reorderedCardIds: string[] }>
    ) => {
      const { reorderedCardIds } = action.payload
      const {
        leaderCardId,
        selectedCardIdsWhileDragging,
        allCardIdsWhileDragging,
      } = state

      const isLeader = (id: string) => id === leaderCardId
      const originalLeaderIndex = allCardIdsWhileDragging.findIndex(isLeader)
      const reorderedLeaderIndex = reorderedCardIds.findIndex(isLeader)

      if (reorderedLeaderIndex === -1 || originalLeaderIndex === -1) {
        // what should we do if we get here?
        console.error('[dragAndDropReducer] could not find leader card')
        return
      }

      const indexDelta = reorderedLeaderIndex - originalLeaderIndex
      const originalFirstSelectedCardIndex = allCardIdsWhileDragging.findIndex(
        (id) => id === selectedCardIdsWhileDragging[0]
      )
      // Ensure insertIndex does not go below 0 which can happen if you drag a leader card that is not
      // the uppermost selected card to the top of the filmstrip
      const insertIndex = Math.max(
        originalFirstSelectedCardIndex + indexDelta,
        0
      )

      const nonSelectedCards = reorderedCardIds.filter(
        (id) => !selectedCardIdsWhileDragging.includes(id)
      )

      state.allCardIdsWhileDragging = [
        ...nonSelectedCards.slice(0, insertIndex),
        ...selectedCardIdsWhileDragging,
        ...nonSelectedCards.slice(insertIndex),
      ]
    },
    onDropReset: () => initialState,
  },
})

type ReduxState = {
  [FilmstripDragAndDropSlice.name]: FilmstripDragAndDropState
}

export const { startDragging, onDropReset, onReorder } =
  FilmstripDragAndDropSlice.actions

// Selectors
export const selectIsFilmstripDragging = (state: ReduxState) =>
  state.FilmstripDragAndDrop.isDragging

export const selectAllFilmstripCardIdsOnDrag = (state: ReduxState) =>
  state.FilmstripDragAndDrop.allCardIdsWhileDragging

export const selectFilmstripDraggingCardIds = (state: ReduxState) =>
  state.FilmstripDragAndDrop.selectedCardIdsWhileDragging

export const selectDragCardIndices = (state: ReduxState) =>
  state.FilmstripDragAndDrop.dragStartIndices

export const selectLeaderCardId = (state: ReduxState) =>
  state.FilmstripDragAndDrop.leaderCardId

export const selectFilmstripDragAndDropData = (
  state: ReduxState
): FilmstripNavItemData[] => {
  const {
    selectedCardIdsWhileDragging,
    allCardIdsWhileDragging,
    leaderCardId,
  } = state.FilmstripDragAndDrop

  return allCardIdsWhileDragging.map((id) => {
    const isDragging = selectedCardIdsWhileDragging.includes(id)

    return {
      cardId: id,
      isDragging,
      displayBehavior:
        isDragging && leaderCardId !== id
          ? ('hidden' as const)
          : ('normal' as const),
    }
  })
}

// Reducer
export const reducer = FilmstripDragAndDropSlice.reducer
