import { findChildren, JSONContent } from '@tiptap/core'
import { Node, ResolvedPos } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'

import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

export const findChildrenPos = (start: number, n: Node): [number, number][] => {
  const map = {}
  const found = findChildren(n, isCardNode)
  if (!found) {
    return []
  }
  for (const { node, pos } of found) {
    map[node.attrs.id] = [start + pos, start + pos + node.nodeSize]
  }
  return Object.values(map)
}

export const overlaps = (pos: number, end: number, sel: Selection): boolean => {
  const { to, from } = sel
  if (pos === from && end === to) {
    return true
  }
  if (pos < from && from < end) {
    return true
  }
  if (pos < to && to < end) {
    return true
  }

  return false
}

export const collectChildCardIds = (node: Node): string[] => {
  return findChildren(node, isCardNode).map((a) => a.node.attrs.id)
}

export const getDocModeOverlap = (
  selection: Selection,
  node: Node,
  nodeWithPos: ResolvedPos
): 'none' | 'child' | 'self' => {
  const pos = nodeWithPos.start()
  const end = pos + nodeWithPos.node().nodeSize

  const children = findChildrenPos(pos + 1, node)

  const overlapsParent = overlaps(pos, end, selection)
  const overlapsChild = children.find(([childPos, childEnd]) => {
    return overlaps(childPos, childEnd, selection)
  })

  if (overlapsParent) {
    return overlapsChild ? 'child' : 'self'
  }
  return 'none'
}

export const findNonCardTitle = (node: JSONContent) => {
  if (!node.content) {
    return null
  }

  const n = node.content[0]!
  switch (n.type) {
    case 'embed':
      return n.attrs!.url
  }
  return null
}
