import { NormalizedCacheObject } from '@apollo/client'

import { IndexedDBStorage } from 'modules/offline/manager/caches/IndexeddbStorage'

type CacheTimes = Record<string, number>

export class OfflineCacheDbAdapter {
  private db: IndexedDBStorage<any>

  private key = 'apollo-offline-cache'

  private cacheTimesKey = 'cache-times'

  constructor() {
    this.db = new IndexedDBStorage<any>('ApolloCache_v2', 'data')
  }

  async getCacheTimes(): Promise<CacheTimes> {
    const result = await this.db.getItem(this.cacheTimesKey)
    return result || {}
  }

  async getNormalizedCache(): Promise<NormalizedCacheObject> {
    const result = await this.db.getItem(this.key)
    return (
      result || {
        ROOT_QUERY: {},
      }
    )
  }

  async writeNormalizedCache(
    normalizedCache: NormalizedCacheObject
  ): Promise<void> {
    return this.db.setItem(this.key, normalizedCache)
  }

  async writeCacheTimes(cacheTimes: CacheTimes): Promise<void> {
    return this.db.setItem(this.cacheTimesKey, cacheTimes)
  }

  async purge(): Promise<void> {
    return Promise.all([
      this.db.removeItem(this.key),
      this.db.removeItem(this.cacheTimesKey),
    ]).then(() => {})
  }
}
