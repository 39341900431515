import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { isMobileDevice } from './../deviceDetection'

// From https://usehooks.com/useHover/
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false)
  const ref: any = useRef<T | null>(null)

  const handleMouseEnter = (): void => setValue(true)
  const handleMouseLeave = (): void => setValue(false)

  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        // Intentionally choose mouseenter/mouseleave, not mouseover/mouseout
        // https://thisthat.dev/mouseenter-vs-mouseover/
        node.addEventListener('mouseenter', handleMouseEnter)
        node.addEventListener('mouseleave', handleMouseLeave)
        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter)
          node.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
      return () => {}
    },
    [ref.current] // Recall only if ref changes
  )
  return [ref, value]
}

export type useIsHoveringProps = {
  enterDelay?: number
  leaveDelay?: number
  disabled?: boolean
}

// Loosely based on https://github.com/andrewbranch/react-use-hover/blob/master/src/index.ts
export const useIsHovering = ({
  enterDelay = 0,
  leaveDelay = 0,
  disabled = isMobileDevice() || false,
}: useIsHoveringProps) => {
  const [isHovering, setIsHovering] = useState<boolean | null>(null)
  const delayedCloseTimeRef = useRef<number | null>(null)
  const mouseEnterTimerRef = useRef<number | null>(null)
  const mouseLeaveTimerRef = useRef<number | null>(null)
  const onMouseOver = useCallback(() => {
    if (delayedCloseTimeRef.current) {
      clearTimeout(delayedCloseTimeRef.current)
      delayedCloseTimeRef.current = null
    }
    if (mouseLeaveTimerRef.current) {
      clearTimeout(mouseLeaveTimerRef.current)
    }
    mouseEnterTimerRef.current = window.setTimeout(() => {
      setIsHovering(true)
    }, enterDelay)
  }, [enterDelay])
  const onMouseOut = useCallback(() => {
    if (mouseEnterTimerRef.current) {
      clearTimeout(mouseEnterTimerRef.current)
    }
    mouseLeaveTimerRef.current = window.setTimeout(() => {
      setIsHovering(false)
    }, leaveDelay)
  }, [leaveDelay])

  // When hovering is disabled (maybe in response to a user click), we remove
  // the handlers but the state could get stuck as true. This resets it to false.
  useEffect(() => {
    if (disabled) {
      setIsHovering(false)
    }
  }, [disabled])

  const delayedClose = useCallback((delay: number) => {
    delayedCloseTimeRef.current = window.setTimeout(() => {
      if (mouseEnterTimerRef.current) {
        clearTimeout(mouseEnterTimerRef.current)
        mouseEnterTimerRef.current = null
      }
      setIsHovering(false)
    }, delay)
  }, [])

  return {
    onMouseOver: disabled ? undefined : onMouseOver,
    onMouseOut: disabled ? undefined : onMouseOut,
    delayedClose,
    isHovering,
  }
}
