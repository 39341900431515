import { EventEmitter } from 'utils/EventEmitter'

export type ConnectionEvents = {
  offline: {}
  navigatorOnline: {}
  navigatorOffline: {}
  online: {
    timeOffline: number | null
  }
  foreground: {
    timeInBackground: number | null
  }
  background: {
    backgroundTime: number
  }
  wakeup: {}
}

export const ConnectionEventEmitter = new EventEmitter<ConnectionEvents>()
