import { config } from 'config'
import { META_TAG_MAX_LENGTH } from 'modules/head/constants'

export const GAMMA_SITES_HOST =
  config.APPLICATION_ENVIRONMENT === 'production'
    ? 'sites.gamma.app'
    : 'sites.staging.gamma.app'

export const GAMMA_SITES_IPS =
  config.APPLICATION_ENVIRONMENT === 'production'
    ? ['3.136.232.26', '3.137.108.170']
    : ['3.131.191.219']

export const SOFT_MAX_TITLE_LENGTH = 70
export const MAX_TITLE_LENGTH = 150
export const SOFT_MAX_DESCRIPTION_LENGTH = 160
export const MAX_DESCRIPTION_LENGTH = META_TAG_MAX_LENGTH
