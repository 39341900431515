import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming/accentImageSourceKey'

type EditBackgroundDrawerState = {
  currentSourceKey: string
}

const initialState: EditBackgroundDrawerState = {
  currentSourceKey: ACCENT_IMAGE_SOURCE_KEY,
}

const EditBackgroundDrawerSlice = createSlice({
  name: 'EditBackgroundDrawer',
  initialState,
  reducers: {
    setCurrentSourceKey(state, action: PayloadAction<{ sourceKey: string }>) {
      state.currentSourceKey = action.payload.sourceKey
    },
    resetCurrentSourceKey(state) {
      state.currentSourceKey = initialState.currentSourceKey
    },
  },
})

export const {
  setCurrentSourceKey: setBackgroundDrawerSourceKey,
  resetCurrentSourceKey: resetBackgroundDrawerSourceKey,
} = EditBackgroundDrawerSlice.actions

type SliceState = Pick<RootState, 'EditBackgroundDrawer'>

export const selectCurrentEditBackgroundDrawerSourceKey = (
  state: SliceState
): string => state.EditBackgroundDrawer.currentSourceKey

export const EditBackgroundDrawerReducer = EditBackgroundDrawerSlice.reducer
