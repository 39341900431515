import { useEffect } from 'react'

import {
  isNavigatorLanguageGA,
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
} from 'modules/i18n/utils/localeKey'

import { userEventEmitter } from '../UserEventEmitter'
import { useUserApi } from './useUserApi'

export const useAutoSetUserLocale = () => {
  const { updateUserSettings } = useUserApi()

  useEffect(() => {
    // Sync user.settings.locale to navigator.locale if it's not set yet
    return userEventEmitter.on('userFetched', ({ user, type }) => {
      if (!user || user?.settings?.locale || type !== 'initial') {
        return
      }

      if (isNavigatorLanguageGA()) {
        updateUserSettings({
          set: {
            locale: getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback(),
          },
        })
      }
    })
  }, [updateUserSettings])
}
