import { useEffect } from 'react'

import { EditorModeEnum } from 'modules/tiptap_editor/types'

import { getBaseDocData } from './baseHoneycombDocData'
import { sendSlideToSlidePerformanceEvent } from './honeycombApi'
import { MeasureSlideToSlideEvent } from './types'

let startTime: null | number = null
let isTOCClick: boolean = false
let isFollowing: boolean = false
let lastCardId: null | string = null

export const startSlideToSlide = (
  { tocClick, following }: { tocClick?: boolean; following?: boolean } = {
    tocClick: false,
    following: false,
  }
) => {
  startTime = performance.now()
  isTOCClick = !!tocClick
  isFollowing = !!following
}

export const setSlideToSlideData = ({ cardId }: { cardId: string }) => {
  lastCardId = cardId
}

const getDirection = (
  curr: string,
  prev: string
): MeasureSlideToSlideEvent['direction'] => {
  if (isTOCClick) {
    return 'toc'
  }

  if (curr === 'presentCurrent') {
    if (prev === 'presentNext') {
      return 'next'
    } else if (prev === 'presentPrev') {
      return 'prev'
    }
  }

  if (curr === 'presentParent' && prev === 'presentCurrent') {
    return 'child'
  } else if (curr === 'presentCurrent' && prev === 'presentParent') {
    return 'parent'
  }

  return 'unknown' as any
}

export const useSendSlideToSlideEvent = (
  enabled: boolean,
  cardId: string,
  presentVariant: string,
  prevPresentVariant: string
) => {
  useEffect(() => {
    if (!enabled) {
      return
    }
    if (cardId !== lastCardId) {
      return
    }
    requestAnimationFrame(function endSlideToSlide() {
      const data = getBaseDocData()
      if (data.editorMode !== EditorModeEnum.SLIDE_VIEW) {
        // dont track when in doc mode
        return
      }
      if (startTime === null) {
        return
      }
      const duration = performance.now() - startTime
      const direction = getDirection(presentVariant, prevPresentVariant)

      sendSlideToSlidePerformanceEvent({
        ...data,
        duration,
        isFollowing,
        presentVariant,
        prevPresentVariant,
        direction,
      })
    })
  }, [enabled, cardId, presentVariant, prevPresentVariant])
}
