import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type FeatureFlagsState = {
  isDocWorkspaceSet: boolean
  showBreakpoint: boolean
  showProsemirrorDevTools: boolean
  showDebugCardView: boolean
}

const initialState: FeatureFlagsState = {
  isDocWorkspaceSet: false,
  showBreakpoint: false,
  showProsemirrorDevTools: false,
  showDebugCardView: false,
}

const FeatureFlagsSlice = createSlice({
  name: 'FeatureFlags',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsDocWorkspaceSet(
      state: FeatureFlagsState,
      action: PayloadAction<{
        isDocWorkspaceSet: boolean
      }>
    ) {
      const { isDocWorkspaceSet } = action.payload
      state.isDocWorkspaceSet = isDocWorkspaceSet
    },
    setShowBreakpoint(
      state: FeatureFlagsState,
      action: PayloadAction<{ showBreakpoint: boolean }>
    ) {
      const { showBreakpoint } = action.payload
      state.showBreakpoint = showBreakpoint
    },
    setShowProsemirrorDevTools(
      state: FeatureFlagsState,
      action: PayloadAction<{ showProsemirrorDevTools: boolean }>
    ) {
      const { showProsemirrorDevTools } = action.payload
      state.showProsemirrorDevTools = showProsemirrorDevTools
    },
    setShowDebugCardView(
      state: FeatureFlagsState,
      action: PayloadAction<{ showDebugCardView: boolean }>
    ) {
      const { showDebugCardView } = action.payload
      state.showDebugCardView = showDebugCardView
    },
  },
})

export const {
  setIsDocWorkspaceSet,
  setShowBreakpoint,
  setShowProsemirrorDevTools,
  setShowDebugCardView,
} = FeatureFlagsSlice.actions

type SliceState = Pick<RootState, 'FeatureFlags'>

// Selectors
export const selectIsDocWorkspaceSet = (state: SliceState): boolean => {
  return state.FeatureFlags.isDocWorkspaceSet
}

export const selectShowBreakpoint = (state: SliceState): boolean => {
  return state.FeatureFlags.showBreakpoint
}

export const selectShowProsemirrorDevTools = (state: SliceState): boolean => {
  return state.FeatureFlags.showProsemirrorDevTools
}

export const selectShowDebugCardView = (state: SliceState): boolean => {
  return state.FeatureFlags.showDebugCardView
}

// Reducer
export const reducer = FeatureFlagsSlice.reducer
