import { Editor } from '@tiptap/core'
import { useEffect } from 'react'

import { selectIsPanelComponentOpen } from 'modules/panels/reducer'
import { useAppStore } from 'modules/redux'
import {
  selectCardIdMap,
  selectDoc,
  selectMode,
  selectNumConcurrentCollaborators,
} from 'modules/tiptap_editor/reducer'
import { useEditorContext } from 'sections/docs/context/EditorContext'
import { TableOfContentsPanel } from 'sections/docs/editor/components/TableOfContents/TableOfContentsPanel'

import { BaseDocData } from './types'

// Store a map of docId to base doc data outside of react
// state for performance reasons, since it updates frequently
// We intentionally dont clear it on umount because other consumers
// use it asynchronously.
const baseDocDataMap: Record<string, Partial<BaseDocData>> = {}
let currentEditor: Editor | null = null

export const useBasePerformanceDocData = () => {
  const { editor } = useEditorContext()

  useEffect(() => {
    currentEditor = editor || null
  }, [editor])

  const reduxStore = useAppStore()
  const docId = currentEditor?.gammaDocId

  useEffect(() => {
    if (!docId) return

    // Clear out any existing data for this docId
    // We cant do this on unmount because other consumers like
    // event tracking unfortunately need the data asynchronously
    baseDocDataMap[docId] = {}

    const compute = () => {
      const baseDocData = baseDocDataMap[docId]

      const state = reduxStore.getState()
      const cardIdMap = selectCardIdMap(state)
      const doc = selectDoc(state)

      baseDocData.docNumConcurrentCollaborators =
        selectNumConcurrentCollaborators(state)
      baseDocData.editorMode = selectMode(state)
      baseDocData.tocOpen =
        selectIsPanelComponentOpen(TableOfContentsPanel)(state)
      baseDocData.docTitle = doc?.title || ''
      baseDocData.docId = docId
      baseDocData.docNumComments = doc?.comments?.length ?? -1
      if (cardIdMap) {
        const { nested, top } = Object.values(cardIdMap.parents).reduce<{
          top: number
          nested: number
        }>(
          (carry, parentCards) => {
            if (parentCards.length > 0) {
              carry.nested++
            } else {
              carry.top++
            }
            return carry
          },
          {
            top: 0,
            nested: 0,
          }
        )
        baseDocData.docNumAllCards = nested + top
        baseDocData.docNumTopLevelCards = top
        baseDocData.docNumNestedCards = nested
      }
    }

    compute()
    return reduxStore.subscribe(compute)
  }, [reduxStore, docId])
}

export const getBaseDocData = (): BaseDocData => {
  const docId = currentEditor?.gammaDocId
  return {
    ...(docId ? baseDocDataMap[docId] : {}),
    docSize: currentEditor?.state.doc.content.size || -1,
  } as BaseDocData
}
