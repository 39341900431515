import React, { PropsWithChildren, useContext } from 'react'

import { shouldUsePublishedVersion } from 'utils/publishing'

export type PublishingContextType = {
  publishedVersionOverride: boolean
}

export const PublishingContext = React.createContext<PublishingContextType>({
  publishedVersionOverride: false,
})

export const usePublishedContext = () => {
  const ctx = useContext(PublishingContext)
  return ctx
}

export function useShouldUsePublishedVersion() {
  const { publishedVersionOverride } = usePublishedContext()
  return publishedVersionOverride || shouldUsePublishedVersion()
}

const enabledValue = { publishedVersionOverride: true }

// Allows overriding the value of "shouldUsePublishedVersion" in react components
export const PublishingContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <PublishingContext.Provider value={enabledValue}>
      {children}
    </PublishingContext.Provider>
  )
}
