import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type HomeState = {
  isHomeSidebarVisible: boolean
}

const initialState: HomeState = {
  isHomeSidebarVisible: false,
}

const HomeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setIsHomeSidebarVisible: (
      state: HomeState,
      action: PayloadAction<{ isSidebarVisible: boolean }>
    ) => {
      state.isHomeSidebarVisible = action.payload.isSidebarVisible
    },
  },
})

export const { setIsHomeSidebarVisible } = HomeSlice.actions

type HomeSliceState = Pick<RootState, 'Home'>

// Selectors
export const selectIsHomeSidebarVisible = (state: HomeSliceState): boolean => {
  return state.Home.isHomeSidebarVisible
}

// Reducer
export const reducer = HomeSlice.reducer
