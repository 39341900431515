export enum EditorModeEnum {
  DOC_VIEW = 'DOC_VIEW',
  SLIDE_VIEW = 'SLIDE_VIEW',
}

export type CardId = string
type RecursiveMap = { [cardId: CardId]: RecursiveMap | {} }
export type CardTreeItem = {
  id: CardId
  children: CardTreeItem[]
}

// See cardIdMap.ts for how this is used.
export type CardIdMap = {
  tree: RecursiveMap
  parents: { [cardId: CardId]: CardId[] }
  treeArray: CardTreeItem[]
}
export type CardIds = CardId[]
