import { PDFThumbnail } from 'modules/import/types'

export const originalThumbnailCard = (thumbnail: PDFThumbnail) => {
  const card = {
    type: 'card',
    attrs: {
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
        },
        content: [
          {
            type: 'image',
            attrs: {
              src: thumbnail.url,
              uploadStatus: 0,
              meta: {
                width: thumbnail.width,
                height: thumbnail.height,
              },
              name: 'ppt-thumbnail-image',
              source: 'image.custom',
            },
          },
        ],
      },
    ],
  }
  return card
}
