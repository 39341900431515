import { createSlice } from '@reduxjs/toolkit'

import { GetDocQuery } from 'modules/api'
import { DocEditorType, DocEditorTypeType } from 'sections/docs/constants'

export interface DocEditorState {
  doc?: GetDocQuery['doc']
  docEditorType: DocEditorTypeType | undefined
  spotlightByBlockEnabled: boolean
}

const initialState: DocEditorState = {
  doc: undefined,
  docEditorType: undefined,
  spotlightByBlockEnabled: false,
}

const DocEditorSlice = createSlice({
  name: 'DocEditor',
  initialState,
  reducers: {
    reset: () => initialState,
    setDoc(state, action: { payload: { doc: GetDocQuery['doc'] } }) {
      const { doc } = action.payload
      state.doc = doc
    },
    setDocEditorType(
      state,
      action: { payload: { docEditorType: DocEditorTypeType } }
    ) {
      const { docEditorType } = action.payload
      state.docEditorType = docEditorType
    },
  },
})

type ReduxState = {
  [DocEditorSlice.name]: DocEditorState
}

export const { setDoc, reset, setDocEditorType } = DocEditorSlice.actions

// Selectors
export const selectDoc = (state: ReduxState) => state.DocEditor.doc

export const selectDocId = (state: ReduxState) => state.DocEditor.doc?.id
export const selectDocSiteId = (state: ReduxState) =>
  state.DocEditor.doc?.site?.id

export const selectIsCollaborativeDocEditorType = (state: ReduxState) =>
  state.DocEditor.docEditorType === DocEditorType.COLLABORATIVE

export const selectDocEditorType = (state: ReduxState) =>
  state.DocEditor.docEditorType

// Reducer
export const reducer = DocEditorSlice.reducer
