import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FC, PropsWithChildren } from 'react'

export const ReactQueryProvider: FC<
  PropsWithChildren<{ client: QueryClient }>
> = ({ children, client }) => {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

type ErrorFilterFn = (error: any) => boolean

const errorFilters: ErrorFilterFn[] = []

// React-Query always console.errors on "Error" responses
// Set our own logger so that we can avoid console.error'ing when
// we want to, such as a 401/403 from /user.
// This prevents these errors from ending up in Sentry
// See https://stackoverflow.com/questions/65570462/how-do-i-mute-react-query-console-errors-when-a-promise-rejects
const customLogger = {
  log: console.log,
  warn: console.warn,
  error: (error: any) => {
    // Avoid console erroring if any of the error filters return true
    if (errorFilters.some((fn) => fn(error))) return
    console.error(error)
  },
}

export const setReactQueryErrorFilter = (fn: ErrorFilterFn) => {
  errorFilters.push(fn)
}

export const getReactQueryClient = () =>
  new QueryClient({ logger: customLogger })
