// Deferred implementation based on input from this SO:
// https://stackoverflow.com/questions/44728779/deferred-that-extends-promise

// See base ES6 Promise typing here:
// https://github.com/microsoft/TypeScript/blob/c6f97328571f00b03acb7a7d2635401bb26e434b/lib/lib.es5.d.ts
// https://github.com/microsoft/TypeScript/blob/c6f97328571f00b03acb7a7d2635401bb26e434b/lib/lib.es2015.promise.d.ts

export class Deferred<T> {
  private _resolveSelf
  private _rejectSelf

  public promise: Promise<T>

  constructor() {
    this.promise = new Promise(
      function (resolve, reject) {
        this._resolveSelf = resolve
        this._rejectSelf = reject
      }.bind(this)
    )
  }

  public resolve(val?: T) {
    this._resolveSelf(val)
  }
  public reject(reason?: any) {
    this._rejectSelf(reason)
  }

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null,
    onrejected?:
      | ((reason: any) => TResult2 | PromiseLike<TResult2>)
      | undefined
      | null
  ): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected)
  }

  public catch<TResult = never>(
    onrejected?:
      | ((reason: any) => TResult | PromiseLike<TResult>)
      | undefined
      | null
  ): Promise<T | TResult> {
    return this.promise.catch(onrejected)
  }

  public finally(): Promise<T> {
    return this.promise.finally()
  }
}

/**
 * Wraps an existing promise in a Deferred.
 */
export const wrapInDeferred = <T>(promise: Promise<T>) => {
  const deferred = new Deferred<T>()
  promise.then(deferred.resolve.bind(deferred), deferred.reject.bind(deferred))
  promise.catch(deferred.catch.bind(deferred))
  promise.finally(deferred.finally.bind(deferred))
  return deferred
}
