import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import { GetSiteQuery } from 'modules/api/generated/graphql'
import { AppDispatch, RootState } from 'modules/redux'

import { SiteNavItem } from './types'
import { routeHasUnpublishedChanges } from './utils'

export type SiteState = {
  site?: GetSiteQuery['site']
  isPublishing: boolean
  currentDocId?: string
  currentNavItem?: SiteNavItem
  hasNavAnimated: boolean
  pagesLastUpdatedMap: Record<string, string>
}

const initialState: SiteState = {
  site: undefined,
  isPublishing: false,
  currentDocId: undefined,
  currentNavItem: undefined,
  hasNavAnimated: false,
  pagesLastUpdatedMap: {},
}

const SiteSlice = createSlice({
  name: 'Site',
  initialState,
  reducers: {
    reset: () => initialState,
    setSite(
      state: SiteState,
      action: PayloadAction<{
        site?: GetSiteQuery['site']
      }>
    ) {
      const { site } = action.payload
      state.site = site
    },
    setPublishing(
      state: SiteState,
      action: PayloadAction<{
        isPublishing: boolean
      }>
    ) {
      const { isPublishing } = action.payload
      state.isPublishing = isPublishing
    },
    setCurrentDocId(
      state: SiteState,
      action: PayloadAction<{
        currentDocId?: string
      }>
    ) {
      const { currentDocId } = action.payload
      state.currentDocId = currentDocId
    },
    setCurrentNavItem(
      state: SiteState,
      action: PayloadAction<{
        currentNavItem?: SiteNavItem
      }>
    ) {
      const { currentNavItem } = action.payload
      state.currentNavItem = currentNavItem
    },
    setHasNavAnimated(
      state: SiteState,
      action: PayloadAction<{
        hasNavAnimated: boolean
      }>
    ) {
      const { hasNavAnimated } = action.payload
      state.hasNavAnimated = hasNavAnimated
    },
    setPageUpdated(
      state: SiteState,
      action: PayloadAction<{
        pageId: string
        lastUpdatedAt: string
      }>
    ) {
      const { pageId, lastUpdatedAt } = action.payload
      state.pagesLastUpdatedMap[pageId] = lastUpdatedAt
    },
  },
})

export const {
  setSite,
  setCurrentDocId,
  setCurrentNavItem,
  setPublishing,
  setPageUpdated,
  setHasNavAnimated,
  reset,
} = SiteSlice.actions

export const setPublishingAction =
  (dispatch: AppDispatch) => (isPublishing: boolean) => {
    dispatch(setPublishing({ isPublishing }))
  }

export const setDocIdAndSiteAction =
  (dispatch: AppDispatch) =>
  ({ docId, site }: { docId?: string; site?: GetSiteQuery['site'] }) => {
    dispatch(setCurrentDocId({ currentDocId: docId }))
    dispatch(setSite({ site }))
  }

export const setNavHasAnimatedAction =
  (dispatch: AppDispatch) => (hasNavAnimated: boolean) => {
    dispatch(setHasNavAnimated({ hasNavAnimated }))
  }

type SitesSliceState = Pick<RootState, 'Site'>

// Selectors

export const selectSite = (state: SitesSliceState) => state.Site.site

export const selectSiteId = (state: SitesSliceState) => state.Site.site?.id

export const selectIsPublishing = (state: SitesSliceState) =>
  state.Site.isPublishing

export const selectSiteTheme = (state: SitesSliceState) =>
  state.Site.site?.theme

export const selectCurrentDocId = (state: SitesSliceState) =>
  state.Site.currentDocId

export const selectCurrentNavItem = (state: SitesSliceState) =>
  state.Site.currentNavItem

export const selectHasNavAnimated = (state: SitesSliceState) =>
  state.Site.hasNavAnimated

export const selectNumberOfChangedPages = (state: SitesSliceState) => {
  const site = selectSite(state)
  if (!site) return 0

  return site.routes.reduce((acc, route) => {
    if (
      selectPageUpdated(route.docId)(state) || // Edited in editor
      routeHasUnpublishedChanges(route, site) // Snapshot ID changed
    ) {
      return acc + 1
    }

    return acc
  }, 0)
}

export const selectPageUpdated =
  (pageId?: string) => (state: SitesSliceState) => {
    const site = selectSite(state)
    if (!pageId || !site) return false

    const route = site.routes.find((r) => r.docId === pageId)
    if (!route) return false

    const pageLastUpdated = state.Site.pagesLastUpdatedMap[pageId]
    const routeHasChanges = routeHasUnpublishedChanges(route, site)

    // If the page hasn't been edited, just return if the route changed.
    if (!pageLastUpdated) return routeHasChanges

    const timeSincePublished =
      +new Date(pageLastUpdated) - +new Date(route.doc?.publishedTime)

    return routeHasChanges || timeSincePublished > 0
  }

export const selectSiteSettingsChanged = (state: SitesSliceState) => {
  const site = selectSite(state)
  if (!site) return false

  const { currentDeployment } = site
  if (!currentDeployment?.siteSettings) return false

  const { siteSettings } = currentDeployment

  const themeChanged = site.themeId !== siteSettings.themeId
  const navEnabledChanged = site.navEnabled !== siteSettings.navEnabled
  const navContentChanged = !isEqual(site.navContent, siteSettings.navContent)
  const gtmContainerIdChanged =
    site.gtmContainerId !== siteSettings.gtmContainerId
  const faviconChanged = site.faviconUrl !== siteSettings.faviconUrl

  return (
    themeChanged ||
    navEnabledChanged ||
    navContentChanged ||
    gtmContainerIdChanged ||
    faviconChanged
  )
}

// Reducer
export const SiteReducer = SiteSlice.reducer
