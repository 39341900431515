import { BoxProps } from '@chakra-ui/layout'

import type { Theme } from 'modules/theming/types'
import { DEFAULT_THEME_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import {
  BackgroundImageAttrs,
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { isColorDark } from 'utils/color'
import { isPptExport } from 'utils/export'
import {
  ImageResizeParams,
  backgroundImageFromUrls,
  recolorImageUrl,
  resizeAndProxyImageUrl,
} from 'utils/image'

import { DEFAULT_MASK } from '../extensions/Card/Card2/BackgroundMask'

export const getDocOrThemeBackground = (
  theme: Theme,
  docBackground?: BackgroundOptions
): BackgroundOptions =>
  !docBackground || docBackground.type === BackgroundType.NONE
    ? !theme.config.background ||
      theme.config.background.type === BackgroundType.NONE
      ? DEFAULT_THEME_BACKGROUND
      : theme.config.background
    : docBackground

// Return true for dark, false for light, null for no mask or default color
// If useDefaultMask is true, it will use the default mask if none is provided
export const isBackgroundDark = (
  background: BackgroundOptions,
  useDefaultMask?: boolean
): boolean | null => {
  // If it's a color background, check if the color is dark
  if (background.type === BackgroundType.COLOR && background.color) {
    return background.color.isDark ?? isColorDark(background.color.hex)
  } else if (background.type === BackgroundType.GRADIENT) {
    return null // Gradients auto adapt to light/dark mode
  }

  const mask = background.mask || (useDefaultMask ? DEFAULT_MASK : null)
  if (!mask) {
    // Todo: check the average_color of the image
    return null
  }
  // If there's a mask applied, check if it's black or white
  switch (mask.color) {
    case 'black':
      return true
    case 'white':
      return false
    default:
      return null
  }
}

export const getBackgroundPosFromBackgroundImageAttrs = (
  attrs: BackgroundImageAttrs | undefined | null
): string => {
  if (!attrs) {
    return 'center'
  }
  const { backgroundPos } = attrs
  return backgroundPos ? `${backgroundPos.x}% ${backgroundPos.y}%` : 'center'
}

export const getBackgroundProps = (
  background: BackgroundOptions,
  isDark: boolean,
  resizeParams: Partial<ImageResizeParams> = { width: 2400 },
  bodyColor?: string
): BoxProps => {
  if (background.type === BackgroundType.GRADIENT) {
    const css = background.gradient?.css || background.css
    // PPT export cant handle gradient fills, so we fall back to a solid color
    if (isPptExport) {
      return {
        backgroundColor: background.gradient?.primaryColor,
      }
    }
    return {
      backgroundColor: isDark ? 'black' : 'white',
      ...css,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
  }

  if (background.type === BackgroundType.IMAGE && background.image) {
    const { tempUrl, meta } = background.image
    const src = background.image.src
      ? recolorImageUrl(background.image.src, bodyColor)
      : undefined
    const resizedSrc = !src
      ? undefined
      : resizeAndProxyImageUrl(src, resizeParams, meta)

    const backgroundImage = backgroundImageFromUrls(
      resizedSrc,
      // Use the tempUrl only if there is no src.
      !resizedSrc ? tempUrl : undefined
    )
    const backgroundPosition = getBackgroundPosFromBackgroundImageAttrs(
      background.image
    )

    return {
      backgroundImage,
      backgroundPosition,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  if (background.type === BackgroundType.COLOR && background.color) {
    return {
      // Legacy backgrounds had color specified in color field directly,
      // new ones should all be in color.hex
      backgroundColor:
        typeof background.color === 'string'
          ? background.color
          : background.color.hex,
    }
  }

  return {}
}

export const getBackgroundColor = (
  background?: BackgroundOptions
): string | undefined => {
  if (
    background &&
    background.type === BackgroundType.COLOR &&
    background.color
  ) {
    return background.color.hex
  } else {
    return
  }
}
