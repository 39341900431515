export const isLocalStorageEnabledFn = () => {
  if (typeof window === 'undefined') {
    return false
  }
  try {
    const key = '__another_random_key_you_are_not_going_to_use__'
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

// Utility to tell us if we can use localStorage and other browser features that are also
// blocked when third-party cookies are blocked.
// For the most comprehensive list we could find, see:
// https://blog.tomayac.com/2022/08/30/things-not-available-when-someone-blocks-all-cookies/
export const isLocalStorageSupported = isLocalStorageEnabledFn()
