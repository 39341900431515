/**
 * This store is responsible for getting metadata from the /user-context endpoint
 * and storing it in localStorage.
 *
 * Consumers can grab the metadata from localStorage to enrich events. Currently, the
 * main use cases are:
 * 1. Passing it as the `context` object to the /identify or /track endpoints
 * 2. Passing it as part of the base data payload for Honeycomb events
 */
import jsCookie from 'js-cookie'
import { memoize } from 'lodash'

import { config } from 'config'
import { isRobot } from 'utils/deviceDetection'
import { shouldUsePublishedVersion } from 'utils/publishing'
import { localStore } from 'utils/storage'

import { getUserMetadataClientSide, getUtmCampaignData } from './fns'
export type UserMetadata = Record<string, any>

const LS_KEY = 'gammaUserMetadata'

const getDefaultData = memoize(() => getUserMetadataClientSide())

const shouldEnable = () => !isRobot() && !shouldUsePublishedVersion()

export class UserMetadataStore {
  protected enabled: boolean | null = shouldEnable()
  private promise: Promise<UserMetadata> | null = null

  private async doFetch(): Promise<UserMetadata> {
    if (!this.enabled) {
      return Promise.reject('User metadata fetching not enabled')
    }
    const lastReferrer = jsCookie.get('lastReferrer')
    const firstPageSeen = jsCookie.get('firstPageSeen')
    const campaign = getUtmCampaignData(firstPageSeen)
    const res = await fetch(
      `https://${config.EVENT_TRACKING_ENDPOINT}/user-context`,
      {
        body: JSON.stringify({
          context: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            userAgent: navigator.userAgent,
            locale: navigator.language,
            lastReferrer,
            firstPageSeen,
            campaign,
          },
        }),
        headers: {
          // Segment's libraries support `text/plain` even though we're actually sending
          // `application/json`.
          'Content-Type': 'text/plain',
        },
        method: 'POST',
      }
    )
    if (!res.ok) {
      console.error(
        '[UserMetadataStore] Failed to fetch user metadata',
        res.statusText
      )
      return Promise.reject()
    }
    return await res.json()
  }

  get(): UserMetadata {
    const data = localStore.getItem(LS_KEY)
    if (!data) {
      return getDefaultData()
    }
    try {
      return JSON.parse(data) || getDefaultData()
    } catch (e) {
      // swallow
    }
    return getDefaultData()
  }
  fetch(): Promise<UserMetadata> {
    if (this.promise) {
      return this.promise
    }
    this.promise = this.doFetch()

    this.promise
      .then((userMetadata) => {
        if (userMetadata) {
          localStore.setItem(LS_KEY, JSON.stringify(userMetadata))
        }
      })
      .catch(() => {
        // no op
      })
    return this.promise
  }
}

export const getUserMetadataStore = memoize(() => new UserMetadataStore())
