import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'modules/redux'
import type { GraphqlUser } from 'modules/user'

type DocSyncedEntry = {
  docId: string
  lastSynced: string
}

export type OfflineManagerState = {
  offlineModeEnabled: boolean

  // state of the sync
  ydocs: Record<string, DocSyncedEntry>

  docAssets: Record<string, DocSyncedEntry>

  graphqlDocs: Record<string, DocSyncedEntry>

  user: GraphqlUser | null

  userLastSynced: string | null
}

// read cookie
const getInitialState = (): OfflineManagerState => {
  return {
    offlineModeEnabled: false,
    ydocs: {},
    docAssets: {},
    graphqlDocs: {},
    user: null,
    userLastSynced: null,
  }
}

const OfflineManagerSlice = createSlice({
  name: 'OfflineManager',
  initialState: getInitialState(),
  reducers: {
    loadOfflineManagerState: (
      _state: OfflineManagerState,
      action: PayloadAction<OfflineManagerState>
    ) => {
      return action.payload
    },

    enableOfflineMode: (
      state: OfflineManagerState,
      action: PayloadAction<{
        user: GraphqlUser
        lastSynced: string
      }>
    ) => {
      if (state.offlineModeEnabled) {
        return state
      }
      const { user, lastSynced } = action.payload

      return {
        ...state,
        offlineModeEnabled: true,
        user: user,
        userLastSynced: lastSynced,
      }
    },

    disableOfflineMode: (state: OfflineManagerState) => {
      if (!state.offlineModeEnabled) {
        return state
      }

      return {
        ...state,
        user: null,
        userLastSynced: null,
        offlineModeEnabled: false,
      }
    },

    setYdocSynced: (
      state: OfflineManagerState,
      action: PayloadAction<DocSyncedEntry>
    ) => {
      const { docId, lastSynced } = action.payload
      state.ydocs[docId] = { docId, lastSynced }
    },

    setGraphqlSynced: (
      state: OfflineManagerState,
      action: PayloadAction<{ docIds: string[]; lastSynced: string }>
    ) => {
      const { docIds, lastSynced } = action.payload
      docIds.forEach((docId) => {
        state.graphqlDocs[docId] = {
          docId,
          lastSynced,
        }
      })
    },

    setAssetsSynced: (
      state: OfflineManagerState,
      action: PayloadAction<DocSyncedEntry>
    ) => {
      const { docId, lastSynced } = action.payload
      state.docAssets[docId] = { docId, lastSynced }
    },

    removeYDocSyncedDocs: (
      state: OfflineManagerState,
      action: PayloadAction<{ docIds: string[] }>
    ) => {
      const { docIds } = action.payload

      docIds.forEach((docId) => {
        delete state.ydocs[docId]
      })
    },

    setOfflineUser: (
      state: OfflineManagerState,
      action: PayloadAction<{
        user: GraphqlUser | null
        lastSynced: string | null
      }>
    ) => {
      const { user, lastSynced } = action.payload
      state.user = user
      state.userLastSynced = lastSynced
    },

    apolloCacheDisabled: (state: OfflineManagerState) => {
      // remove all of the synced docs graphql synced status
      for (const docId in state.graphqlDocs) {
        delete state.graphqlDocs[docId]
      }
    },

    serwistDisabled: (state: OfflineManagerState) => {
      // remove all of the synced docs assets synced status
      for (const docId in state.docAssets) {
        delete state.docAssets[docId]
      }
    },
  },
})

export const {
  loadOfflineManagerState,
  setYdocSynced,
  setGraphqlSynced,
  setAssetsSynced,
  setOfflineUser,
  removeYDocSyncedDocs,
  enableOfflineMode,
  disableOfflineMode,
  apolloCacheDisabled,
  serwistDisabled,
} = OfflineManagerSlice.actions

type SliceState = Pick<RootState, 'OfflineManager'>

export const selectOfflineManagerState = (state: RootState) =>
  state.OfflineManager

export const selectOfflineModeEnabled = (state: SliceState) =>
  state.OfflineManager.offlineModeEnabled

export const selectSyncedYDocs = (
  state: SliceState
): Record<string, DocSyncedEntry> => {
  return state.OfflineManager.ydocs
}

export const selectFullySyncedDocIds = createSelector(
  (state: SliceState) => state.OfflineManager.graphqlDocs,
  (state: SliceState) => state.OfflineManager.docAssets,
  (state: SliceState) => state.OfflineManager.ydocs,
  (graphqlDocs, docAssets, ydocs) => {
    return Object.values(graphqlDocs)
      .filter(({ docId }) => {
        return !!ydocs[docId] && !!docAssets[docId]
      })
      .map(({ docId }) => docId)
  }
)

export const OfflineManagerReducer = OfflineManagerSlice.reducer
