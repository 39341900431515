import { Editor, JSONContent } from '@tiptap/core'
import debounce from 'lodash/debounce'
import { Node, ResolvedPos } from 'prosemirror-model'
import { Selection, Transaction } from 'prosemirror-state'
import { useEffect, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { SET_SELECTION_ORIGIN_META_KEY } from 'modules/tiptap_editor/commands/keys'
import { selectPresentingCardId } from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'

import { collectChildCardIds, getDocModeOverlap } from './helpers'

type OnUpdateArgs = {
  editor: Editor
  transaction?: Transaction
}

/**
 * Figures out which TOC item should be highlighted either
 * based on the selection (doc mode) or the presentingCardId (slide mode)
 */
export const useOverlap = ({
  editorMode,
  resolved,
  node,
  selection,
}: {
  editorMode: EditorModeEnum
  resolved: ResolvedPos | null
  selection: Selection
  node: Node
}): 'none' | 'child' | 'self' => {
  const presentingCardId = useAppSelector(selectPresentingCardId)
  if (!resolved) {
    return 'none'
  }

  if (editorMode === EditorModeEnum.DOC_VIEW) {
    return getDocModeOverlap(selection, node, resolved)
  } else if (editorMode === EditorModeEnum.SLIDE_VIEW) {
    if (!presentingCardId) {
      return 'none'
    } else {
      const childIds = collectChildCardIds(node)
      return presentingCardId === node.attrs.id
        ? 'self'
        : childIds.includes(presentingCardId)
        ? 'child'
        : 'none'
    }
  }

  return 'none'
}

export const useSyncEditor = (editor?: Editor) => {
  const [content, setContent] = useState<JSONContent>()
  const [selection, setSelection] = useState<Selection | null>(null)
  const [isSelectionOriginTOC, setIsSelectionOriginTOC] =
    useState<boolean>(false)

  useEffect(() => {
    if (!editor) return

    const onUpdate = ({ editor: editorInstance }: OnUpdateArgs) => {
      setContent(editorInstance.getJSON() as JSONContent)
    }
    const onSelectionUpdate = ({
      editor: editorInstance,
      transaction,
    }: OnUpdateArgs) => {
      setSelection(editorInstance.state.selection)
      setIsSelectionOriginTOC(
        transaction?.getMeta(SET_SELECTION_ORIGIN_META_KEY) === 'toc'
      )
    }

    // When the collaborative editor first loads, set the film strip content for the first time
    onUpdate({ editor })
    onSelectionUpdate({ editor })

    const debouncedOnUpdate = debounce(onUpdate, 200, {
      leading: true,
      maxWait: 500,
    })
    const debouncedOnSelectionUpdate = debounce(onSelectionUpdate, 200, {
      leading: true,
    })

    editor.on('update', debouncedOnUpdate)
    editor.on('selectionUpdate', debouncedOnSelectionUpdate)

    return () => {
      editor.off('update', debouncedOnUpdate)
      editor.off('selectionUpdate', debouncedOnSelectionUpdate)
    }
  }, [editor])

  return { content, selection, isSelectionOriginTOC }
}
