import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'

import { QueueableTask, Messages } from 'modules/ai/chat/types'

type QueueableTaskWithId = { task: QueueableTask; id: string }

export interface DesignPartnerState {
  status: Status
  messages: Messages[]
  queuedTasks: QueueableTaskWithId[]
}

export type Status = 'idle' | 'classifying' | 'fetching'

const initialState: DesignPartnerState = {
  status: 'idle',
  messages: [],
  queuedTasks: [],
}

const DesignPartnerSlice = createSlice({
  name: 'DesignPartner',
  initialState,
  reducers: {
    reset: () => initialState,
    setStatus(state, action: PayloadAction<Status>) {
      state.status = action.payload
    },
    addMessage(state, action: { payload: { message } }) {
      const { message } = action.payload
      const newMessages = [...state.messages, message]
      state.messages = newMessages
    },
    enqueueTask(state, action: { payload: { task: QueueableTask } }) {
      const { task } = action.payload
      const newTaskQueue = [...state.queuedTasks, { task, id: nanoid() }]
      state.queuedTasks = newTaskQueue
    },
    removeQueuedTask(state, action: { payload: { id: string } }) {
      const { id } = action.payload
      const newTaskQueue = state.queuedTasks.filter((task) => task.id !== id)
      state.queuedTasks = newTaskQueue
    },
  },
})

type ReduxState = {
  [DesignPartnerSlice.name]: DesignPartnerState
}

export const { addMessage, enqueueTask, removeQueuedTask, reset, setStatus } =
  DesignPartnerSlice.actions

// Selectors
export const selectMessages = (state: ReduxState) =>
  state.DesignPartner.messages

export const selectQueuedTasks = (state: ReduxState) =>
  state.DesignPartner.queuedTasks

export const selectStatus = (state: ReduxState) => state.DesignPartner.status

// Reducer
export const reducer = DesignPartnerSlice.reducer
