import { EventEmitter } from 'utils/EventEmitter'

import { FetchUserResponse } from './api/fetchUser'

type UserFetchedPayload = Pick<FetchUserResponse, 'user' | 'status'> & {
  type: 'initial' | 'refetch'
}

type UserEvents = {
  signedIn: {}

  userFetched: UserFetchedPayload
}

export const userEventEmitter = new EventEmitter<UserEvents>()
