import { memo } from 'react'

import { isColorDark } from 'utils/color'

import { Theme } from '../types'
import { isThemeDark } from '../utils/colors'

// Based on https://stackoverflow.com/a/69801798
// and https://codepen.io/mathiesjanssen/pen/QgLzmM
export const Soft3DSmallOuterShadow: React.FC<{ theme: Theme; id?: string }> =
  memo(({ theme, id }) => {
    const isDark = isThemeDark(theme)
    const topLeftColor = isDark
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(255, 255, 255, 0.75)'
    const bottomRightColor = isDark
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.15)'
    const shadowDistance = 0.5
    const shadowBlur = 1.5
    return (
      <>
        <filter id={id || `soft3d-small-inner-shadow`}>
          {/* Top left dark inner shadow */}
          <feFlood floodColor={bottomRightColor} result="flood1" />
          {/* Subtracts the original shape from the shadow color to get a cutout of the original shape */}
          <feComposite
            operator="out"
            in2="SourceGraphic"
            in="flood1"
            result="composite1"
          />
          {/* Stretch the cutout so it overlaps the original shape */}
          <feMorphology
            operator="dilate"
            radius={shadowDistance}
            in="composite1"
            result="morphology1"
          />
          {/* Blur the cutout to get a shadow effect */}
          <feGaussianBlur
            stdDeviation={shadowBlur}
            in="morphology1"
            result="blur1"
          />
          <feOffset
            dx={shadowDistance + shadowBlur}
            dy={shadowDistance + shadowBlur}
            in="blur1"
            result="offset1"
          />
          {/* Repeat the whole operation for the bottom right light inner shadow */}
          <feFlood floodColor={topLeftColor} result="flood2" />
          <feComposite
            operator="out"
            in2="SourceGraphic"
            in="flood2"
            result="composite2"
          />
          <feMorphology
            operator="dilate"
            radius={shadowDistance}
            in="composite2"
            result="morphology2"
          />
          <feGaussianBlur
            stdDeviation={shadowBlur}
            in="morphology2"
            result="blur2"
          />
          <feOffset
            dx={-shadowDistance - shadowBlur}
            dy={-shadowDistance - shadowBlur}
            in="blur2"
            result="offset2"
          />
          {/* Merge the two blurry cutouts into one object */}
          <feMerge>
            <feMergeNode in="offset1" />
            <feMergeNode in="offset2" />
          </feMerge>
          {/* Subtract the original shape from the blurry cutouts so we just have the shadows left */}
          <feComposite operator="atop" in2="SourceGraphic" />
        </filter>
      </>
    )
  })
Soft3DSmallOuterShadow.displayName = 'Soft3DSmallOuterShadow'

export const getSoft3DShadow = (
  cardColor: string,
  shadowDistance = 0.375,
  inset = false
) => {
  const shadowBlur = Math.abs(shadowDistance) * 2
  const isDark = isColorDark(cardColor)
  const topLeftColor = isDark
    ? 'rgba(255, 255, 255, 0.15)'
    : 'rgba(255, 255, 255, 0.75)'
  const bottomRightColor = isDark
    ? 'rgba(0, 0, 0, 0.75)'
    : 'rgba(0, 0, 0, 0.15)'

  return `${
    inset ? 'inset' : ''
  } ${-shadowDistance}em ${-shadowDistance}em ${shadowBlur}em ${topLeftColor}, ${
    inset ? 'inset' : ''
  } ${shadowDistance}em ${shadowDistance}em ${shadowBlur}em ${bottomRightColor}`
}
