import { t } from '@lingui/macro'
/**
 * Used by both the ThemeBuilder and the ThemeEditor.
 * The ThemeImporter has its own reducer because its state also relies on suggestions
 * returned from the AI
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Content } from '@tiptap/core'
import { isEqual } from 'lodash'

import { CreateThemeInput } from 'modules/api'
import { RootState } from 'modules/redux'
import { FontMap, Theme, ThemeColor } from 'modules/theming/types'
import { getThemeLogoSrc } from 'modules/theming/utils/design'
import { getEmptyTheme } from 'modules/theming/utils/emptyTheme'
import { getUpdatedThemeFonts } from 'modules/theming/utils/fonts'

import { FontPair } from './types'

export type ThemeEditingState = {
  theme: Theme | null
  originalTheme: Theme | null
  baseTheme: Theme | null
  previewingDocContent: Content | undefined
  themeBackgroundImgStatus: 'idle' | 'loading' | 'error'
  themeLogoImgStatus: 'idle' | 'loading' | 'error'
}

const getInitialState = (): ThemeEditingState => {
  return {
    theme: null,
    originalTheme: null,
    baseTheme: null,
    previewingDocContent: undefined,
    themeBackgroundImgStatus: 'idle',
    themeLogoImgStatus: 'idle',
  }
}

const ThemeEditingSlice = createSlice({
  name: 'ThemeEditing',
  initialState: getInitialState(),
  reducers: {
    resetThemeEditingState: () => getInitialState(),
    setOriginalTheme: (
      state: ThemeEditingState,
      action: PayloadAction<{ originalTheme: Theme }>
    ) => {
      state.originalTheme = action.payload.originalTheme
    },
    setPreviewingDocContent: (
      state: ThemeEditingState,
      action: PayloadAction<{ json: ThemeEditingState['previewingDocContent'] }>
    ) => {
      state.previewingDocContent = action.payload.json
    },
    initBlankTheme(state: ThemeEditingState) {
      state.theme = getEmptyTheme()
    },
    setBaseTheme(
      state: ThemeEditingState,
      action: PayloadAction<{ baseTheme: Theme }>
    ) {
      state.theme = {
        ...state.theme,
        ...action.payload.baseTheme,
      }
    },
    updateThemeName(
      state: ThemeEditingState,
      action: PayloadAction<{ name: string }>
    ) {
      if (!state.theme) return
      state.theme.name = action.payload.name
    },
    updateCardColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['cardColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.cardColor = action.payload.color
    },
    updateCardBorderColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['cardBorderColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.cardBorderColor = action.payload.color
    },
    updatePrimaryColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: ThemeColor }>
    ) {
      if (!state.theme) return
      state.theme.accentColor = undefined // Clear out any legacy accent color that was here
      state.theme.config.primaryColor = action.payload.color
      state.theme.config.accentBackgrounds = undefined
      state.theme.config.stylePrompt = undefined
    },
    updateSecondaryColors(
      state: ThemeEditingState,
      action: PayloadAction<{ colors: ThemeColor[] }>
    ) {
      if (!state.theme) return
      state.theme.config.secondaryColors = action.payload.colors
    },
    updateHeadingFont(
      state: ThemeEditingState,
      action: PayloadAction<{
        font: string
        fontsMap: FontMap
      }>
    ) {
      if (!state.theme) return
      const { font, fontsMap } = action.payload
      state.theme.headingFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: font,
        bodyFontId: state.theme.bodyFont,
      })
    },
    updateBodyFont(
      state: ThemeEditingState,
      action: PayloadAction<{ font: string; fontsMap: FontMap }>
    ) {
      if (!state.theme) return
      const { fontsMap, font } = action.payload
      state.theme.bodyFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        bodyFontId: font,
        headingFontId: state.theme.headingFont,
      })
    },
    updateHeadingFontWeight(
      state: ThemeEditingState,
      action: PayloadAction<{ weight: Theme['headingFontWeight'] }>
    ) {
      if (!state.theme) return
      state.theme.headingFontWeight = action.payload.weight
    },
    updateBodyFontWeight(
      state: ThemeEditingState,
      action: PayloadAction<{ weight: Theme['bodyFontWeight'] }>
    ) {
      if (!state.theme) return
      state.theme.bodyFontWeight = action.payload.weight
    },
    updateBodyColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['bodyColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.bodyColor = action.payload.color
    },
    updateHeadingColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['headingColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.headingColor = action.payload.color
    },
    updateHeadingFontAndWeight(
      state: ThemeEditingState,
      action: PayloadAction<{
        font: string
        weight: Theme['headingFontWeight']
        fontsMap: FontMap
      }>
    ) {
      if (!state.theme) return
      state.theme.headingFont = action.payload.font
      state.theme.headingFontWeight = action.payload.weight
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: action.payload.fontsMap,
        headingFontId: action.payload.font,
        bodyFontId: state.theme.bodyFont,
      })
    },
    updateBodyFontAndWeight(
      state: ThemeEditingState,
      action: PayloadAction<{
        font: string
        weight: Theme['bodyFontWeight']
        fontsMap: FontMap
      }>
    ) {
      if (!state.theme) return
      state.theme.bodyFont = action.payload.font
      state.theme.bodyFontWeight = action.payload.weight
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: action.payload.fontsMap,
        bodyFontId: action.payload.font,
        headingFontId: state.theme.headingFont,
      })
    },
    updateFontPair(
      state: ThemeEditingState,
      action: PayloadAction<{ fontPair: FontPair; fontsMap: FontMap }>
    ) {
      if (!state.theme) return
      const { fontPair, fontsMap } = action.payload
      state.theme.headingFont = fontPair.heading.font
      state.theme.bodyFont = fontPair.body.font
      state.theme.headingFontWeight = fontPair.heading.weight
      state.theme.bodyFontWeight = fontPair.body.weight
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: fontPair.body.font,
        bodyFontId: fontPair.heading.font,
      })
    },
    updateConfig(
      state: ThemeEditingState,
      action: PayloadAction<{ config: Theme['config'] }>
    ) {
      if (!state.theme) return
      state.theme.config = {
        ...state.theme.config,
        ...action.payload.config,
      }
    },
    toggleDisabilityAdjustment(state: ThemeEditingState) {
      if (!state.theme) return
      state.theme.config.disableReadabilityAdjustment =
        !state.theme.config.disableReadabilityAdjustment
    },
    updateLetterSpacingHeading(
      state: ThemeEditingState,
      action: PayloadAction<{
        letterSpacing: Theme['config']['letterSpacingHeading']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.letterSpacingHeading = action.payload.letterSpacing
    },
    updateLetterSpacingBody(
      state: ThemeEditingState,
      action: PayloadAction<{
        letterSpacing: Theme['config']['letterSpacingBody']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.letterSpacingBody = action.payload.letterSpacing
    },
    updateLineHeightHeading(
      state: ThemeEditingState,
      action: PayloadAction<{
        lineHeight: Theme['config']['lineHeightHeading']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.lineHeightHeading = action.payload.lineHeight
    },
    updateLineHeightBody(
      state: ThemeEditingState,
      action: PayloadAction<{
        lineHeight: Theme['config']['lineHeightBody']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.lineHeightBody = action.payload.lineHeight
    },
    updateBodyFontSize(
      state: ThemeEditingState,
      action: PayloadAction<{
        fontSize: Theme['config']['fontSize']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.fontSize = action.payload.fontSize
    },
    updateHeadingFontSize(
      state: ThemeEditingState,
      action: PayloadAction<{
        fontSize: Theme['config']['headingFontSize']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.headingFontSize = action.payload.fontSize
    },
    updateHeadingTransform(
      state: ThemeEditingState,
      action: PayloadAction<{
        transform: Theme['config']['headingTransform']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.headingTransform = action.payload.transform
    },
    updateShapeColorScheme(
      state: ThemeEditingState,
      action: PayloadAction<{
        shapeColorScheme: Theme['config']['shapeColorScheme']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.shapeColorScheme = action.payload.shapeColorScheme
    },
    updateShapeColors(
      state: ThemeEditingState,
      action: PayloadAction<{ colors: Theme['config']['shapeColors'] }>
    ) {
      if (!state.theme) return
      state.theme.config.shapeColors = action.payload.colors
    },
    updateLinkColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['linkColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.linkColor = action.payload.color
    },
    updateButtonRoundness(
      state: ThemeEditingState,
      action: PayloadAction<{ roundness: Theme['config']['buttonRoundness'] }>
    ) {
      if (!state.theme) return
      state.theme.config.buttonRoundness = action.payload.roundness
    },
    updateButtonColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: Theme['config']['buttonColor'] }>
    ) {
      if (!state.theme) return
      state.theme.config.buttonColor = action.payload.color
    },
    updateShapeShadow(
      state: ThemeEditingState,
      action: PayloadAction<{ shapeShadow: Theme['config']['shapeShadow'] }>
    ) {
      if (!state.theme) return
      state.theme.config.shapeShadow = action.payload.shapeShadow
    },
    updateShapeBorder(
      state: ThemeEditingState,
      action: PayloadAction<{ shapeBorder: Theme['config']['shapeBorder'] }>
    ) {
      if (!state.theme) return
      state.theme.config.shapeBorder = action.payload.shapeBorder
    },
    updateShapeFill(
      state: ThemeEditingState,
      action: PayloadAction<{ shapeFill: Theme['config']['shapeFill'] }>
    ) {
      if (!state.theme) return
      state.theme.config.shapeFill = action.payload.shapeFill
    },
    updateAccentCut(
      state: ThemeEditingState,
      action: PayloadAction<{ accentCut: Theme['config']['accentCut'] }>
    ) {
      if (!state.theme) return
      state.theme.config.accentCut = action.payload.accentCut
    },
    updateCardTransparency(
      state: ThemeEditingState,
      action: PayloadAction<{
        cardTransparency: Theme['config']['cardTransparency']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.cardTransparency = action.payload.cardTransparency
    },
    updateCardBorder(
      state: ThemeEditingState,
      action: PayloadAction<{ cardBorder: Theme['config']['cardBorder'] }>
    ) {
      if (!state.theme) return
      state.theme.config.cardBorder = action.payload.cardBorder
    },
    updateRoundness(
      state: ThemeEditingState,
      action: PayloadAction<{ roundness: Theme['config']['roundness'] }>
    ) {
      if (!state.theme) return
      state.theme.config.roundness = action.payload.roundness
    },
    updateCardShadow(
      state: ThemeEditingState,
      action: PayloadAction<{ cardShadow: Theme['config']['cardShadow'] }>
    ) {
      if (!state.theme) return
      state.theme.config.cardShadow = action.payload.cardShadow
    },

    updateStylePrompt(
      state: ThemeEditingState,
      action: PayloadAction<{ stylePrompt: Theme['config']['stylePrompt'] }>
    ) {
      if (!state.theme) return
      state.theme.config.stylePrompt = action.payload.stylePrompt
    },
    updateAccentBackgrounds(
      state: ThemeEditingState,
      action: PayloadAction<{
        accentBackgrounds: Theme['config']['accentBackgrounds']
      }>
    ) {
      if (!state.theme) return
      state.theme.config.accentBackgrounds = action.payload.accentBackgrounds
    },

    handleBackgroundImageUploadStart(
      state: ThemeEditingState,
      action: PayloadAction<{ background: Theme['config']['background'] }>
    ) {
      if (!state.theme) return
      state.themeBackgroundImgStatus = 'loading'
      state.theme.config.background = action.payload.background
    },
    handleBackgroundImageUploadSuccess(
      state: ThemeEditingState,
      action: PayloadAction<{ background: Theme['config']['background'] }>
    ) {
      if (!state.theme) return
      state.themeBackgroundImgStatus = 'idle'
      state.theme.config.background = action.payload.background
    },
    handleBackgroundImageUploadError(state: ThemeEditingState) {
      state.themeBackgroundImgStatus = 'error'
    },
    handleLogoImageUploadStart(state: ThemeEditingState) {
      state.themeLogoImgStatus = 'loading'
    },
    handleLogoImageUploadSuccess(
      state: ThemeEditingState,
      action: PayloadAction<{ logoImage: Theme['config']['logoImage'] }>
    ) {
      if (!state.theme) return
      state.themeLogoImgStatus = 'idle'
      state.theme.logoUrl = action.payload.logoImage?.src || undefined
      state.theme.config.logoImage = action.payload.logoImage
    },
    handleLogoImageUploadError(state: ThemeEditingState) {
      state.themeLogoImgStatus = 'error'
    },
    handleRemoveLogoImage(state: ThemeEditingState) {
      if (!state.theme) return
      state.theme.logoUrl = undefined
      state.theme.config.logoImage = undefined
    },

    // ADVANCED
    updateColorKeywords(
      state: ThemeEditingState,
      action: PayloadAction<{
        colorKeywords: string[]
      }>
    ) {
      if (!state.theme) return
      if (!state.theme.config.keywords) {
        state.theme.config.keywords = { color: [], tone: [] }
      }
      state.theme.config.keywords.color = action.payload.colorKeywords
    },
    updateToneKeywords(
      state: ThemeEditingState,
      action: PayloadAction<{
        toneKeywords: string[]
      }>
    ) {
      if (!state.theme) return
      if (!state.theme.config.keywords) {
        state.theme.config.keywords = { color: [], tone: [] }
      }
      state.theme.config.keywords.tone = action.payload.toneKeywords
    },
    updateThemePriority(
      state: ThemeEditingState,
      action: PayloadAction<{ priority?: Theme['priority'] }>
    ) {
      if (!state.theme) return
      state.theme.priority = action.payload.priority || 0
    },
    updateThemeContentStyles(
      state: ThemeEditingState,
      action: PayloadAction<{ contentStyles: Theme['config']['contentStyles'] }>
    ) {
      if (!state.theme) return
      state.theme.config.contentStyles = action.payload.contentStyles
    },
  },
})

export const {
  resetThemeEditingState,
  setOriginalTheme,
  setPreviewingDocContent,
  updateThemeName,
  initBlankTheme,
  setBaseTheme,
  updateCardColor,
  updateCardBorderColor,
  updatePrimaryColor,
  updateSecondaryColors,
  updateHeadingFont,
  updateBodyFont,
  updateHeadingFontWeight,
  updateBodyFontWeight,
  updateBodyColor,
  updateHeadingColor,
  updateHeadingFontAndWeight,
  updateBodyFontAndWeight,
  updateConfig,
  updateFontPair,
  toggleDisabilityAdjustment,
  updateLetterSpacingHeading,
  updateLetterSpacingBody,
  updateLineHeightHeading,
  updateLineHeightBody,
  updateBodyFontSize,
  updateHeadingFontSize,
  updateHeadingTransform,

  updateShapeColorScheme,
  updateShapeColors,
  updateLinkColor,
  updateButtonRoundness,
  updateButtonColor,
  updateShapeShadow,
  updateShapeBorder,
  updateShapeFill,
  updateAccentCut,
  updateCardTransparency,
  updateCardBorder,
  updateRoundness,
  updateCardShadow,
  updateStylePrompt,
  updateAccentBackgrounds,

  handleBackgroundImageUploadStart,
  handleBackgroundImageUploadSuccess,
  handleBackgroundImageUploadError,

  handleLogoImageUploadStart,
  handleLogoImageUploadSuccess,
  handleLogoImageUploadError,
  handleRemoveLogoImage,

  // Advanced
  updateColorKeywords,
  updateToneKeywords,
  updateThemePriority,
  updateThemeContentStyles,
} = ThemeEditingSlice.actions

type ThemeEditingSliceState = Pick<RootState, 'ThemeEditing'>

// Selectors
export const selectEditingTheme = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.theme

export const selectThemeName = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.theme?.name

export const selectThemeInputToSave =
  (workspaceId: string) =>
  (
    state: ThemeEditingSliceState
  ): CreateThemeInput & {
    id?: string
  } => {
    return {
      workspaceId,
      id: state.ThemeEditing.theme?.id,
      name: state.ThemeEditing.theme?.name || t`Untitled theme`,
      headingFont: state.ThemeEditing.theme?.headingFont,
      headingFontWeight: state.ThemeEditing.theme?.headingFontWeight,
      bodyFont: state.ThemeEditing.theme?.bodyFont,
      bodyFontWeight: state.ThemeEditing.theme?.bodyFontWeight,
      config: state.ThemeEditing.theme?.config,
      priority: state.ThemeEditing.theme?.priority,
      // The only way to send a delete through is to pass null
      // @ts-ignore
      logoUrl: state.ThemeEditing.theme?.logoUrl ?? null,
      // @ts-ignore
      accentColor: state.ThemeEditing.theme?.accentColor ?? null,
    }
  }

export const selectPreviewingDocContent = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.previewingDocContent

export const selectAnyThemeImagesUploading = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.themeBackgroundImgStatus === 'loading' ||
  state.ThemeEditing.themeLogoImgStatus === 'loading'

export const selectThemeHasChanges = (state: ThemeEditingSliceState) => {
  return (
    state.ThemeEditing?.theme?.id === 'new' ||
    !isEqual(state.ThemeEditing.theme, state.ThemeEditing.originalTheme)
  )
}

export const selectEditingThemeId = (state: ThemeEditingSliceState) =>
  state.ThemeEditing?.theme?.id

export const selectIsNewTheme = (state: ThemeEditingSliceState) =>
  state.ThemeEditing?.theme && state.ThemeEditing?.theme?.id === 'new'

export const selectThemeLogoSrc = (state: ThemeEditingSliceState) =>
  getThemeLogoSrc(state.ThemeEditing.theme)

export const ThemeEditingReducer = ThemeEditingSlice.reducer
