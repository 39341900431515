import { useEffect } from 'react'

export const useScriptInnerHTML = (enabled: boolean, innerHTML: string) => {
  useEffect(() => {
    if (!enabled || !innerHTML) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = innerHTML
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [enabled, innerHTML])
}

export const useScript = (
  url: string,
  {
    predicate,
    onload = null,
    onerror,
  }: {
    predicate?: () => boolean
    onload?: ((this: GlobalEventHandlers, ev: Event) => any) | null
    onerror?: OnErrorEventHandler
  }
) => {
  useEffect(() => {
    if (predicate?.() === false) return

    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.defer = true
    script.onload = onload
    script.onerror = (event: Event) => {
      console.error(
        `Failed to load script from ${(event?.target as HTMLScriptElement).src}`
      )
      onerror?.(event)
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url, predicate, onload, onerror])
}
