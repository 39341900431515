import { createSlice } from '@reduxjs/toolkit'

import { TaskContext } from 'modules/ai/chat/Task'
import { ChatCompletionProvider } from 'modules/api'

export interface DesignPartnerAnalyticsState {
  interactions: { [interactionId: string]: InteractionRecord }
}

type InteractionRecord = Omit<TaskContext, 'editorContext'> & {
  provider: ChatCompletionProvider
  cardId: string
  // filled in with trackClassification
  classification?: string

  // filled in with trackInputContent
  inputContent?: string

  // filled in with trackResponseMessage
  outputMessage?: string
  outputContent?: string
}

const initialState: DesignPartnerAnalyticsState = {
  interactions: {},
}

const DesignPartnerAnalyticsSlice = createSlice({
  name: 'DesignPartnerAnalytics',
  initialState,
  reducers: {
    reset: () => initialState,
    startDesignPartnerInteraction(
      state,
      action: {
        payload: { taskContext: TaskContext; provider: ChatCompletionProvider }
      }
    ) {
      const provider = action.payload.provider
      const { editorContext, ...taskContext } = action.payload.taskContext
      const existing = state.interactions[taskContext.interactionId]
      if (existing) {
        console.warn(
          '[DesignPartnerAnalytics] Interaction already exists',
          taskContext.interactionId
        )
        return
      }

      state.interactions[taskContext.interactionId] = {
        ...taskContext,
        provider,
        cardId: editorContext.card.cardId,
      }
    },
    storeClassification(
      state,
      action: { payload: { taskContext: TaskContext; classification: string } }
    ) {
      const { taskContext, classification } = action.payload

      const existing = state.interactions[taskContext.interactionId]
      if (!existing) {
        console.warn(
          '[DesignPartnerAnalytics.trackClassification] No interaction exists',
          taskContext.interactionId
        )
        return
      }

      existing.classification = classification
    },
    storeInputContent(
      state,
      action: {
        payload: {
          taskContext: TaskContext
          inputContent: string
        }
      }
    ) {
      const { taskContext, inputContent } = action.payload

      const existing = state.interactions[taskContext.interactionId]
      if (!existing) {
        console.warn(
          '[DesignPartnerAnalytics.trackInputContent] No interaction exists',
          taskContext.interactionId
        )
        return
      }

      existing.inputContent = inputContent
    },
    storeResponseMessage(
      state,
      action: {
        payload: {
          taskContext: TaskContext
          outputMessage: string
          outputContent?: string
        }
      }
    ) {
      const { taskContext, outputContent, outputMessage } = action.payload

      const existing = state.interactions[taskContext.interactionId]
      if (!existing) {
        console.warn(
          '[DesignPartnerAnalytics.trackResponseMessage] No interaction exists',
          taskContext.interactionId
        )
        return
      }

      existing.outputMessage = outputMessage
      existing.outputContent = outputContent
    },
  },
})

type ReduxState = {
  [DesignPartnerAnalyticsSlice.name]: DesignPartnerAnalyticsState
}

export const {
  startDesignPartnerInteraction,
  storeClassification,
  storeInputContent,
  storeResponseMessage,
} = DesignPartnerAnalyticsSlice.actions

// Selectors
export const selectDesignPartnerAnalyticsRecord =
  (interactionId: string) =>
  (state: ReduxState): InteractionRecord | null => {
    return state.DesignPartnerAnalytics.interactions[interactionId] ?? null
  }

// Reducer
export const reducer = DesignPartnerAnalyticsSlice.reducer
