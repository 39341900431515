import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { memoize } from 'lodash'
import { useEffect, useState } from 'react'

import { config } from 'config'
import { getIsOnline } from 'modules/connection/utils'
import { getApolloOfflineCache } from 'modules/offline/manager/caches/ApolloOfflineCache'
import { isOfflineModeEnabled } from 'modules/offline/manager/utils'

import { cacheConfig } from './cache'

export * from './hooks'

let client: ApolloClient<NormalizedCacheObject>
let wsLink: WebSocketLink

export const getWsLink = () => wsLink

const RETRY_ATTEMPTS = 2

// On the server, we overwrite the regular GraphQLError with our own formatting:
// https://github.com/gamma-app/gamma/blob/2ca7a4994e6384c49e83d7a0805c7b40cd86cd10/packages/server/src/app.module.ts#L107-L115
export type FormattedGraphQLError = {
  code: string
  message: string
}
// Export for testing
export const defaultOptions: DefaultOptions = {
  watchQuery: {
    // See https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
    fetchPolicy: 'cache-and-network',

    // Details on how this is expected to behave here:
    //   https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',

    returnPartialData: true,

    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',

    returnPartialData: true,

    errorPolicy: 'all',
  },
}

/**
 * This hook helps ensure that the apollo client's cached
 * has been properly warmed up if offline mode is enabled
 * and we detect your connection is offline
 */
export const useApolloClientReady = () => {
  const [ready, setReady] = useState(false)
  const [apolloOfflineCache] = useState(() => getApolloOfflineCache())

  useEffect(() => {
    const offlineModeEnabled = isOfflineModeEnabled()
    const isOnline = getIsOnline()

    if (offlineModeEnabled && !isOnline) {
      console.debug(
        'offlineMode - [useApolloClientReady] offline - using offline cache'
      )
      // we must wait for the cache to be warmed when offline before
      // rendering the page
      apolloOfflineCache.useOfflineCache().then(() => {
        setReady(true)
      })
    } else {
      setReady(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ready
}

export const getApolloClient = () => client

/**
 * opts.supportOfflineMode is a little different than `isOfflineModeEnabled`
 * if true then it's saying that during the lifecycle of the app that
 * you could enable offline mode, thus turning on the OfflineApolloCache
 *
 * if false then we dont want to create an OfflineApolloCache at all
 *
 * WARNING: if you invoke this function with `supportOfflineMode: true` and
 * do NOT call `initOfflineMode` then the cacheWarmLink will lock and never be resolved
 *
 */
export const initApolloClient = (opts: { supportOfflineMode: boolean }) => {
  if (!config.IS_CLIENT_SIDE) {
    client = getServerApolloClient()
    return client
  }

  client = new ApolloClient({
    link: getSplitLink(),
    defaultOptions,
    cache: new InMemoryCache(cacheConfig),
    connectToDevTools: config.APPLICATION_ENVIRONMENT !== 'production',
  })

  window['apollo'] = client
  window['getCache'] = () => client.cache.extract()

  if (!opts.supportOfflineMode) {
    // no need to connect the client to the cache if offline mode is not supported
    // ie: on marketing page or server side
    return client
  }
  getApolloOfflineCache().setClient(client)

  return client
}

/**
 * Server side apollo client doesn't support a lot of the features of the client side version
 * - no InMemoryCache
 * - very simplified link chain, no contentful, no websockets
 * -  no memoization
 */
const getServerApolloClient = memoize(() => {
  const gammaHttpLink = createHttpLink({
    uri: `${config.API_HOST}/graphql`,
    credentials: 'include',
  })

  const contentfulHttpLink = createHttpLink({
    uri: `${config.CONTENTFUL_GRAPHQL_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${config.CONTENTFUL_API_TOKEN}`,
    },
  })

  const link = split(
    (operation) => operation.getContext().clientName === 'contentfulGraphql',
    contentfulHttpLink,
    gammaHttpLink
  )

  return new ApolloClient({
    link,
    // NOTE(jordan): these options may not be totally correct for server side graphql
    // but they are what we were using in the past.
    defaultOptions,
    cache: new InMemoryCache(cacheConfig),
  })
})

type ShareTokenHeader = {
  'share-token'?: string
}

const getShareTokenHeader = (): ShareTokenHeader => {
  const additionalHeaders: ShareTokenHeader = {}
  if (config.SHARE_TOKEN || config.SHARE_TOKEN === '') {
    additionalHeaders['share-token'] = config.SHARE_TOKEN
  }
  return additionalHeaders
}

const getSplitLink = () => {
  const httpLink = createHttpLink({
    uri: `${config.API_HOST}/graphql`,
    credentials: 'include',
  })

  const contentfulHttpLink = createHttpLink({
    uri: `${config.CONTENTFUL_GRAPHQL_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${config.CONTENTFUL_API_TOKEN}`,
    },
  })

  if (!process.browser) return httpLink

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getShareTokenHeader(),
      },
    }
  })

  wsLink = new WebSocketLink({
    uri: `${config.API_HOST.replace('https', 'wss')}/graphql`,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        // We cant pass the share-token in the headers with WebSocketLink,
        // so set it as a connectionParam and handle that on the server
        // See more here: https://github.com/apollographql/apollo-client/issues/6782
        return {
          ...getShareTokenHeader(),
        }
      },
    },
  })

  // Retry when network errors happen, not GraphQL errors
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  const retryLink = new RetryLink({
    attempts: (count, _operation) => {
      if (!getIsOnline()) {
        // Dont retry if our connection isn't healthy
        return false
      }
      return count <= RETRY_ATTEMPTS
    },
  })

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors as unknown as FormattedGraphQLError[]) {
        console.log(
          `[GraphQL error]: Code: ${err.code}. Message: ${err.message}}`
        )
        if (err.code === 'UNAUTHENTICATED') {
          // Redirect to logout
          window.location.href = `${config.API_HOST}/logout`
        }
      }
    }
  })

  // For normal GraphQL requests, use a link chain.
  // The order here matters (httpLink should be last)
  // See https://www.apollographql.com/docs/react/api/link/introduction/
  const gammaHttpLink = from([retryLink, errorLink, authLink, httpLink])

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    // Based on the split above, use the websocket link for subscriptions
    wsLink,

    split(
      (operation) => operation.getContext().clientName === 'contentfulGraphql',
      contentfulHttpLink,
      gammaHttpLink
    )
  )
}
