import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'
import { isMobileDevice } from 'utils/deviceDetection'

import { PreviewSize } from './types'

export type PreviewState = {
  previewSize: PreviewSize | null
}

const initialState: PreviewState = {
  previewSize: null,
}

const PreviewSlice = createSlice({
  name: 'Preview',
  initialState,
  reducers: {
    reset: () => initialState,

    setPreviewSize(
      state,
      action: { payload: { previewSize: PreviewSize | null } }
    ) {
      const { previewSize } = action.payload
      state.previewSize = previewSize
    },
  },
})

export const { setPreviewSize, reset } = PreviewSlice.actions

type PreviewSliceState = Pick<RootState, 'Preview'>

// Selectors
export const selectShouldRenderMobile = (state: PreviewSliceState) => {
  return state.Preview.previewSize === 'mobile' || isMobileDevice()
}

export const selectPreviewSize = (state: PreviewSliceState) => {
  return state.Preview.previewSize
}

export const selectPreviewEnabled = (state: PreviewSliceState) => {
  return state.Preview.previewSize !== null
}

// Reducer
export const PreviewReducer = PreviewSlice.reducer
