import { QueryHookOptions } from '@apollo/client'

import {
  GetSnapshotQuery,
  GetSnapshotQueryVariables,
  useGetSnapshotQuery,
} from './generated/graphql'

/**
 * Small wrapper around `useGetSnapshotQuery` so that we can use it imperatively.
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 *
 */
export const useGetSnapshotLazyQueryImperative = (
  options: QueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables> = {}
) => {
  const { refetch } = useGetSnapshotQuery({
    ...options,
    skip: true,
  })

  const imperativelyCallQuery = (queryVariables: GetSnapshotQueryVariables) => {
    return refetch(queryVariables)
  }

  return imperativelyCallQuery
}
