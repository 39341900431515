import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Card } from 'modules/api'
import { RootState } from 'modules/redux'

export type CardTextMap = {
  [key: string]: {
    title?: string
    isFirstCard?: boolean
    text?: string
  }
}

export type CardState = {
  cards: Card[]
  localCardTextMap: CardTextMap
}

const initialState: CardState = {
  cards: [],
  localCardTextMap: {},
}

const CardsSlice = createSlice({
  name: 'Cards',
  initialState,
  reducers: {
    reset: () => initialState,
    setCards(
      state: CardState,
      action: PayloadAction<{
        cards: Card[]
      }>
    ) {
      const { cards } = action.payload
      state.cards = cards
    },
    setCardText(
      state: CardState,
      action: PayloadAction<{
        localCardTextMap: CardTextMap
      }>
    ) {
      state.localCardTextMap = action.payload.localCardTextMap
    },
  },
})

export const { setCards, setCardText, reset } = CardsSlice.actions

type SliceState = Pick<RootState, 'Cards'>

// Selectors
export const selectCards = (state: SliceState) => state.Cards.cards

export const selectCard =
  (id?: string) =>
  (state: SliceState): Card | undefined => {
    return state.Cards.cards.find((c) => c.id === id)
  }

export const selectFirstCardTitle =
  (docId?: string) =>
  (state: Pick<RootState, 'Cards' | 'TipTap'>): string | undefined => {
    if (docId !== state.TipTap.doc?.id) return

    return Object.entries(state.Cards.localCardTextMap).find(
      ([id, c]) => c.isFirstCard && state.TipTap.cardIds.includes(id)
    )?.[1].title
  }

export const selectFirstCardText = (state: SliceState): string | undefined => {
  return Object.values(state.Cards.localCardTextMap).find((c) => c.isFirstCard)
    ?.text
}

export const selectTitleCard = (state: SliceState): Card => {
  return state.Cards.cards[0]
}

export const selectCardText =
  (id?: string) =>
  (state: SliceState): valueof<CardTextMap> | undefined => {
    if (!id || !state.Cards.localCardTextMap[id]) return undefined
    return state.Cards.localCardTextMap[id]
  }

export const selectCardTextMap = (state: SliceState): CardTextMap => {
  return state.Cards.localCardTextMap
}

// Reducer
export const CardsReducer = CardsSlice.reducer
