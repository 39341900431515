import { ProductFeatures } from '@gammatech/authorization'

import { config } from 'config'
import { Doc, Organization } from 'modules/api/generated/graphql'
import { featureFlags, useFeatureFlag } from 'modules/featureFlags'
import { abilityFactory, useCan, useUserContext } from 'modules/user'
import { getCurrentUser, getCurrentWorkspace } from 'modules/user/context'

import { getProductForWorkspace } from '../utils'

// Hook version for use within React, updates reactively
export const useCanUseProductFeature = (
  feature: ProductFeatures,
  workspace?: Organization // Default to the user's current workspace
) => {
  const { currentWorkspace } = useUserContext()
  const workspaceToUse = workspace || currentWorkspace
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canUseFeature = useCan(feature, workspaceToUse)

  if (!workspaceToUse) {
    // If there is no workspace, assume the user can't use the feature.
    return false
  }

  return hasProAccessLD || canUseFeature
}

// Non-hook version for use outside of React, e.g. in editor commands
export const getCanUseProductFeature = (
  feature: ProductFeatures,
  workspace?: Organization // Default to the user's current workspace
) => {
  const user = getCurrentUser()
  const ability = abilityFactory.createForUser(user)
  if (!workspace) {
    workspace = getCurrentWorkspace()
  }

  if (!workspace) {
    // If there is no workspace, assume the user can't use the feature.
    return false
  }
  const canUseFeature = ability.can(feature, workspace)
  const hasProAccessLD = featureFlags.get('hasProAccess')
  return hasProAccessLD || canUseFeature
}

/**
 * @deprecated Use useCanUseProductFeature instead.
 */
export const useCanUseCustomFonts = () => {
  const { currentWorkspace } = useUserContext()
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canUseCustomFonts = useCan('custom_fonts', currentWorkspace)
  return hasProAccessLD || canUseCustomFonts
}

/**
 * @deprecated Use useCanUseProductFeature instead.
 */
export const useHasAIProModels = () => {
  const { currentWorkspace } = useUserContext()
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canUseProModels = useCan('ai_premium_models', currentWorkspace)
  return hasProAccessLD || canUseProModels
}

export const useChangeHistoryLimit = (): 'unlimited' | '30' | '7' => {
  const { currentWorkspace } = useUserContext()
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canUseUnlimitedChangeHistory = useCan(
    'change_history_unlimited',
    currentWorkspace
  )
  return canUseUnlimitedChangeHistory || hasProAccessLD ? 'unlimited' : '30'
}

export const useCanUseSites = () => {
  // Later, when we turn sites GA,
  const { currentWorkspace } = useUserContext()
  const hasSitesAccessLD = useFeatureFlag('sites')
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canUseSites = useCan('custom_domains', currentWorkspace)
  return (
    config.GAMMA_E2E_USER || canUseSites || hasProAccessLD || hasSitesAccessLD
  )
}

export const useCanHideMadeWithGammaBadge = (
  docWorkspace?: Doc['organization']
) => {
  const hasProAccessLD = useFeatureFlag('hasProAccess')
  const canHideGammaBadge = useCan('hide_gamma_badge', docWorkspace)

  if (!docWorkspace) {
    return undefined // We can't say for sure yet.
  }

  if (canHideGammaBadge) {
    // Short circuit if doc organization allows
    return true
  }

  // Else check launchdarkly
  return hasProAccessLD
}

// Allow paying customer docs to skip the link interstitial
export const useCanSkipLinkInterstitial = (
  docWorkspace?: Doc['organization']
) => {
  const product = getProductForWorkspace(docWorkspace)

  return Boolean(product)
}
