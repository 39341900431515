import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type MediaDrawerState = {
  currentSourceKey: string
  overrideDefault: boolean
}

const initialState: MediaDrawerState = {
  currentSourceKey: 'image.ai-generated',
  overrideDefault: false,
}

const MediaDrawerSlice = createSlice({
  name: 'MediaDrawer',
  initialState,
  reducers: {
    setCurrentSourceKey(
      state,
      action: PayloadAction<{ sourceKey: string; overrideDefault?: boolean }>
    ) {
      state.currentSourceKey = action.payload.sourceKey
      if (action.payload.overrideDefault !== undefined) {
        state.overrideDefault = action.payload.overrideDefault
      } else {
        state.overrideDefault = false
      }
    },
    resetCurrentSourceKey(state) {
      state.currentSourceKey = initialState.currentSourceKey
      state.overrideDefault = initialState.overrideDefault
    },
  },
})

export const {
  setCurrentSourceKey: setMediaDrawerSourceKey,
  resetCurrentSourceKey: resetMediaDrawerSourceKey,
} = MediaDrawerSlice.actions

type SliceState = Pick<RootState, 'MediaDrawer'>

export const selectCurrentMediaDrawerSourceKey = (state: SliceState): string =>
  state.MediaDrawer.currentSourceKey

export const selectOverrideDefault = (state: SliceState): boolean =>
  state.MediaDrawer.overrideDefault

export const MediaDrawerReducer = MediaDrawerSlice.reducer
