import { Editor } from '@tiptap/core'
import React, { useContext } from 'react'

export type EditorContextType = {
  docId: string // nanoid
  isUnauthorized: boolean | undefined // undefined value represents the doc and user has yet to be loaded
  passwordRequired: boolean | undefined
  setCollaborativeEditorInstance: (editor: Editor) => void
  getCollaborativeEditorInstance: () => Editor | null
  confirmNavigation: () => Promise<void>
  onPasswordSubmitted?: (password: string) => Promise<any>
  editor?: Editor
}

export const EditorContext = React.createContext<EditorContextType>({} as any)

export function useEditorContext() {
  const ctx = useContext(EditorContext)
  return ctx
}
