import { Heading } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import { isMobileDevice } from 'utils/deviceDetection'

export const PanelHeader = ({ children }: PropsWithChildren<{}>) => {
  const isMobile = isMobileDevice()
  return (
    <Heading
      fontSize={isMobile ? '2xl' : '3xl'}
      fontWeight={isMobile ? 'bold' : 800}
      letterSpacing={isMobile ? 'tight' : -1}
      noOfLines={1}
    >
      {children}
    </Heading>
  )
}
