import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'
import { selectUser } from 'modules/user/reducer'

import { DEFAULT_GAMMA_LOCALE, SupportedLocaleKey } from './constants'

export type I18nState = {
  userDisplayLocale: SupportedLocaleKey
  previewLocale: SupportedLocaleKey | null
  pseudoLocaleActive: boolean
}

const initialState: I18nState = {
  previewLocale: null,
  userDisplayLocale: DEFAULT_GAMMA_LOCALE,
  pseudoLocaleActive: false,
}

const I18nSlice = createSlice({
  name: 'I18n',
  initialState,
  reducers: {
    reset: () => initialState,
    setDisplayLocale(
      state: I18nState,
      action: PayloadAction<{
        locale: SupportedLocaleKey
      }>
    ) {
      state.userDisplayLocale = action.payload.locale
    },
    setPreviewLocale(
      state: I18nState,
      action: PayloadAction<{
        locale: SupportedLocaleKey | null
      }>
    ) {
      state.previewLocale = action.payload.locale
    },
    setPseudoLocaleActive(
      state: I18nState,
      action: PayloadAction<{
        isActive: boolean
      }>
    ) {
      state.pseudoLocaleActive = action.payload.isActive
    },
  },
})

export const { setPseudoLocaleActive, setPreviewLocale, setDisplayLocale } =
  I18nSlice.actions

type SliceState = Pick<RootState, 'I18n' | 'User'>

// Selectors
export const selectDisplayLocale = (state: SliceState) =>
  state.I18n.userDisplayLocale

export const selectPreviewLocale = (state: SliceState) =>
  state.I18n.previewLocale

export const selectPseudoLocaleActive = (state: SliceState) =>
  state.I18n.pseudoLocaleActive

export const selectUserLocaleInitialized = (state: SliceState) => {
  const user = selectUser(state)
  if (!user) return false

  if (!user.settings?.locale) return true

  return user.settings.locale === selectDisplayLocale(state)
}

// Reducer
export const I18nReducer = I18nSlice.reducer
