import memoize from 'fast-memoize'
import { sortBy } from 'lodash'
import tinycolor from 'tinycolor2'

import { lerp } from 'utils/easings'

export const getColorLightness = (color: tinycolor.ColorInput) => {
  return tinycolor(color).toHsl().l
}

export const colorWithLightness = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    const hsl = tinycolor(color).toHsl()
    hsl.l = lightness
    return tinycolor(hsl).toHex8String()
  }
)

export const lightenColor = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    return tinycolor(color).lighten(lightness).toHex8String()
  }
)

export const saturateColor = memoize(
  (color: tinycolor.ColorInput, saturation: number) => {
    return tinycolor(color).saturate(saturation).toHex8String()
  }
)

// Brighten is like lighten, but it pushes things more toward gray
export const brightenColor = memoize(
  (color: tinycolor.ColorInput, lightness: number) => {
    return tinycolor(color).brighten(lightness).toHex8String()
  }
)

export const colorWithOpacity = memoize(
  (color: tinycolor.ColorInput, opacity: number) => {
    return tinycolor(color).setAlpha(opacity).toHex8String()
  }
)

export const blendColors = memoize(
  (
    color1: tinycolor.ColorInput,
    color2: tinycolor.ColorInput,
    amount2: number // 0 to 1
  ) => {
    if (amount2 === 0) return tinycolor(color1).toHex8String()
    if (amount2 === 1) return tinycolor(color2).toHex8String()
    return tinycolor.mix(color1, color2, amount2 * 100).toHex8String()
  }
)

const DEFAULT_CONTRAST_RATIO = 4.5 // AA Small / AAA Large

export const isColorReadable = (
  textColor: string,
  backgroundColor: string,
  contrastRatio: number = DEFAULT_CONTRAST_RATIO
) => {
  return tinycolor.readability(textColor, backgroundColor) >= contrastRatio
}

const LIGHTNESS_OPTIONS = [0, 0.15, 0.3, 0.4, 0.6, 0.7, 0.85, 1]

export const makeColorReadable = memoize(
  (
    color: string,
    contrast: string,
    contrastRatio: number = DEFAULT_CONTRAST_RATIO,
    whiteBlackOnly: boolean = false
  ) => {
    if (isColorReadable(color, contrast, contrastRatio)) {
      return color
    }

    // Depending on the context, we might generate different shades of the same color (e.g. for links)
    // or just fall back to white and black (for body text)
    if (whiteBlackOnly) {
      const whiteReadability = tinycolor.readability('#FFFFFF', contrast)
      const blackReadability = tinycolor.readability('#000000', contrast)
      if (whiteReadability > blackReadability) {
        return '#FFFFFF'
      } else {
        return '#000000'
      }
    }

    const colorLightness = getColorLightness(color)
    const alternateColors = sortBy(LIGHTNESS_OPTIONS, (lightness) =>
      Math.abs(lightness - colorLightness)
    ).map((lightness) => colorWithLightness(color, lightness))

    let mostReadable = alternateColors[0],
      bestReadability = 0
    for (const option of alternateColors) {
      const readability = tinycolor.readability(option, contrast)
      if (readability >= contrastRatio) {
        return option
      }
      if (!bestReadability || readability > bestReadability) {
        mostReadable = option
        bestReadability = readability
      }
    }
    return mostReadable
  }
)

export const isColorDark = memoize((color: tinycolor.ColorInput) => {
  return tinycolor(color).isDark()
})

export const lerpColor = (
  start: tinycolor.ColorInput,
  end: tinycolor.ColorInput,
  t: number // 0 to 1
) => {
  const startRgb = tinycolor(start).toRgb()
  const endRgb = tinycolor(end).toRgb()
  const r = lerp(startRgb.r, endRgb.r, t)
  const g = lerp(startRgb.g, endRgb.g, t)
  const b = lerp(startRgb.b, endRgb.b, t)
  return `rgb(${r}, ${g}, ${b})`
}

/**
 * Sets the `lightness` of `color` for appropriate contrast.
 *
 * @see getContrastAdjustment
 */
export function setContrast(
  color: tinycolor.Instance,
  contrastColor: string | tinycolor.Instance,
  minContrast: number,
  direction?: 'darken' | 'lighten'
): void {
  const adjustment = getContrastAdjustment(
    color,
    contrastColor,
    minContrast,
    direction
  )

  if (adjustment.direction && adjustment.amount) {
    color[adjustment.direction](adjustment.amount)
  }
}

/**
 * Recursive function, figures out the amount by which the lightness of
 * `originalColor` needs to be adjusted so its contrast against `contrastColor`
 * meets or exceeds `minContrast`.
 *
 * @param direction - If not provided, will try to guess
 * @param i         - Only used for recursion.
 */
export const getContrastAdjustment = memoize(
  (
    originalColor: tinycolor.Instance,
    contrastColor: tinycolor.Instance | string,
    minContrast: number = DEFAULT_CONTRAST_RATIO,
    direction?: 'darken' | 'lighten',
    i: number = 0
  ): { amount: number; direction?: 'darken' | 'lighten' } => {
    const step = 3
    const amount = step * i
    const color = originalColor.clone()

    if (amount > 90) {
      return { amount, direction }
    }

    switch (direction) {
      case 'darken':
      case 'lighten':
        color[direction](amount)
        break

      // Test which direction improves the contrast
      default: {
        // Don't want the probing to actually affect the color.
        const clone = color.lighten(step)
        if (
          tinycolor.readability(clone, contrastColor) >
          tinycolor.readability(originalColor, contrastColor)
        ) {
          direction = 'lighten'
        } else {
          direction = 'darken'
        }
      }
    }

    const currentContrast = tinycolor.readability(color, contrastColor)

    if (currentContrast >= minContrast) {
      return { amount, direction }
    }

    return getContrastAdjustment(
      originalColor,
      contrastColor,
      minContrast,
      direction,
      i + 1
    )
  }
)

/**
 * Modifies the existing `color` so it has the specified brightness.
 *
 * @param targetBrightness - A number between 0 and 255
 */
export function setColorBrightness(
  color: tinycolor.Instance,
  targetBrightness: number
): void {
  const initialBrightnessPercentage = color.getBrightness() / 2.55
  const targetBrightnessPercentage = targetBrightness / 2.55

  color.brighten(targetBrightnessPercentage - initialBrightnessPercentage)
}

export function getCSSVarForChakraColor(color: string) {
  return `var(--chakra-colors-${color.replace('.', '-')})`
}

export const isHexColor = (color: string) => {
  return color.startsWith('#') && color.length === 7
}
