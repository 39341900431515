import { Editor } from '@tiptap/core'

import { useFeatureFlag } from 'modules/featureFlags'

import {
  DocGenerateInfo,
  DocSettings,
  DocSettingsWithDefaults,
  DocumentAttributes,
} from './attributes'
import {
  DOC_FORMAT_CATEGORIES,
  DocFormatKey,
  STYLE_TEMPLATES,
  StyleData,
  StyleTemplateKey,
} from './DocFormats'

export const DEFAULT_DOC_TYPE = 'deck'

export const getDocAttrs = (editor: Editor): DocumentAttributes => {
  return editor.state.doc.firstChild!.attrs as DocumentAttributes
}

export const getDocFormatKey = (attrs: DocumentAttributes) => {
  return attrs.format || DEFAULT_DOC_TYPE
}

export const getDocFormatFromAttrs = (attrs: DocumentAttributes) => {
  const docType = getDocFormatKey(attrs)
  return findDocFormat(docType)
}

export const findDocFormat = (key: DocFormatKey) => {
  return DOC_FORMAT_CATEGORIES[key] || DOC_FORMAT_CATEGORIES.deck
}

export const getDocGenerateInfo = (editor: Editor): DocGenerateInfo => {
  return getDocAttrs(editor).generateInfo
}

export const useDocFormatCategories = () => {
  const canUseSocial = useFeatureFlag('socialFormat')

  const categories = Object.values(DOC_FORMAT_CATEGORIES)
  return canUseSocial
    ? categories
    : categories.filter((category) => category.formatType !== 'social')
}

const unchangeableAttributes = ['cardDimensions', 'defaultFullBleed']

export const findMatchingPageStyleTemplateKey = (
  docSettings: DocSettings,
  format: DocFormatKey | null
): StyleTemplateKey | undefined => {
  if (docSettings.stylesDerivedFrom === 'custom') {
    return 'custom'
  }
  const foundEntry = Object.entries(STYLE_TEMPLATES).find(
    ([_styleTemplateKey, style]) => {
      const docSettingsMatch = unchangeableAttributes.every(
        (key) => docSettings[key] === style.docSettings[key]
      )
      return docSettingsMatch && style.formatType === format
    }
  )
  return foundEntry?.[0] as StyleTemplateKey
}

export const getDocSettingsWithDefaults = (
  docSettings: DocSettings,
  format?: DocFormatKey | null
): DocSettingsWithDefaults => {
  const selectedStyleTemplateKey =
    format && findMatchingPageStyleTemplateKey(docSettings, format)
  const selectedStyleTemplate: StyleData | undefined =
    STYLE_TEMPLATES[selectedStyleTemplateKey || 'custom']
  const {
    animationsEnabled,
    defaultContentWidth: savedDefaultContentWidth,
    verticalAlign: savedVerticalAlign,
    presentBackdrop,
    fontSize: savedFontSize,
    cardDimensions: savedCardDimensions,
    defaultFullBleed: savedDefaultFullBleed,
  } = docSettings
  return {
    stylesDerivedFrom: docSettings.stylesDerivedFrom,
    metaDescription: docSettings.metaDescription,
    animationsEnabled: animationsEnabled || false,
    presentBackdrop: presentBackdrop || false,
    cardDimensions: savedCardDimensions || 'fluid',
    defaultFullBleed: savedDefaultFullBleed || 'contained',
    defaultContentWidth: savedDefaultContentWidth || 'md',
    fontSize: savedFontSize
      ? savedFontSize
      : selectedStyleTemplate
      ? selectedStyleTemplate.docSettings.fontSize
      : 'md',
    verticalAlign: savedVerticalAlign
      ? savedVerticalAlign
      : selectedStyleTemplate
      ? selectedStyleTemplate.docSettings.verticalAlign
      : 'center',
  }
}
