import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { selectShowDebugCardView, setShowDebugCardView } from '../reducer'

export function useDebugCardViewSync() {
  const dispatch = useAppDispatch()
  const showDebugCardView = useAppSelector(selectShowDebugCardView)

  const [isActive, setActive] = useLocalStorage('showDebugCardView', false)

  // Initialize from LS
  useEffect(() => {
    if (isActive) {
      dispatch(setShowDebugCardView({ showDebugCardView: true }))
    }
  }, [dispatch, isActive])

  // Keep LS in sync with app state
  useEffect(() => {
    setActive(showDebugCardView)
  }, [setActive, showDebugCardView])
}
