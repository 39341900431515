import type { HocuspocusProvider } from '@hocuspocus/provider'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { Doc } from 'modules/api/generated/graphql'
import { RootState } from 'modules/redux'
import type { Theme } from 'modules/theming/types'
import type { GraphqlUser } from 'modules/user/context/UserContext'
import { shouldUsePublishedVersion } from 'utils/publishing'

import { DraftCommentReply } from './extensions/Annotatable/components/types'
import { ZOOM_LEVEL_DEFAULT } from './extensions/Card/constants'
import {
  defaultAttrs,
  DocSettingsWithDefaults,
  DocumentAttributes,
} from './extensions/Document/DocumentAttrs/attributes'
import {
  getDocFormatFromAttrs,
  getDocFormatKey,
  getDocSettingsWithDefaults,
} from './extensions/Document/DocumentAttrs/utils'
import { DEFAULT_THEME } from './styles/themes'
import { CardIdMap, CardIds, CardTreeItem, EditorModeEnum } from './types'

// In the published context, we want the initial edit state to be false (it will stay that way)
// But for regular editing, we assume editing is allowed and update it later based on permissions
const defaultIsAllowedToEdit = shouldUsePublishedVersion() ? false : true

export type MemoState = {
  /**
   *  Attributes to sync to the awareness bus for the local user
   * -----------------------------------------------------------
   */

  // The editor mode that the collaborator is currently in
  mode: EditorModeEnum

  // The sessionId of the collaborator being followed
  following?: string | null

  // Whether or not the collaborator is attached to their follower
  // Only effects the case when following is set
  attached: boolean

  // What pos & percent within that pos we are scrolled
  scroll: {
    pos: number | null
    pct: number | null
  }

  // CardCollapse
  expandedCards: Record<string, boolean> // A map of card IDs -> true (expanded) / false (collapsed)
  expandedMediaId: string | null // The current media item id that is expanded, if any
  expandedToggles: Record<string, boolean | undefined> // A map of toggle IDs -> true/false expanded or null, use default for mode
}

export type Collaborator = Pick<GraphqlUser, 'id' | 'profileImageUrl'> & {
  name: string

  // The YJS awareness clientID
  clientId?: number

  // A unique identifier per browser tab (stored in SessionStorage)
  // which is persistent across page navigations and reloads
  sessionId: string

  // When a user disconnects, this field is set to indicate how long they've been gone
  idleSince: number | null

  isReady: boolean

  // If this collaborator is a presenter view collaborator
  isPresenterView: boolean

  color: string

  // The spotlight plugin pos for this collaborator (see /extensions/spotlight)
  spotlight: {
    cardId: string | null
    pos: number | null
  }

  memoState?: MemoState
}

export type TipTapState = {
  // The full list of collaborators from the YJS awareness list (provider.awareness.states)
  collaborators: Collaborator[]

  // The list of followers who just started following (appeared in the most recent dispatch)
  newFollowers: Collaborator[]

  docAttrs: DocumentAttributes
  cardIds: CardIds
  cardIdMap: CardIdMap
  theme: Theme | null

  // Whether the user is allowed to edit the doc at all
  isAllowedToEdit: boolean
  isEditingDisabled: boolean // Temporary disable editing UI, e.g. to print

  // Whether were currently editing in slide view
  isEditingInSlideView: boolean

  // Whether this editor is being used for presenter view (e.g. /docs/presenter/<docId>)
  isPresenterViewEditor: boolean

  presentModeContextMenu: { pos: number | null; cardId: string | null }

  userZoomLevel: number
  autoZoomEnabled: boolean | null // If null, use the default value

  // Whether were currently editing media
  isEditingMedia: boolean

  // The awareness sessionId of the local collaborator
  // (necessary to distinguish the same user across mulitple tabs)
  localCollaboratorId?: string
  doc?: Doc
  openBlockCommentId: string | null
  draftReplies: { [key: string]: DraftCommentReply }
  isStatic: boolean

  // Whether or not the editor should do animations
  animationsEnabled: boolean

  // A temporary flag to force disable animations
  forceDisableAnimations: boolean

  commentsEnabled: boolean

  expandedNoteId: string | null
  expandedDrawingId: string | null

  // The id of the chart currently being edited
  expandedChartId: string | null

  memoState: MemoState

  hocuspocusProvider: HocuspocusProvider | null
}

const initialState: TipTapState = {
  collaborators: [],
  newFollowers: [],
  docAttrs: defaultAttrs,
  cardIds: [],
  cardIdMap: {
    tree: {},
    parents: {},
    treeArray: [],
  },
  isAllowedToEdit: defaultIsAllowedToEdit,
  isEditingInSlideView: false,
  isPresenterViewEditor: false,
  presentModeContextMenu: {
    pos: null,
    cardId: null,
  },
  isEditingMedia: false,
  isEditingDisabled: false,
  openBlockCommentId: null,
  draftReplies: {},
  isStatic: false,
  animationsEnabled: true,
  forceDisableAnimations: false,
  commentsEnabled: false,
  expandedNoteId: null,
  expandedDrawingId: null,
  expandedChartId: null,
  theme: null,
  userZoomLevel: ZOOM_LEVEL_DEFAULT,
  autoZoomEnabled: null,
  hocuspocusProvider: null,

  // MemoState
  memoState: {
    mode: EditorModeEnum.DOC_VIEW,
    following: null,
    attached: false,
    scroll: {
      pos: null,
      pct: null,
    },
    expandedCards: {},
    expandedToggles: {},
    expandedMediaId: null,
  },
}

export const IDLE_EXPIRATION_TIME_IN_MS = 5000

const computeNewFollowers = (
  state: TipTapState,
  incomingCollaborators: Collaborator[]
): Collaborator[] => {
  const existingFollowers = state.collaborators.filter(
    (c) => c.memoState?.following === state.localCollaboratorId
  )

  // Compute the list of followers that are new on this dispatch,
  // meaning they are not already in our state.
  return incomingCollaborators.filter(
    (c) =>
      state.localCollaboratorId &&
      c.memoState?.following === state.localCollaboratorId &&
      !existingFollowers.find((f) => f.id === c.id)
  )
}

/**
 * Helper function to compute which collaborators are idle
 * based on the current list and the incoming one.
 */
const computeIdleCollaborators = (
  state: TipTapState,
  incomingCollaborators: Collaborator[]
): Collaborator[] => {
  const updateTime = +new Date()
  return state.collaborators.filter((e) => {
    const isIncoming = incomingCollaborators.find(
      (c) => c.sessionId === e.sessionId
    )
    if (isIncoming || !e.sessionId) return false

    const isExpired = e.idleSince
      ? updateTime - e.idleSince > IDLE_EXPIRATION_TIME_IN_MS
      : false

    return !isExpired
  })
}

/**
 * If we are following someone and attached, update certain properties of
 * our MemoState to mirror the person we're following.
 *
 * The behavior that we're achieving here is intentionally that our local
 * state is updated along with the person we're following. This means that
 * when we detach and are no longer following, our UI doesn't jerk back to
 * the state it was before we were following.
 *
 * Using a selector to do this dynamically (choosing our local state vs another
 * collaborator's state based on attached) would result in reverting back to
 * the point in time before we followed.
 */
const syncMemoStateIfFollowing = (state: TipTapState) => {
  const attached = selectLocalCollaboratorAttached({ TipTap: state })
  const followee = selectCollaboratorBeingFollowed({ TipTap: state })

  if (!attached || !followee?.memoState) {
    return
  }

  state.memoState.expandedCards = followee.memoState.expandedCards
  state.memoState.expandedMediaId = followee.memoState.expandedMediaId
  state.memoState.expandedToggles = followee.memoState.expandedToggles
  state.memoState.mode = followee.memoState.mode!
  state.memoState.scroll = followee.memoState.scroll

  // Future state to sync here includes:
  // - state.memoState.spotlight
}

const TipTapSlice = createSlice({
  name: 'TipTap',
  initialState,
  reducers: {
    reset: () => initialState,
    // The CollaborationCursor extension calls these viewers
    setCollaborators(
      state,
      action: { payload: { collaborators: Collaborator[] } }
    ) {
      const { collaborators } = action.payload

      const idleCollaborators = computeIdleCollaborators(
        state as TipTapState,
        collaborators
      ).map((c) => {
        // Ensure all idle collaborators have an idleSince time
        if (!c.idleSince) {
          c.idleSince = +new Date()
        }
        return c
      })

      state.newFollowers = computeNewFollowers(
        state as TipTapState,
        collaborators
      )
      state.collaborators = collaborators.concat(idleCollaborators)

      syncMemoStateIfFollowing(state as TipTapState)
    },
    setDocAttrs(
      state,
      action: { payload: { docAttrs: Partial<DocumentAttributes> } }
    ) {
      const { docAttrs } = action.payload
      Object.assign(state.docAttrs, docAttrs)
    },
    setCardIds(
      state,
      action: { payload: { cardIds: CardIds; cardIdMap: CardIdMap } }
    ) {
      const { cardIds, cardIdMap } = action.payload
      state.cardIds = cardIds
      state.cardIdMap = cardIdMap
    },
    setLocalCollaboratorId(state, action: { payload: { sessionId: string } }) {
      const { sessionId } = action.payload
      state.localCollaboratorId = sessionId
    },
    setIsAllowedToEdit(
      state,
      action: { payload: { isAllowedToEdit: boolean } }
    ) {
      const { isAllowedToEdit } = action.payload
      state.isAllowedToEdit = isAllowedToEdit
    },
    setIsEditingInSlideView(
      state,
      action: { payload: { isEditingInSlideView: boolean } }
    ) {
      const { isEditingInSlideView } = action.payload
      state.isEditingInSlideView = isEditingInSlideView
    },
    setIsPresenterViewEditor(
      state,
      action: { payload: { isPresenterViewEditor: boolean } }
    ) {
      const { isPresenterViewEditor } = action.payload
      state.isPresenterViewEditor = isPresenterViewEditor
    },
    setPresentModeContextMenu(
      state,
      action: { payload: { pos: number | null; cardId: string | null } }
    ) {
      state.presentModeContextMenu = action.payload
    },
    closePresentModeContextMenu(state) {
      state.presentModeContextMenu = { pos: null, cardId: null }
    },
    setIsEditingMedia(state, action: { payload: { isEditingMedia: boolean } }) {
      const { isEditingMedia } = action.payload
      state.isEditingMedia = isEditingMedia
    },
    setIsStatic(state, action: { payload: { isStatic: boolean } }) {
      const { isStatic } = action.payload
      state.isStatic = isStatic
    },
    setAnimationsEnabled(
      state,
      action: { payload: { animationsEnabled: boolean } }
    ) {
      const { animationsEnabled } = action.payload
      state.animationsEnabled = animationsEnabled
    },
    setForceDisableAnimations(
      state,
      action: { payload: { disable: boolean } }
    ) {
      const { disable } = action.payload
      state.forceDisableAnimations = disable
    },
    setCommentsEnabled(
      state,
      action: { payload: { commentsEnabled: boolean } }
    ) {
      const { commentsEnabled } = action.payload
      state.commentsEnabled = commentsEnabled
    },
    setMode(state, action: { payload: { mode: EditorModeEnum } }) {
      const { mode } = action.payload
      state.memoState.mode = mode
    },
    setCardsCollapsed(
      state,
      action: { payload: { cardIds: string[]; isCollapsed: boolean } }
    ) {
      const { cardIds, isCollapsed } = action.payload
      cardIds.forEach((cardId) => {
        state.memoState.expandedCards[cardId] = !isCollapsed
      })
      // Changing card collapse state always detaches
      // Following card collapse state is synced above in syncMemoStateIfFollowing
      state.memoState.attached = false
    },
    setExpandedCardsMap(
      state,
      action: { payload: { expandedCardsMap: MemoState['expandedCards'] } }
    ) {
      const { expandedCardsMap } = action.payload
      state.memoState.expandedCards = expandedCardsMap
    },
    setMediaNodeExpanded(
      state,
      action: { payload: { nodeId: string | null } }
    ) {
      const { nodeId } = action.payload
      state.memoState.expandedMediaId = nodeId ? nodeId : null

      // Changing media expanded state always detaches
      // Following media expanded state is synced above in syncMemoStateIfFollowing
      state.memoState.attached = false
    },
    setTogglesExpanded(
      state,
      action: { payload: { toggleIds: string[]; isExpanded: boolean } }
    ) {
      const { toggleIds, isExpanded } = action.payload
      toggleIds.forEach((toggleId) => {
        state.memoState.expandedToggles[toggleId] = isExpanded
      })
    },
    setExpandedNoteId(state, action: { payload: { noteId: string | null } }) {
      const { noteId } = action.payload
      state.expandedNoteId = noteId
    },
    setExpandedChartId(state, action: { payload: { chartId: string | null } }) {
      const { chartId } = action.payload
      state.expandedChartId = chartId
    },
    setExpandedDrawingId(
      state,
      action: { payload: { drawingId: string | null } }
    ) {
      const { drawingId } = action.payload
      state.expandedDrawingId = drawingId
    },
    setFollowingAttached(
      state,
      action: { payload: { attached?: boolean; following?: string | null } }
    ) {
      const { attached, following } = action.payload
      if (attached !== undefined) {
        state.memoState.attached = attached
      }
      if (following !== undefined) {
        state.memoState.following = following
      }

      syncMemoStateIfFollowing(state as TipTapState)
    },
    setDoc(state, action: { payload: { doc: Doc } }) {
      const { doc } = action.payload
      state.doc = doc
    },
    setTheme(state, action: { payload: { theme: Theme | null } }) {
      const { theme } = action.payload
      // Solves a typescript issue with readonly props being passed to redux
      // https://stackoverflow.com/questions/61828397/how-can-i-remove-the-read-only-property-of-an-object-when-i-clone-copy-it
      state.theme = JSON.parse(JSON.stringify(theme))
    },
    setScroll(
      state,
      action: { payload: { pos: number | null; pct: number | null } }
    ) {
      state.memoState.scroll = action.payload
    },
    setCommentReactionOpen(
      state,
      action: { payload: { isOpen: boolean; blockCommentId: string } }
    ) {
      const { isOpen, blockCommentId } = action.payload

      state.openBlockCommentId = isOpen ? blockCommentId : null
    },
    setIsEditingDisabled(
      state,
      action: { payload: { isEditingDisabled: boolean } }
    ) {
      const { isEditingDisabled } = action.payload
      state.isEditingDisabled = isEditingDisabled
    },
    updateDraftReply(
      state,
      action: { payload: { id: string; reply: DraftCommentReply } }
    ) {
      const { id, reply } = action.payload
      state.draftReplies[id] = reply
    },
    deleteDraftReply(state, action: { payload: { id: string } }) {
      delete state.draftReplies[action.payload.id]
    },
    setZoomLevel(state, action: { payload: { zoomLevel: number } }) {
      state.userZoomLevel = action.payload.zoomLevel
    },
    setAutoZoomEnabled(
      state,
      action: { payload: { enabled: boolean | null } }
    ) {
      state.autoZoomEnabled = action.payload.enabled
    },
    setHocuspocusProvider(
      state,
      action: { payload: { hocuspocusProvider: HocuspocusProvider | null } }
    ) {
      state.hocuspocusProvider = action.payload.hocuspocusProvider
    },
  },
})

export const {
  reset,
  setAnimationsEnabled,
  setForceDisableAnimations,
  setCollaborators,
  setCommentsEnabled,
  setCardIds,
  setDoc,
  setCardsCollapsed,
  setDocAttrs,
  setIsAllowedToEdit,
  setFollowingAttached,
  setCommentReactionOpen,
  setIsEditingDisabled,
  setIsEditingInSlideView,
  setIsPresenterViewEditor,
  setIsEditingMedia,
  setIsStatic,
  setExpandedCardsMap,
  setExpandedNoteId,
  setExpandedDrawingId,
  setExpandedChartId,
  setLocalCollaboratorId,
  setMediaNodeExpanded,
  setTogglesExpanded,
  setPresentModeContextMenu,
  closePresentModeContextMenu,
  setMode,
  setTheme,
  setScroll,
  updateDraftReply,
  deleteDraftReply,
  setZoomLevel,
  setAutoZoomEnabled,
  setHocuspocusProvider,
} = TipTapSlice.actions

type TipTapSliceState = Pick<RootState, 'TipTap'>

// Selectors

export const selectAnimationsEnabled = (state: TipTapSliceState) => {
  const editorAnimationsEnabled =
    state.TipTap.animationsEnabled && !state.TipTap.forceDisableAnimations
  // Assume animations are allowed if the docAttrs are not loaded yet or the setting is not explicitly false
  const docAnimationsEnabled =
    state.TipTap.docAttrs?.settings?.animationsEnabled !== false
  return editorAnimationsEnabled && docAnimationsEnabled
}

export const selectCommentsEnabled = (state: TipTapSliceState) =>
  state.TipTap.commentsEnabled

export const selectTheme = (state: Pick<RootState, 'TipTap' | 'Site'>) => {
  if (state.Site.site?.theme) return state.Site.site.theme
  if (state.TipTap.theme) return state.TipTap.theme

  // use the theme on the doc if there is one
  if (state.TipTap.doc?.theme) return state.TipTap.doc?.theme

  return DEFAULT_THEME
}

export const selectThemeId = (state: Pick<RootState, 'TipTap' | 'Site'>) =>
  selectTheme(state)?.id

export const selectBackground = (state: TipTapSliceState) =>
  state.TipTap.docAttrs?.background

export const selectDocAttrs = (state: TipTapSliceState) => state.TipTap.docAttrs

export const selectDocFormat = (state: TipTapSliceState) =>
  getDocFormatFromAttrs(state.TipTap.docAttrs)

export const selectCustomCode = (state: TipTapSliceState) =>
  state.TipTap.docAttrs?.customCode

export const selectDocSettingsWithDefaults = createSelector(
  (state: TipTapSliceState) => state.TipTap.docAttrs,
  (docAttrs: DocumentAttributes): DocSettingsWithDefaults => {
    const docSettings = docAttrs?.settings
    const format = getDocFormatKey(docAttrs) || 'deck'
    return getDocSettingsWithDefaults(docSettings, format)
  }
)

// Return all the main collaborators
export const selectCollaborators = (state: TipTapSliceState) =>
  state.TipTap.collaborators

// The overall state is editable if the user is allowed to edit
// AND
// the current mode/isEditingInSlideView values allow for it
// Note that callers can optionally pass in the current editor mode value
export const selectEditable = (
  state: TipTapSliceState,
  modeOverride?: EditorModeEnum
) => {
  const {
    isAllowedToEdit,
    isEditingInSlideView,
    isEditingDisabled,
    memoState: { mode },
  } = state.TipTap
  const modeToUse = modeOverride || mode
  const editingEnabled =
    modeToUse === EditorModeEnum.DOC_VIEW || isEditingInSlideView
  return isAllowedToEdit && editingEnabled && !isEditingDisabled
}

export const selectIsPresentModeAndNotEditing = (state: TipTapSliceState) => {
  return !selectEditable(state) && selectMode(state) !== EditorModeEnum.DOC_VIEW
}

export const selectIsFullyInPresentMode = (state: TipTapSliceState) => {
  const mode = selectMode(state)
  const presentingCardId = selectPresentingCardId(state) || ''
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  return (
    isPresentMode === true && selectCardIds(state).includes(presentingCardId)
  )
}

export const selectIsPresenterViewEditor = (state: TipTapSliceState) => {
  return state.TipTap.isPresenterViewEditor
}

export const selectPresentModeContextMenu = (state: TipTapSliceState) =>
  state.TipTap.presentModeContextMenu

export const selectIsPresentModeMenuOpen = (state: TipTapSliceState) => {
  const { pos, cardId } = state.TipTap.presentModeContextMenu
  return pos !== null || cardId !== null
}

export const selectIsFullyInDocMode = (state: TipTapSliceState) => {
  const mode = selectMode(state)
  const presentingCardId = selectPresentingCardId(state) || ''
  const isDocMode = mode === EditorModeEnum.DOC_VIEW
  return isDocMode === true && !selectCardIds(state).includes(presentingCardId)
}

export const selectZoomLevel = (state: TipTapSliceState) =>
  state.TipTap.userZoomLevel

export const selectAutoZoomEnabled = (state: TipTapSliceState) =>
  state.TipTap.autoZoomEnabled &&
  selectMode(state) === EditorModeEnum.SLIDE_VIEW

export const selectContentEditable = (state: TipTapSliceState) => {
  return selectEditable(state) && !selectIsAnyCommentOpen(state)
}

export const selectIsEditingDisabled = (state: TipTapSliceState) => {
  return state.TipTap.isEditingDisabled
}

export const selectIsAllowedToEdit = (state: TipTapSliceState) =>
  state.TipTap.isAllowedToEdit

export const selectIsEditingInSlideView = (state: TipTapSliceState) =>
  state.TipTap.isEditingInSlideView

export const selectIsEditingMedia = (state: TipTapSliceState) =>
  state.TipTap.isEditingMedia

export const selectMode = (state: TipTapSliceState) =>
  state.TipTap.memoState.mode

export const selectScroll = (state: TipTapSliceState) =>
  state.TipTap.memoState.scroll

export const selectIsStatic = (state: TipTapSliceState) => state.TipTap.isStatic

export const selectCardCollapsed =
  (cardId: string) => (state: TipTapSliceState) =>
    !state.TipTap.isStatic &&
    !selectCardIdMap(state).tree[cardId] && // Top-level cards should always be expanded
    !state.TipTap.memoState.expandedCards[cardId]

export const selectExpandedCardsMap = (state: TipTapSliceState) =>
  state.TipTap.memoState.expandedCards

export const selectExpandedMediaId = (state: TipTapSliceState) =>
  state.TipTap.memoState.expandedMediaId

export const selectToggleExpanded =
  (toggleId: string) => (state: TipTapSliceState) =>
    state.TipTap.memoState.expandedToggles[toggleId]

export const selectExpandedNoteId = (state: TipTapSliceState) =>
  state.TipTap.expandedNoteId

export const selectExpandedDrawingId = (state: TipTapSliceState) =>
  state.TipTap.expandedDrawingId

export const selectExpandedChartId = (state: TipTapSliceState) =>
  state.TipTap.expandedChartId

export const selectCardIds = (state: TipTapSliceState) => state.TipTap.cardIds

export const selectNumberOfCards = (state: TipTapSliceState) =>
  state.TipTap.cardIds.length

export const selectCardIdMap = (state: TipTapSliceState) =>
  state.TipTap.cardIdMap

export const selectTOCData = ({
  cardId,
  showAll = false,
}: {
  cardId?: string
  showAll?: boolean
}) =>
  createSelector(
    (state: TipTapSliceState) => state.TipTap.cardIdMap,
    (cardIdMap: CardIdMap): CardTreeItem[] => {
      const cardTree = cardIdMap.treeArray
      if (showAll) {
        return cardTree
      }
      if (!cardId) {
        return []
      }
      const ind = cardTree.findIndex((c) => c.id === cardId)
      if (ind === -1) {
        return []
      }

      return cardTree.slice(ind + 1)
    }
  )

export const selectTopLevelCardIds = createSelector(
  (state: TipTapSliceState) => state.TipTap.cardIdMap,
  (cardIdMap: CardIdMap) => {
    const { tree } = cardIdMap
    return Object.keys(tree)
  }
)

export const selectIsPresentingNestedCard = (state: TipTapSliceState) => {
  const cardIdMap = selectCardIdMap(state)
  const presentingCardId = selectPresentingCardId(state)
  return presentingCardId && !cardIdMap.tree[presentingCardId]
}

export const selectDoc = (state: TipTapSliceState) => state.TipTap.doc

export const selectDocLinks = (state: TipTapSliceState) =>
  state.TipTap.doc?.links

export const selectDocSiteHasCustomDomain = (state: TipTapSliceState) =>
  state.TipTap.doc?.site?.hasCustomDomain === true

export const selectDocWorkspace = (state: TipTapSliceState) =>
  state.TipTap.doc?.organization

export const selectDocEditors = (state: TipTapSliceState) =>
  state.TipTap.doc?.editors

export const selectDocCreator = (state: TipTapSliceState) => {
  const doc = state.TipTap.doc
  if (!doc) return null

  const { createdBy } = doc
  return createdBy
}

export const selectDocSavedTime = (state: TipTapSliceState) =>
  state.TipTap.doc?.savedTime

export const selectIsAnyCommentOpen = (state: TipTapSliceState) =>
  state.TipTap.openBlockCommentId !== null

export const selectIsBlockCommentOpen =
  (blockCommentId: string) => (state: TipTapSliceState) =>
    state.TipTap.openBlockCommentId === blockCommentId

export const selectIsOtherBlockCommentOpen =
  (blockCommentId: string) => (state: TipTapSliceState) =>
    state.TipTap.openBlockCommentId !== null &&
    state.TipTap.openBlockCommentId !== blockCommentId

export const selectDocId = (state: TipTapSliceState) => state.TipTap.doc?.id

export const selectDocOrgId = (state: TipTapSliceState) =>
  state.TipTap.doc?.organization?.id

/**
 * SELECTORS FOR PRESENT/FOLLOW SYNCING
 */

/**
 * State here will be synced across the awareness bus via `editor.commands.user(Partial<state>)`
 * See useAwarenessSync for where this happens.
 */
export const selectMemoStateToSync = (state: TipTapSliceState) => {
  return { memoState: state.TipTap.memoState }
}

// Find the collaborator in the main collaborators list
// with the same sessionId as the localCollaborator
export const selectLocalCollaborator = createSelector(
  (state: TipTapSliceState) => state.TipTap.localCollaboratorId,
  (state: TipTapSliceState) => state.TipTap.collaborators,
  (localId, collaborators) => {
    if (!localId) return
    return collaborators.find((c) => c.sessionId === localId)
  }
)

export const selectPresentingCardIdForPresenterView = (
  mainPresenterId: string | null
) =>
  createSelector(
    selectLocalCollaborator,
    (state: TipTapSliceState) => state.TipTap.collaborators,
    (localCollaborator, collaborators) => {
      if (!localCollaborator || !mainPresenterId) return
      const presenterCollaborator = collaborators.find(
        (c) =>
          c.id === localCollaborator?.id &&
          !c.isPresenterView &&
          c.sessionId === mainPresenterId
      )
      return presenterCollaborator?.spotlight.cardId || undefined
    }
  )

export const selectFollowingAttached = (state: TipTapSliceState) =>
  selectLocalCollaboratorAttached(state) &&
  selectLocalCollaboratorIsFollowingSomeone(state)

// Find the attached state for the local collaborator
export const selectLocalCollaboratorAttached = (state: TipTapSliceState) =>
  state.TipTap.memoState.attached

// Return whether or not the local collaborator is following anyone
export const selectLocalCollaboratorIsFollowingSomeone = (
  state: TipTapSliceState
) => Boolean(state.TipTap.memoState.following)

// Return whether or not anyone is following the local collaborator
export const selectSomeoneIsFollowingLocalCollaborator = createSelector(
  (state: TipTapSliceState) => state.TipTap.collaborators,
  (state: TipTapSliceState) => state.TipTap.localCollaboratorId,
  (collaborators, localCollabId) => {
    return collaborators.some(
      (c) =>
        c.sessionId !== localCollabId &&
        c.memoState?.following === localCollabId
    )
  }
)

// Find the spotlight for the local collaborator
export const selectLocalCollaboratorSpotlight = (state: TipTapSliceState) => {
  const localCollab = selectLocalCollaborator(state)
  return localCollab?.spotlight
}

// Find the collaborator entry for the user were following, if any
export const selectCollaboratorBeingFollowed = createSelector(
  (state: TipTapSliceState) => state.TipTap.collaborators,
  (state: TipTapSliceState) => state.TipTap.memoState.following,
  (collaborators, following) => {
    return [...collaborators].find((c) => c.sessionId === following)
  }
)

// Find the collaborator entry for the user were following
// and return their spotlight and scroll data
export const selectCollaboratorBeingFollowedSpotlightAndScroll = createSelector(
  selectCollaboratorBeingFollowed,
  (collab) => {
    if (!collab) return
    const collabToReturn = { ...collab }

    return {
      spotlight: collabToReturn.spotlight,
      scroll: collabToReturn.memoState?.scroll,
    }
  }
)

// Find the current cardId being presented
export const selectPresentingCardId = (state: TipTapSliceState) => {
  const localCollab = selectLocalCollaborator(state)
  return localCollab?.spotlight?.cardId
}

interface FollowerCollaboratorSet {
  followers: Collaborator[]
  collaborators: Collaborator[]
}

// Partition the main collaborators list into groups of people
// following vs not following the local collaborator.
// Remove duplicates based on the id field (the gamma User) and
// then remove any that match the provided filter.
export const selectDistinctCollaborators = (idFilter?: string) =>
  createSelector(
    (state: TipTapSliceState) => state.TipTap.collaborators,
    (state: TipTapSliceState) =>
      collaboratorIsFollowerFilter(state.TipTap.localCollaboratorId),
    (collaborators, collaboratorIsFollower): FollowerCollaboratorSet => {
      const result = [...collaborators]
        // Sort by follower count before filtering or grouping
        .sort(sortByFollowerCount(collaborators))
        // Ensure the user is ready and is not this user
        .filter((c) => c.isReady && c.id !== idFilter)
        .reduce<FollowerCollaboratorSet>(
          (acc, c) => {
            const following = collaboratorIsFollower(c)
            if (following) {
              acc.followers.push(c)
            } else {
              acc.collaborators.push(c)
            }
            return acc
          },
          { followers: [], collaborators: [] }
        )
      const followers = deDuplicateById(result.followers)
      return {
        followers,
        // Filter out collaborators who are already in the followers list,
        // which is possible when opening the same doc in multiple tabs
        collaborators: deDuplicateById(result.collaborators).filter(
          (c) => !followers.find((f) => f.id === c.id)
        ),
      }
    }
  )

export const selectNewFollowers = (state: TipTapSliceState) =>
  state.TipTap.newFollowers

export const selectDraftCommentReplies = (state: TipTapSliceState) =>
  state.TipTap.draftReplies

export const selectDraftCommentReplyForCommentId =
  (id: string) => (state: TipTapSliceState) => {
    return state.TipTap.draftReplies[id]
  }

export const selectHasDraftCommentReplies = (commentIds: string[]) =>
  createSelector(
    (state: TipTapSliceState) => state.TipTap.draftReplies,
    (draftReplies) => {
      return commentIds.some((id) => !!draftReplies[id])
    }
  )

export const selectNumConcurrentCollaborators = (state: TipTapSliceState) =>
  state.TipTap.collaborators.length

export const selectHocuspocusProvider = (state: TipTapSliceState) =>
  state.TipTap.hocuspocusProvider

export const selectHocuspocusProviderDocId = (
  state: TipTapSliceState
): string | null => {
  if (!state.TipTap.hocuspocusProvider) {
    return null
  }

  return state.TipTap.hocuspocusProvider.configuration.name || null
}

// Reducer
export const reducer = TipTapSlice.reducer

/*
 * Filter functions
 */

// Following is considered true if the collaborator is attached and
// following the provided sessionId
const collaboratorIsFollowerFilter =
  (sessionId?: Collaborator['sessionId']) => (c: Collaborator) =>
    sessionId && c.memoState?.attached && c.memoState?.following === sessionId

// Removes duplicates based on id, where the first match
// in the list is the one that will be returned
const deDuplicateById = (list: Collaborator[]) =>
  list.filter(
    (c: Collaborator, cIdx: number) =>
      list.findIndex((item) => item.id === c.id) === cIdx
  )

// For a given Collaborator, count how many users are following them (must be attached)
const getFollowerCount = (collabs: Collaborator[], collab: Collaborator) => {
  const thisSessionId = collab.sessionId
  return collabs.reduce((acc, c) => {
    const cIsFollowing =
      c.memoState?.attached && c.memoState?.following === thisSessionId
    return acc + (cIsFollowing ? 1 : 0)
  }, 0)
}

// Sorts a list of collaborators based on how many people are following (and attached)
const sortByFollowerCount =
  (collabs: Collaborator[]) => (a: Collaborator, b: Collaborator) => {
    const aCount = getFollowerCount(collabs, a)
    const bCount = getFollowerCount(collabs, b)
    return bCount - aCount
  }
