/**
 * Returns if moving in the given direction from the starting ID is in the direction of the anchor
 * */
export const isInDirectionOfAnchor = ({
  direction,
  orderedIds,
  startingId,
  anchorId,
}: {
  direction: 'up' | 'down'
  orderedIds: readonly string[]
  startingId: string | null
  anchorId: string | null
}): boolean => {
  if (!startingId || !anchorId) {
    return false
  }
  const startingIndex = orderedIds.indexOf(startingId)
  const anchorIndex = orderedIds.indexOf(anchorId)
  if (startingIndex === -1 || anchorIndex === -1) {
    return false
  }
  if (startingIndex === anchorIndex) {
    return false
  }
  if (direction === 'up') {
    return startingIndex > anchorIndex
  } else {
    return startingIndex < anchorIndex
  }
}

/**
 * Returns the ID of the last selected card, or null if none are selected.
 */
export const findLastSelectedCardId = (
  orderedCardIds: readonly string[],
  selectedCardIds: readonly string[]
): string | null => {
  if (orderedCardIds.length === 0 || selectedCardIds.length === 0) {
    return null
  }
  const selectedCardIdsSet = new Set(selectedCardIds)
  const orderedSelectedCardIds = orderedCardIds.filter((id) =>
    selectedCardIdsSet.has(id)
  )
  return orderedSelectedCardIds[orderedSelectedCardIds.length - 1] ?? null
}

/**
 * Returns the ID of the first selected card, or null if none are selected.
 */
export const findFirstSelectedCardId = (
  orderedCardIds: readonly string[],
  selectedCardIds: readonly string[]
): string | null => {
  if (orderedCardIds.length === 0 || selectedCardIds.length === 0) {
    return null
  }
  const selectedCardIdsSet = new Set(selectedCardIds)
  const orderedSelectedCardIds = orderedCardIds.filter((id) =>
    selectedCardIdsSet.has(id)
  )
  return orderedSelectedCardIds[0] ?? null
}

export const findCardAboveOrBelow = (
  orderedIds: string[],
  startingId: string | null,
  direction: 'up' | 'down'
): string | null => {
  const n = orderedIds.length
  if (n === 0 || !startingId) {
    return null
  }
  const startIndex = orderedIds.indexOf(startingId)
  if (startIndex === -1) {
    return null
  }

  return direction === 'up'
    ? orderedIds[startIndex - 1] || orderedIds[0]
    : orderedIds[startIndex + 1] || orderedIds[n - 1]
}

/**
 * find the lowest selected card BELOW the anchor
 * If none, then find the highest selected card ABOVE the anchor
 * If none, return the anchorId
 */
export const findHeadId = ({
  anchorId,
  orderedIds,
  selectedIds,
}: {
  anchorId: string | null
  readonly orderedIds: string[]
  readonly selectedIds: string[]
}): string | null => {
  if (!anchorId) {
    return null
  }

  const anchorIndex = orderedIds.indexOf(anchorId)

  if (anchorIndex === -1) {
    return null
  }

  const lastSelectedCard = findLastSelectedCardId(orderedIds, selectedIds)
  const lastSelectedCardIndex = lastSelectedCard
    ? orderedIds.indexOf(lastSelectedCard)
    : -1

  let newHeadId: string | null = null

  if (lastSelectedCardIndex > anchorIndex) {
    newHeadId = lastSelectedCard
  } else {
    newHeadId = findFirstSelectedCardId(orderedIds, selectedIds)
  }

  return newHeadId || anchorId
}
