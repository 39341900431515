import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { memoize } from 'lodash'

import { MergeCacheOperation } from 'modules/offline/apolloOfflineCache/MergeCacheOperation'
import { OfflineCacheDbAdapter } from 'modules/offline/apolloOfflineCache/OfflineCacheDbAdapter'
import Trigger from 'modules/offline/apolloOfflineCache/Trigger'
import { getStore } from 'modules/redux'

import { apolloCacheDisabled } from '../reducer'
import { OfflineCache } from './OfflineCache'

export class ApolloOfflineCache implements OfflineCache<any> {
  private store = getStore()

  private trigger: Trigger<any> | null = null

  private db: OfflineCacheDbAdapter

  private apolloClient: ApolloClient<NormalizedCacheObject> | null = null

  private clientReady: Promise<ApolloClient<NormalizedCacheObject>>

  /**
   * before we load the offline cache we save the existing cache
   * so it can be restored when we are back online.
   *
   * This is necessary because we assume anything from the online cache
   * is "recently fetched" and would mess up the cacheTimes if we kept
   * the offline cache when we come back online
   */
  private savedCache: NormalizedCacheObject | null = null

  private resolveClientReady: (
    client: ApolloClient<NormalizedCacheObject>
  ) => void

  private usingOfflineCache: boolean = false

  constructor() {
    this.clientReady = new Promise<ApolloClient<NormalizedCacheObject>>(
      (resolve) => {
        this.resolveClientReady = resolve
      }
    )

    this.db = new OfflineCacheDbAdapter()
  }

  async setClient(apolloClient: ApolloClient<NormalizedCacheObject>) {
    if (this.apolloClient) {
      console.warn('offlineMode - [ApolloOfflineCache] already has a client')
      return
    }
    this.apolloClient = apolloClient
    this.resolveClientReady(apolloClient)
  }

  async enable(): Promise<void> {
    const apolloClient = await this.clientReady

    this.trigger = new Trigger({
      cache: apolloClient.cache,
      onPersist: async (val) => {
        return this.writeCache(val)
      },
      debounce: 1000,
    })
  }

  async disable(): Promise<void> {
    console.debug(
      'offlineMode - [ApolloOfflineCache] Disabling ApolloOfflineCache'
    )
    if (!this.trigger) {
      // has never been enabled
      return
    }
    console.debug(
      'offlineMode - [ApolloOfflineCache] Purging ApolloOfflineCache'
    )

    this.db.purge()
    this.trigger?.uninstall()

    this.store.dispatch(apolloCacheDisabled())
    this.trigger = null
  }

  /**
   * Warms the apollo client cache with the offline cache
   * pauses any triggers that would persist the cache to indexeddb
   */
  async useOfflineCache(): Promise<void> {
    const apolloClient = await this.clientReady
    if (this.usingOfflineCache) {
      // already using the offline cache
      return
    }
    this.usingOfflineCache = true

    this.savedCache = apolloClient.cache.extract()
    this.trigger?.pause()

    const operation = new MergeCacheOperation(this.db)
    const result = await operation.merge(this.savedCache)
    console.debug(
      'offlineMode - [ApolloOfflineCache] Using offline cache PAUSING -- restoring cache',
      result
    )

    // dont do anything else with this operation just merge and get the data out
    apolloClient.cache.restore(result)
  }

  async backOnline() {
    if (!this.usingOfflineCache) {
      return
    }

    const apolloClient = await this.clientReady

    console.debug('offlineMode - [ApolloOfflineCache] Back online')
    apolloClient.cache.restore(this.savedCache!)

    this.savedCache = null
    this.trigger?.resume()

    this.usingOfflineCache = false
  }

  /**
   * Represents updates to the online apollo cache.  Updates here should
   * be merged with the current offline cache and then re-written to the
   * offline cache
   */
  async writeCache(onlineCache: NormalizedCacheObject): Promise<void> {
    const now = performance.now()

    const mergeOperation = new MergeCacheOperation(this.db)
    const cache = await mergeOperation.merge(onlineCache, {
      expireEntries: true,
    })

    await mergeOperation.write()

    console.debug(
      'offlineMode - [ApolloOfflineCache] wrote cache in ',
      Math.round(performance.now() - now),
      'ms',
      cache
    )
  }

  async gc(): Promise<void> {
    if (!this.apolloClient) {
      throw new Error('Cannot gc(), apollo client not set')
    }
    // just gc the apollo cache, this will clean up any dangling references
    // In the future we will rely on an apollo cache with a true ttl mechanism
    this.apolloClient.cache.gc()
  }

  async debug(): Promise<any> {
    return {
      client: this.apolloClient,
    }
  }
}

export const getApolloOfflineCache = memoize(() => new ApolloOfflineCache())
