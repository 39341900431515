import { useEffect } from 'react'
import * as workerTimers from 'worker-timers'

import { useOnConnectionEvent } from 'modules/connection/hooks/connectionHooks'

import { useUserApi } from './useUserApi'

/**
 * This hooks should contain all of the places where
 * we want to refresh the user either from
 * 1. some interval passing
 * 2. some connection event
 *
 * User actions that trigger a user refetch should
 * just be called locally in the component that
 * handled the action
 */
export const useRefreshUser = () => {
  const { fetchUser } = useUserApi()

  // Setup intervals to refresh the session cookie
  useEffect(() => {
    // Refresh the token every so often
    // Should align with server/src/identity/jwt.strategy.ts:JwtStrategy.validate
    const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 15
    const refreshTokenInterval = workerTimers.setInterval(
      fetchUser,
      REFRESH_TOKEN_INTERVAL
    )
    return () => workerTimers.clearInterval(refreshTokenInterval)
  }, [fetchUser])

  // when we wake up from a background, refetch the user
  useOnConnectionEvent('wakeup', fetchUser)
}
