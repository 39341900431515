/**
 * This file provides certain core info to subcomponents via context
 */
import { Organization } from 'modules/api'
import { getStore } from 'modules/redux'
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectDocOrMostPermissiveWorkspaceForUser,
  selectUser,
} from 'modules/user/reducer'

// TODO get rid of extra exports...
export * from './AuthContext'
export * from './UserContext'

// TODO get rid of this
export const WORKSPACE_SETTINGS_QUERY_PARAM = 'workspace-settings'

// Expose the current workspace id to non-react consumers
export const getCurrentWorkspaceId = () => {
  const state = getStore().getState()
  return selectCurrentWorkspaceId(state)
}

export const getCurrentUser = () => {
  const state = getStore().getState()
  return selectUser(state)
}

export const getCurrentWorkspace = () => {
  const state = getStore().getState()
  return selectCurrentWorkspace(state)
}

export const getDocOrMostPermissiveWorkspace = (
  docWorkspaceId?: string
): Organization | undefined => {
  const state = getStore().getState()
  return selectDocOrMostPermissiveWorkspaceForUser(docWorkspaceId)(state)
}
