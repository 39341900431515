import { useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'

import { useOnConnectionEvent } from 'modules/connection/hooks/connectionHooks'

const SHOW_ONLINE_TOAST_AFTER_MS = 1 * 60 * 1000
const BACK_ONLINE_TOAST_ID = 'back-online-toast'

export const useReconnectedToast = () => {
  const toast = useToast()

  useOnConnectionEvent('online', ({ timeOffline }) => {
    if (timeOffline && timeOffline > SHOW_ONLINE_TOAST_AFTER_MS) {
      if (!toast.isActive(BACK_ONLINE_TOAST_ID)) {
        toast({
          id: BACK_ONLINE_TOAST_ID,
          title: t`You're back online.`,
          status: 'success',
          position: 'top',
          duration: 5000,
        })
      }
    }
  })
}
