import { getStore } from 'modules/redux'

import { selectConnectionState } from './reducer'

export const getIsOnline = () => {
  const store = getStore()
  // assume that if there is no store then were online
  // this should really never happen
  if (!store) {
    return true
  }

  const { online } = selectConnectionState(store.getState())
  return online
}
