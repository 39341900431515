/**
 * Handy hook to show toasts in the UI if the user's connection to our servers is lost.
 * This could be due to:
 * - Disconnecting from WiFi network
 */
import { selectConnectionState } from 'modules/connection/reducer'
import { useFeatureFlag } from 'modules/featureFlags'
import { selectOfflineModeEnabled } from 'modules/offline/manager/reducer'
import { useAppSelector } from 'modules/redux'

export function useHealthCheck() {
  const offlineTest = useFeatureFlag('offlineTest')
  const { online } = useAppSelector(selectConnectionState)
  const offlineModeEnabled = useAppSelector(selectOfflineModeEnabled)
  if (offlineTest) {
    return {
      isConnected: false,
      isInOfflineMode: offlineModeEnabled,
    }
  }

  return {
    isConnected: online,
    isInOfflineMode: offlineModeEnabled && !online,
  }
}
