import { getIsOnline } from 'modules/connection/utils'
import { getStore } from 'modules/redux'
import type { GraphqlUser } from 'modules/user/context'

import { OFFLINE_USER_EXPIRATION_MS } from './constants'
import {
  selectOfflineManagerState,
  selectOfflineModeEnabled,
  setOfflineUser,
} from './reducer'

export const isOfflineModeEnabled = () => {
  const store = getStore()
  return selectOfflineModeEnabled(store.getState())
}

export const shouldUseOfflineUser = () => {
  const store = getStore()
  const enabled = selectOfflineModeEnabled(store.getState())

  return enabled && !getIsOnline()
}

export const getOrExpireOfflineUser = (): GraphqlUser | null => {
  const store = getStore()
  const { user, userLastSynced } = selectOfflineManagerState(store.getState())

  if (!user || !userLastSynced) {
    return null
  }

  const now = new Date().getTime()
  const lastSynced = new Date(userLastSynced).getTime()

  const isExpired = now - lastSynced > OFFLINE_USER_EXPIRATION_MS

  if (isExpired) {
    // not sure if the expired time is right here
    store.dispatch(setOfflineUser({ user: null, lastSynced: null }))
    return null
  }

  return user
}
