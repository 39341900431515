import { createSlice } from '@reduxjs/toolkit'

import { config } from 'config'
import { RootState } from 'modules/redux'

export type ConnectionState = {
  online: boolean
  navigatorOnline: boolean
  backgrounded: boolean
}

const getInitialState = () => {
  if (!config.IS_CLIENT_SIDE) {
    return {
      online: true,
      navigatorOnline: true,
      backgrounded: false,
    }
  }
  const initialOnline = window.navigator.onLine
  return {
    online: initialOnline,
    navigatorOnline: initialOnline,
    backgrounded: false,
  }
}

const ConnectionSlice = createSlice({
  name: 'Connection',
  initialState: getInitialState(),
  reducers: {
    resetConnectionState: () => getInitialState(),

    updateConnectionState: (
      state: ConnectionState,
      action: {
        payload: Partial<ConnectionState>
      }
    ) => {
      for (const key in action.payload) {
        const value = action.payload[key]
        state[key] = value
      }
    },
  },
})

export const ConnectionReducer = ConnectionSlice.reducer

export const { resetConnectionState, updateConnectionState } =
  ConnectionSlice.actions

export const selectConnectionState = (rootState: RootState) => {
  return rootState.Connection
}
