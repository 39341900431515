export const USER_SETTINGS_CONSTANTS = {
  backgroundedSince: 'backgroundedSince',
  gammaDocPasswords: 'gammaDocPasswords',
  hasClosedExamplesOnRecentTab: 'hasClosedExamplesOnRecentTab',
  hasSeenCoachBar: 'hasSeenCoachBar_v3',
  hasDismissedSearchTip: 'hasDismissedSearchTip',
  sessionId: 'gammaSessionId',
  lastDismissedPreferredBrowserMessage: 'lastDismissedPreferredBrowserMessage',
  viewPreferences: 'viewPreferences',
  lastHomeUrl: 'lastHomeUrl',
  hasDismissedDocWorkspaceNotice: 'hasDismissedDocWorkspaceNotice',
  welcomeDocId: 'welcomeDocId',
  lastAdvancedModeInputs: 'lastAdvancedModeInputs',
  generateLanguage: 'generateLanguage',
  referredByUserId: 'referredByUserId',
  gammaLocale: 'gammaLocale',
  isPseudoLocaleActive: 'isPseudoLocaleActive',
  abuseReports: 'abuseReports',
  filmstripViewPreference: 'filmstripViewPreference',
  recentlyUsedCardTemplateIds: 'recentlyUsedCardTemplateIds',
  currentWorkspaceId: 'currentWorkspaceId',
  hasSeenPresentModeDblClickNotification:
    'hasSeenPresentModeDblClickNotification',
} as const
