import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { setCardIds } from 'modules/tiptap_editor/reducer'

import { filmstripStateBuilder } from './filmstripStateBuilder'
import {
  findCardAboveOrBelow,
  findLastSelectedCardId,
  findHeadId,
  isInDirectionOfAnchor,
  findFirstSelectedCardId,
} from './helpers'

export interface FilmstripState {
  allCardIds: string[] // ordered card ids, stays in sync
  selectedCardIds: string[]
  anchorId: string | null
  lastSelection: {
    selectedCardIds: string[]
    anchorId: string | null
  }
  isFilmstripOpen: boolean
  resizedFilmstripPreviewWidth: null | number
}

const initialState: FilmstripState = {
  allCardIds: [],
  selectedCardIds: [],
  anchorId: null,
  lastSelection: {
    selectedCardIds: [],
    anchorId: null,
  },
  isFilmstripOpen: false,
  resizedFilmstripPreviewWidth: null,
}

const FilmstripSlice = createSlice({
  name: 'Filmstrip',
  initialState,
  extraReducers: (builder) => {
    // To handle cards being added / removed while selecting
    builder.addCase(setCardIds, (state, action) => {
      const {
        payload: { cardIdMap },
      } = action
      // TODO: Are we sure we want to add allCards to state?
      state.allCardIds = Object.keys(cardIdMap.tree)

      // Remember the state before deleted cards are removed from selection state for undo/redo
      state.lastSelection = {
        selectedCardIds: state.selectedCardIds,
        anchorId: state.anchorId,
      }

      // if a card was removed, remove it from selectedCards (if new cards were added, do nothing)
      state.selectedCardIds = state.selectedCardIds.filter((cardId) =>
        state.allCardIds.includes(cardId)
      )

      // If current anchor is orphaned, set a new anchor
      // preferring the first selected card and defaulting to null if none are selected
      if (state.anchorId && !state.allCardIds.includes(state.anchorId)) {
        state.anchorId = null
        for (const cardId of state.allCardIds) {
          if (state.selectedCardIds.includes(cardId)) {
            state.anchorId = cardId
            break
          }
        }
      }
    })
  },
  reducers: {
    resetSelection: (state) => {
      // Only clear selection state if there is a selection
      if (state.selectedCardIds.length !== 0) {
        state.selectedCardIds = []
      }
      if (state.anchorId !== null) {
        state.anchorId = null
      }
    },
    filmstripItemClick: (
      state,
      action: PayloadAction<{
        id: string
      }>
    ) => {
      const {
        payload: { id },
      } = action

      filmstripStateBuilder(state).clear().select(id).anchor(id)
    },
    filmstripItemShiftClick: (
      state,
      action: PayloadAction<{
        id: string
        currentFocusedCard: string | null
      }>
    ) => {
      const {
        payload: { id, currentFocusedCard },
      } = action

      const isSelected = state.selectedCardIds.includes(id)
      if (isSelected) {
        filmstripStateBuilder(state).deselect(id)
        return
      }

      if (!state.anchorId) {
        // use currentFocusedCard as anchor if none already set
        state.anchorId = currentFocusedCard
      }

      filmstripStateBuilder(state).selectFromAnchor(id)
    },
    filmstripItemCmdClick: (
      state,
      action: PayloadAction<{
        id: string
      }>
    ) => {
      const {
        payload: { id },
      } = action

      const isSelected = state.selectedCardIds.includes(id)

      if (isSelected) {
        filmstripStateBuilder(state).deselect(id)
      } else {
        filmstripStateBuilder(state).select(id)
      }
    },
    filmstripContextMenuOpen: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const {
        payload: { id },
      } = action

      if (!state.selectedCardIds.includes(id)) {
        filmstripStateBuilder(state).clear().select(id).anchor(id)
      }
    },
    filmstripItemArrowKey: (
      state,
      action: PayloadAction<{
        direction: 'up' | 'down'
        withShift: boolean
        currentFocusedCard: string | null
      }>
    ) => {
      const { direction, withShift, currentFocusedCard } = action.payload
      const { allCardIds, anchorId, selectedCardIds } = state

      let startingId = anchorId ?? currentFocusedCard
      if (withShift) {
        const headId = findHeadId({
          anchorId,
          orderedIds: allCardIds,
          selectedIds: selectedCardIds,
        })
        startingId = headId ?? currentFocusedCard
      }

      const nextCardId = findCardAboveOrBelow(allCardIds, startingId, direction)

      if (!nextCardId) {
        return
      }

      if (withShift) {
        if (
          isInDirectionOfAnchor({
            direction,
            orderedIds: allCardIds,
            startingId,
            anchorId,
          })
        ) {
          if (startingId) {
            filmstripStateBuilder(state).deselect(startingId)
          }
        } else {
          filmstripStateBuilder(state).select(nextCardId)
        }
      } else {
        filmstripStateBuilder(state)
          .clear()
          .anchor(nextCardId)
          .select(nextCardId)
      }
    },
    selectAddedFilmstripItems: (
      state,
      action: PayloadAction<{
        beforeCardId: string
        cardCount: number
      }>
    ) => {
      const { beforeCardId, cardCount } = action.payload

      const beforeCardIndex = state.allCardIds.indexOf(beforeCardId)
      if (beforeCardIndex === -1) {
        return
      }
      const fromCardId = state.allCardIds[beforeCardIndex + 1]
      if (!fromCardId) {
        return
      }

      filmstripStateBuilder(state).clear().anchor(fromCardId)

      const toCardId = state.allCardIds[beforeCardIndex + cardCount]
      if (toCardId) {
        filmstripStateBuilder(state).selectFromAnchor(toCardId)
      }
    },
    deleteFilmstripItems: (state) => {
      const { allCardIds, selectedCardIds } = state

      // Create a set of all selected card IDs for quick lookup.
      const selectedCardIdsSet = new Set(selectedCardIds)

      // Find the next card to select after the deletion.
      let nextIdToSelect: string | null = null

      // Since we want the card immediately following the selection to be selected, iterate backwards.
      for (let i = allCardIds.length - 1; i >= 0; i--) {
        const id = allCardIds[i]
        if (selectedCardIdsSet.has(id)) {
          if (i === allCardIds.length - 1) {
            // If the last card is selected, find the closest unselected card,
            // starting from the penultimate card.
            for (let j = i - 1; j >= 0; j--) {
              const prevId = allCardIds[j]
              if (!selectedCardIdsSet.has(prevId)) {
                nextIdToSelect = prevId
                break
              }
            }
          } else {
            // If this is not the last card, select the next card.
            nextIdToSelect = allCardIds[i + 1]
          }
          break
        }
      }

      // If we found a card to select, select it. Otherwise, clear the selection.
      if (nextIdToSelect === null) {
        filmstripStateBuilder(state).clear()
      } else {
        filmstripStateBuilder(state).clear().select(nextIdToSelect)
      }
    },
    onDragStartSetSelectedCards: (
      state,
      action: PayloadAction<{
        selectedCardIdsToDrag: string[]
      }>
    ) => {
      const { selectedCardIdsToDrag } = action.payload

      filmstripStateBuilder(state).clear().anchor(selectedCardIdsToDrag[0])
      for (const draggedCardId of selectedCardIdsToDrag) {
        filmstripStateBuilder(state).select(draggedCardId)
      }
    },
    selectAll: (
      state,
      action: PayloadAction<{
        currentFocusedCard: string | null
      }>
    ) => {
      const { allCardIds, anchorId } = state
      const { currentFocusedCard } = action.payload
      const newAnchorId = anchorId ?? currentFocusedCard ?? allCardIds[0]

      filmstripStateBuilder(state).clear().anchor(newAnchorId)
      for (const cardId of allCardIds) {
        filmstripStateBuilder(state).select(cardId)
      }
    },
    restoreFromUndoOrRedo: (
      state,
      action: PayloadAction<
        Pick<FilmstripState, 'selectedCardIds' | 'anchorId'>
      >
    ) => {
      const { selectedCardIds, anchorId } = action.payload

      state.lastSelection = {
        selectedCardIds: state.selectedCardIds,
        anchorId: state.anchorId,
      }
      state.selectedCardIds = selectedCardIds
      state.anchorId = anchorId
    },
    setFilmstripIsOpen(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.isFilmstripOpen = action.payload.isOpen
    },
    setFilmstripPreviewWidth(state, action: PayloadAction<{ width: number }>) {
      state.resizedFilmstripPreviewWidth = action.payload.width
    },
  },
})

type ReduxState = {
  [FilmstripSlice.name]: FilmstripState
}

export const {
  resetSelection,
  filmstripItemClick,
  filmstripItemShiftClick,
  filmstripItemCmdClick,
  filmstripContextMenuOpen,
  deleteFilmstripItems,
  filmstripItemArrowKey,
  onDragStartSetSelectedCards,
  selectAll,
  restoreFromUndoOrRedo,
  selectAddedFilmstripItems,
  setFilmstripIsOpen,
  setFilmstripPreviewWidth,
} = FilmstripSlice.actions

// Selectors
export const selectSelectedCardIds = (state: ReduxState) =>
  state.Filmstrip.selectedCardIds

export const selectLastOfSelectedCardIds = (state: ReduxState) => {
  const { allCardIds, selectedCardIds } = state.Filmstrip
  return findLastSelectedCardId(allCardIds, selectedCardIds)
}

export const selectFirstOfSelectedCardIds = (state: ReduxState) => {
  const { allCardIds, selectedCardIds } = state.Filmstrip
  return findFirstSelectedCardId(allCardIds, selectedCardIds)
}

export const selectFilmstripStateForUndo = (state: ReduxState) => {
  const { anchorId, selectedCardIds } = state.Filmstrip
  return {
    anchorId,
    selectedCardIds,
  }
}

export const selectLastFilmstripStateForUndo = (state: ReduxState) => {
  const { anchorId, selectedCardIds } = state.Filmstrip.lastSelection
  return {
    anchorId,
    selectedCardIds,
  }
}

export const selectIsFilmstripOpen = (state: ReduxState) =>
  state.Filmstrip.isFilmstripOpen

export const selectResizedFilmstripPreviewWidth = (state: ReduxState) =>
  state.Filmstrip.resizedFilmstripPreviewWidth

// Reducer
export const reducer = FilmstripSlice.reducer
