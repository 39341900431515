import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import isEqual from 'lodash/isEqual'
import { Node as ProsemirrorNode } from 'prosemirror-model'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'

import { selectCard, selectCardText } from './reducer'

export const useGetCard = (cardId?: string) => {
  return useAppSelector(selectCard(cardId), (...args) => isEqual(...args))
}

export const useGetCardText = ({
  node,
  cardId,
}: {
  node?: ProsemirrorNode
  cardId?: string
}) => {
  const cardData = useGetCard(cardId)
  const cardText = useAppSelector(selectCardText(cardId), (...args) =>
    isEqual(...args)
  )
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()
  const cardTextComputedEnabled =
    useFeatureFlag('cardTextComputedEnabled') && !shouldUsePublishedVersion
  const title = cardTextComputedEnabled
    ? cardText?.title || '' // Degraded path (content service having problems)
    : cardData?.title || (node ? getCardTitle(node.toJSON()) || '' : '') // Normal path

  return {
    title,
    text: cardTextComputedEnabled ? cardText?.text : cardData?.text,
  }
}
