import {
  DEFAULT_GAMMA_LOCALE,
  FALLBACK_LOCALES as FALLBACK_LOCALE_KEYS,
  LOCALE_ROLLOUT_MAP,
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from '../constants'

export const getNavigatorLanguage = () => {
  if (typeof window === 'undefined') return
  return window.navigator.language.toLowerCase()
}

export const isNavigatorLanguageSupported = () =>
  SUPPORTED_LOCALE_KEYS.includes(
    getNavigatorLanguage() as SupportedLocaleKey
  ) ||
  FALLBACK_LOCALE_KEYS[getNavigatorLanguage() as SupportedLocaleKey] !==
    undefined

export const isNavigatorLanguageGA = () => {
  const navLang = getNavigatorLanguage()
  const localeKey = SUPPORTED_LOCALE_KEYS.includes(
    navLang as SupportedLocaleKey
  )
    ? navLang
    : FALLBACK_LOCALE_KEYS[navLang as SupportedLocaleKey]

  return LOCALE_ROLLOUT_MAP[localeKey as SupportedLocaleKey] === 'ga'
}

export const getSupportedLocaleKeyOrUseFallback = (
  locale?: string
): SupportedLocaleKey => {
  if (locale) {
    const localeKey = locale.toLowerCase() as SupportedLocaleKey
    if (SUPPORTED_LOCALE_KEYS.includes(localeKey)) {
      return localeKey
    } else if (FALLBACK_LOCALE_KEYS[localeKey] !== undefined) {
      return FALLBACK_LOCALE_KEYS[localeKey]
    }
  }
  return DEFAULT_GAMMA_LOCALE
}

export const getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback =
  (): SupportedLocaleKey => {
    return getSupportedLocaleKeyOrUseFallback(getNavigatorLanguage())
  }
