import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { selectShowBreakpoint, setShowBreakpoint } from '../reducer'

export function useBreakpointWidgetSync() {
  const dispatch = useAppDispatch()
  const showBreakpoint = useAppSelector(selectShowBreakpoint)

  const [isActive, setActive] = useLocalStorage('showBreakpoint', false)

  // Initialize from LS
  useEffect(() => {
    if (isActive) {
      dispatch(setShowBreakpoint({ showBreakpoint: true }))
    }
  }, [dispatch, isActive])

  // Keep LS in sync with app state
  useEffect(() => {
    setActive(showBreakpoint)
  }, [setActive, showBreakpoint])
}
