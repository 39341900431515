import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type CardMenuTypes = 'styling' | 'editWithAI'

type MenuPopoverState = {
  cardId: null | string
  menuOpen: null | CardMenuTypes
}

const initialState: MenuPopoverState = {
  cardId: null,
  menuOpen: null,
}

const CardMenusSlice = createSlice({
  name: 'CardMenus',
  initialState,
  reducers: {
    toggle(
      state,
      action: PayloadAction<{ cardId: string; menuType: CardMenuTypes }>
    ) {
      const { cardId, menuType } = action.payload
      state.cardId = state.menuOpen === menuType ? null : cardId
      state.menuOpen = state.menuOpen === menuType ? null : menuType
    },
    close(state) {
      state.menuOpen = null
      state.cardId = null
    },
  },
})

export const { toggle: toggleCardMenu, close: closeCardMenu } =
  CardMenusSlice.actions

type SliceState = Pick<RootState, 'CardMenus'>

export const selectCardMenuOpen =
  (idToCheck: string, menuType: CardMenuTypes) =>
  (state: SliceState): boolean => {
    const { cardId, menuOpen } = state.CardMenus
    return menuOpen === menuType && cardId === idToCheck
  }

export const CardMenusReducer = CardMenusSlice.reducer
