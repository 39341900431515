import { useCallback } from 'react'

import { useUpdateUserSettingsMutation } from 'modules/api/generated/graphql'
import { useAppDispatch, useAppSelector, useAppStore } from 'modules/redux'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { apiFetchUser } from '../api/fetchUser'
import { GraphqlUser, UserSetting, UserSettings } from '../context/UserContext'
import {
  selectCurrentWorkspaceId,
  setCurrentWorkspaceId as reduxSetCurrentWorkspaceId,
  selectUserStatus,
  userFetched,
  selectUser,
} from '../reducer'
import { userEventEmitter } from '../UserEventEmitter'
import { generateSettingsUpdatePatch } from '../utils'

const useCurrentWorkspaceId = () => {
  const dispatch = useAppDispatch()
  const currentWorskpaceId = useAppSelector(selectCurrentWorkspaceId)
  const setCurrentWorkspaceId = (workspaceId: string) => {
    localStore.setItem(
      USER_SETTINGS_CONSTANTS.currentWorkspaceId,
      JSON.stringify(workspaceId)
    )
    dispatch(reduxSetCurrentWorkspaceId({ currentWorkspaceId: workspaceId }))
  }

  return [currentWorskpaceId, setCurrentWorkspaceId] as const
}

const usePatchUserSettings = () => {
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  return useCallback(
    async (settings: {
      set?: Partial<UserSettings>
      remove?: UserSetting[]
    }) => {
      const patch = generateSettingsUpdatePatch(settings)
      return updateUserSettings({
        variables: {
          patch,
        },
      })
    },
    [updateUserSettings]
  )
}

export const useUserApi = () => {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const [currentWorkspaceId, setCurrentWorkspaceId] = useCurrentWorkspaceId()

  /**
   * Ensures that the currentWorkspaceId matches a valid workspace for the user
   */
  const resolveCurrentWorkspaceId = useCallback(
    (user: GraphqlUser) => {
      const workspaces = user.workspaceMemberships?.map((m) => m.workspace)
      if (!workspaces || workspaces.length === 0) {
        return
      }

      const workspace = workspaces.find((w) => w?.id === currentWorkspaceId)
      if (!workspace) {
        // set the currentWorkspaceId to the first workspace if it isn't set or is invalid
        const firstWorkspace = workspaces?.[0]
        if (firstWorkspace) {
          setCurrentWorkspaceId(firstWorkspace.id)
        }
      }
    },
    [currentWorkspaceId, setCurrentWorkspaceId]
  )

  const fetchUser = useCallback(async () => {
    const prevUserStatus = selectUserStatus(store.getState())
    const response = await apiFetchUser({ returnOfflineUser: true })

    const { user, status } = response

    dispatch(userFetched({ response }))

    if (status === 'loggedIn') {
      resolveCurrentWorkspaceId(user)
    }

    // emit any events to other places in the app
    if (prevUserStatus === 'loggedOut' && status === 'loggedIn') {
      userEventEmitter.emit('signedIn', {})
    }

    userEventEmitter.emit('userFetched', {
      user,
      status,
      type: prevUserStatus === 'loading' ? 'initial' : 'refetch',
    })

    return response
  }, [dispatch, resolveCurrentWorkspaceId, store])

  const patchSettings = usePatchUserSettings()

  const updateUserSettings = useCallback(
    async (...args: Parameters<typeof patchSettings>) => {
      try {
        await patchSettings(...args)
        fetchUser()
      } catch (e) {
        const user = selectUser(store.getState())
        console.error(
          `[UpdateSettings] Error updating user settings for "${user?.id}"`,
          e.message
        )
      }
    },
    [fetchUser, patchSettings, store]
  )

  return {
    fetchUser,
    updateUserSettings,
    setCurrentWorkspaceId,
  }
}
