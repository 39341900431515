import { useCallback } from 'react'

import { getWsLink } from 'modules/api'

import { useUserSignedIn } from './useUserSignedIn'

export const useApolloConnectOnSignin = () => {
  const cb = useCallback(() => {
    // This is a hack to reconnect the subscription when a user is signed in
    // https://github.com/apollographql/subscriptions-transport-ws/issues/378
    // note that subscriptions-transport-ws is no longer maintained
    // but we can't upgrade until we upgrade apollo server
    if (!getWsLink) return
    try {
      // @ts-ignore: Property 'subscriptionClient' is private and only accessible within class 'WebSocketLink'.ts(2341)
      getWsLink().subscriptionClient.close(false, false)
    } catch (e) {
      console.error('Subscription reconnect failed', e)
    }
  }, [])

  useUserSignedIn(cb)
}
