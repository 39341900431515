import { ApolloCache } from '@apollo/client/core'

import { EventEmitter } from 'utils/EventEmitter'

export type ApolloCacheEvents = {
  write: {
    input: Parameters<typeof ApolloCache.prototype.write>[0]
  }

  evict: {
    input: Parameters<typeof ApolloCache.prototype.evict>[0]
  }

  modify: {
    input: Parameters<typeof ApolloCache.prototype.modify>[0]
  }

  gc: {}
}

export class ApolloCacheEventEmitter extends EventEmitter<ApolloCacheEvents> {}

/**
 * (Monkey)patches an ApolloCache forwarding all cache mutations
 * to an EventEmitter
 */
export const patchCache = (opts: {
  cache: ApolloCache<any>
}): { emitter: ApolloCacheEventEmitter; uninstall: () => void } => {
  const { cache } = opts
  const emitter = new ApolloCacheEventEmitter()

  const write = cache.write
  const evict = cache.evict
  const modify = cache.modify
  const gc = cache.gc

  cache.write = (input: Parameters<typeof write>[0]) => {
    const result = write.call(cache, input)
    emitter.emit('write', { input })

    return result
  }
  cache.evict = (input: Parameters<typeof evict>[0]) => {
    const result = evict.call(cache, input)
    emitter.emit('evict', { input })
    return result
  }
  cache.modify = (input: Parameters<typeof modify>[0]) => {
    const result = modify.call(cache, input)
    emitter.emit('modify', { input: input })
    return result
  }
  cache.gc = () => {
    const result = gc.call(cache)
    emitter.emit('gc', {})
    return result
  }

  const uninstall = () => {
    cache.write = write
    cache.evict = evict
    cache.modify = modify
    cache.gc = gc
  }

  return {
    emitter,
    uninstall,
  }
}
