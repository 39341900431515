import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { replaceState } from 'modules/history/history'
export const stripQueryParamsAndReplaceState = ({
  queryKeys,
}: {
  queryKeys: string[]
}) => {
  if (!queryKeys.length) {
    return // noop
  }
  const { searchParams } = new URL(window.location.href)
  queryKeys.forEach((key) => {
    searchParams.delete(key)
  })
  const query = Object.fromEntries(searchParams.entries())
  replaceState({ query, emitChange: false })
}

export const useStripQueryParams = (...queryKeys: string[]) => {
  const { isReady: isRouterReady } = useRouter()

  useEffect(() => {
    if (!isRouterReady) {
      return
    }

    stripQueryParamsAndReplaceState({ queryKeys })

    // We only want to run this effect when any of the individual
    // query key strings change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady, ...queryKeys])
}
