export const sendGTMSignupSubmitEvent = (provider: 'GOOGLE' | 'PASSWORD') => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'sign_up_submit',
    provider,
  })
}

export const sendGTMSignupCompleteEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'sign_up_complete',
  })
}

export const sendGTMPurchaseEvent = (metadata: Record<string, any> = {}) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'purchase',
    ...metadata,
  })
}
