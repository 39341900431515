import {
  Analytics,
  AnalyticsBrowser,
  Callback,
  Options,
} from '@segment/analytics-next'

import { config } from 'config'
import { ConnectionEventEmitter } from 'modules/connection/ConnectionEventEmitter'
import { getBaseDocData } from 'modules/performance/baseHoneycombDocData'
import { getUserMetadataStore } from 'modules/performance/UserMetadataStore'
import { Deferred } from 'utils/deferred'
import { isRobot } from 'utils/deviceDetection'
import { shouldUsePublishedVersion } from 'utils/publishing'
import { camelToSnakeCase } from 'utils/string'

type InitialCalls = {
  method: 'track' | 'page' | 'identify' | 'setAnonymousId'
  args: any[]
}

const shouldEnable = () => !isRobot() && !shouldUsePublishedVersion()

const userMetadataStore = getUserMetadataStore()

export class AnalyticsWrapper {
  public loadSdkPromise: Deferred<Analytics | null> = new Deferred()

  protected initialized: boolean = false

  protected sdk: Analytics | null = null

  protected initialQueue: InitialCalls[] = []
  protected enabled: boolean | null = shouldEnable()

  init() {
    if (this.initialized) {
      return
    }

    ConnectionEventEmitter.on('online', () => {
      this.enabled = true
    })
    ConnectionEventEmitter.on('offline', () => {
      this.enabled = false
    })

    AnalyticsBrowser.load({
      // no need for a writeKey, because we are using a custom API host
      writeKey: '',
      // hard code cdnSettings so we dont have to load from segment settings endpoint
      // see: https://github.com/segmentio/analytics-next/blob/v1.35.0/src/browser.ts#L56-L62
      cdnSettings: {
        integrations: {
          'Segment.io': {
            apiHost: config.EVENT_TRACKING_ENDPOINT,
          },
        },
      },
    })
      .then(
        ([sdk]) => {
          this.sdk = sdk
          return sdk
        },
        (reason) => {
          console.error('Segment load error:', reason)
        }
      )
      // Set enabled & initialized when were finally ready and then process the initialQueue
      .then(() => {
        this.initialized = true
        this.processQueue()
        this.loadSdkPromise.resolve(this.sdk)
      })
  }

  trackDocEvent(eventName, properties?: object) {
    if (!this.initialized) {
      console.error('Cannot track doc event before initialization')
      return
    }
    const docData = getBaseDocData()

    const docDataToSend: object = {}
    for (const [key, value] of Object.entries(docData)) {
      docDataToSend[camelToSnakeCase(key)] = value
    }

    this.track(eventName, {
      ...docDataToSend,
      ...properties,
    })
  }

  // track, identify, and page are the basic segment tracking options
  track(
    eventName: string,
    properties?: object,
    options?: Options,
    callback?: Callback
  ) {
    if (this.enabled === false) return

    if (!this.initialized) {
      this.initialQueue.push({
        method: 'track',
        args: [eventName, properties, options, callback],
      })
      return
    }

    return this.sdk?.track(eventName, properties, options, callback)
  }

  identify(userId?: string, traits?: object) {
    const context = userMetadataStore.get()
    const options = { context }
    if (this.enabled === false) return

    if (!this.initialized) {
      this.initialQueue.push({
        method: 'identify',
        args: [userId, traits, options],
      })
      return
    }

    this.sdk?.identify(userId, traits, options)
  }

  page(category: string, name?: string | object, properties?: object) {
    if (this.enabled === false) return

    if (!this.initialized) {
      this.initialQueue.push({
        method: 'page',
        args: [category, name, properties],
      })
      return
    }

    this.sdk?.page(category, name, properties)
  }

  setAnonymousId(anonymousId: string) {
    if (this.enabled === false) return

    if (!this.initialized) {
      this.initialQueue.push({
        method: 'setAnonymousId',
        args: [anonymousId],
      })
      return
    }

    this.sdk?.setAnonymousId(anonymousId)
  }

  protected processQueue() {
    while (this.initialQueue.length > 0) {
      const toProcess = this.initialQueue.shift()

      if (!this.enabled) continue
      ;(this[toProcess!.method] as Function).apply(this, toProcess!.args)
    }
  }
}

export const analytics = new AnalyticsWrapper()
