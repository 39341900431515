import {
  Editor,
  findChildren,
  findChildrenInRange,
  JSONContent,
  NodeWithPos,
  Node as TiptapNode,
  Mark as TiptapMark,
} from '@tiptap/core'
import { range as _range } from 'lodash'
import {
  Fragment,
  Mark,
  MarkType,
  Node,
  NodeType,
  ResolvedPos,
  Schema,
} from 'prosemirror-model'
import { EditorState, Transaction } from 'prosemirror-state'
import {
  findWrapping,
  AddMarkStep,
  AddNodeMarkStep,
  RemoveMarkStep,
  RemoveNodeMarkStep,
  ReplaceAroundStep,
  ReplaceStep,
  Step,
  AttrStep,
} from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import { getScrollManager } from 'modules/scroll'
import { CardId } from 'modules/tiptap_editor/types'
import {
  getFirstParentWithHeight,
  getViewportHeight,
  __DEBUGGING_addDebuggingOutline,
} from 'utils/dom'
import { startsWithHttp } from 'utils/link'
import { CARD_HASH_PREFIX } from 'utils/url'

import getChangedRanges, {
  ChangedRange,
} from './plugins/uniqueAttribute/helpers/getChangedRanges'

type PosAtCoords = ReturnType<EditorView['posAtCoords']>

export const isNodeEmpty = (node: Node) => {
  const hasNoChildren =
    node.type.isBlock && node.childCount === 0 && !node.isAtom
  const isEmptyTextBlock = node.isTextblock && node.content.size === 0
  const isEmpty = hasNoChildren || isEmptyTextBlock
  return isEmpty
}

export const isTreeEmpty = (node) => {
  return (
    isNodeEmpty(node) || (node.childCount == 1 && isTreeEmpty(node.child(0)))
  )
}

// Uses an undocumented method in DOM observer to fix cases where selections are jumping around when they shouldn't be. The try/catch protects us if this method is ever removed or changed.
export const suppressSelectionUpdates = (editor) => {
  try {
    // EditorView type definition doesn't include the dom observer:
    // https://github.com/ProseMirror/prosemirror-view/blob/8fc84abf6126dc9125993227414f10710f7999c2/src/input.js#L35
    // @ts-ignore
    editor.view.domObserver.suppressSelectionUpdates()
  } catch (e) {
    console.error(
      `editor.view.domObserver.suppressSelectionUpdates failed: ${e}`
    )
  }
}

export const getDomNodeFromPos = (editor: Editor, pos: number) => {
  if (editor.isDestroyed) return
  const nodeAt = editor.view.state.doc.nodeAt(pos)
  const nodeDom = editor.view.nodeDOM(pos)
  const domAtPos = editor.view.domAtPos(pos)

  if (!nodeDom && !domAtPos) {
    return
  }

  // If we found a card, prefer its nodeDom
  const cardNode = nodeAt?.type.name === 'card' && nodeDom
  const preferredNode =
    // NodeViews of size 1, like image, dont play well with domAtPos
    // while ones with children dont play well with nodeDOM 🤷
    nodeAt && nodeAt.nodeSize > 1 && domAtPos ? domAtPos.node : nodeDom

  const nodeToUse = cardNode || preferredNode || domAtPos?.node || nodeDom

  // I think this covers text nodes, which dont support getBoundingClientRect
  const finalNode =
    nodeToUse instanceof Element ? nodeToUse : nodeToUse.parentElement
  return finalNode as HTMLElement
}

export const getDomNodeFromPosAtCoords = (
  editor: Editor,
  posAtCoords: PosAtCoords
): {
  node: HTMLElement
  pos: number // The pos that was used to find the node (.pos or .inside)
} | null => {
  if (!posAtCoords) return null

  // Prefer nodeDom via inside, but fallback to domAtPos via pos
  // See https://discuss.prosemirror.net/t/domatpos-for-atom-block-nodes/3800/2
  const domInside = editor.view.nodeDOM(posAtCoords.inside) as
    | HTMLElement
    | undefined
  if (domInside) {
    return { node: domInside, pos: posAtCoords.inside }
  }

  const domPos = editor.view.domAtPos(posAtCoords.pos).node as
    | HTMLElement
    | undefined
  if (domPos) {
    return { node: domPos, pos: posAtCoords.pos }
  }

  return null
}

/**
 * Get the domNode from nodeAt, ensuring that it's not a TEXT_NODE
 */
export const getNodeDOMNonText = (editor: Editor, pos: number) => {
  const domNode = editor.view.nodeDOM(pos) as HTMLElement | undefined
  const nonTextNode =
    domNode?.nodeType === window.Node.TEXT_NODE
      ? domNode.parentElement
      : domNode

  return nonTextNode || undefined
}

/**
 * Attempt to figure out the prosemirror node and pos that is
 * at the top center of the page (with optional topOffset) by
 * starting in the middle and moving to the left if nothing
 * is found.
 */
export const getTopCenterIshNode = (
  editor: Editor,
  parentSelector: string,
  topOffset = 0,
  leftOffset = 0
): {
  node?: HTMLElement | undefined
  pos?: PosAtCoords
  posForNode?: number
} => {
  const rootEl = document.querySelector(parentSelector)
  if (!rootEl) return {}
  const rootRect = rootEl.getBoundingClientRect()
  let posToUse: PosAtCoords | undefined = undefined
  let domNodeToUse: HTMLElement | undefined = undefined
  let posForNode: number | undefined = undefined

  for (const divisor of [2, 2.5, 3, 3.5, 4, 5, 6, 7, 8]) {
    const left = leftOffset + (rootRect.width - leftOffset) / divisor
    const top = rootRect.top + topOffset
    posToUse = editor.view.posAtCoords({ left, top })
    if (!posToUse) continue

    const domNodeFromPos = getDomNodeFromPosAtCoords(editor, posToUse)
    domNodeToUse = domNodeFromPos?.node
    posForNode = domNodeFromPos?.pos
    if (domNodeToUse) {
      break
    }
  }
  return {
    node: domNodeToUse,
    pos: posToUse,
    posForNode,
  }
}

export const getTopOrBottomCenterNode = ({
  editor,
  side = 'top',
  margin = 100,
}: {
  editor: Editor
  side?: 'top' | 'bottom'
  margin?: number
}) => {
  const CHUNK_SIZE = 20
  const scrollManager = getScrollManager('editor')
  let resultPos: number | undefined = undefined
  let resultDomNode: HTMLElement | undefined = undefined
  /**
   * Call getTopCenterIshNode until we find a pos where state.nodeAt resolves.
   * Start at offset and move up/down the page in CHUNK_SIZE increments.
   */
  for (let offset = 1; offset < margin; offset = offset + CHUNK_SIZE) {
    const topOffset = side === 'top' ? offset : getViewportHeight() - offset
    const nextResult = getTopCenterIshNode(
      editor,
      scrollManager.scrollSelector,
      topOffset
    )
    if (!nextResult.pos?.pos) continue

    let nextResultPos: number | undefined = undefined
    if (editor.state.doc.nodeAt(nextResult.pos.pos)) {
      nextResultPos = nextResult.pos.pos
    } else if (editor.state.doc.nodeAt(nextResult.pos.inside)) {
      nextResultPos = nextResult.pos.inside
    }

    if (!nextResultPos) continue

    if (!resultPos || nextResultPos > resultPos) {
      resultDomNode = nextResult.node
      resultPos = nextResultPos
    }
  }
  return { pos: resultPos, node: resultDomNode }
}

/**
 * Get the pos and scroll percent of the top center node
 */
export const getTopCenterPosPct = (
  editor: Editor,
  topOffset = 0,
  leftOffset = 0
) => {
  const scrollManager = getScrollManager('editor')
  let resultPos: number | undefined = undefined
  let resultDom: HTMLElement | undefined = undefined

  const CHUNK_SIZE = 10 // How many vertical pixels to move up each time we try getTopCenterIshNode
  for (
    let offset = topOffset;
    offset > topOffset / 2;
    offset = offset - CHUNK_SIZE
  ) {
    const nextResult = getTopCenterIshNode(
      editor,
      scrollManager.scrollSelector,
      offset,
      leftOffset
    )
    if (!nextResult.posForNode) continue

    if (!resultPos || nextResult.posForNode > resultPos) {
      resultPos = nextResult.posForNode
      resultDom = nextResult.node
    }
  }

  if (!resultPos) {
    console.warn('[getTopCenterPosPct] No result for getTopCenterIshNode')
    return
  }

  const domNodeToUse = getFirstParentWithHeight(resultDom)
  if (!domNodeToUse || !resultPos) return

  __DEBUGGING_addDebuggingOutline({
    element: domNodeToUse,
    color: '#32ff61',
    requiredCookie: 'spotlightScrollDebug=true',
  })

  const domNodeToUseRect = domNodeToUse.getBoundingClientRect()
  const pct = parseFloat(
    ((topOffset - domNodeToUseRect.y) / domNodeToUseRect.height).toFixed(2)
  )
  console.debug(
    '[getTopCenterPosPct].',
    JSON.stringify({ pct, pos: resultPos }),
    resultDom
  )
  return { pos: resultPos, pct }
}

// Modeled off of https://github.com/atlassian/prosemirror-utils/blob/1b97ff08f1bbaea781f205744588a3dfd228b0d1/src/selection.js#L21
export const findParentNodes = (
  $pos: ResolvedPos,
  predicate: (node: Node, parent: Node) => boolean
) => {
  const matches = [] as {
    pos: number
    start: number
    depth: number
    node: Node
  }[]
  for (let i = $pos.depth; i > 0; i--) {
    const thisNode = $pos.node(i)
    const pos = $pos.posAtIndex(0, i - 1)
    const parentNode = $pos.doc.resolve(pos).parent
    if (predicate(thisNode, parentNode)) {
      matches.push({
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node: thisNode,
      })
    }
  }
  return matches
}

export const findParentNodeClosestToPosWithDepth = (
  $pos: ResolvedPos,
  predicate: (node: Node, depth: number) => boolean
) => {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i)
    if (predicate(node, i)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      }
    }
  }
  return
}

export const findNodeAndParents = (
  $pos: ResolvedPos,
  predicate: (node: Node, parent: Node) => boolean
): NodeWithPos[] => {
  const self = $pos.nodeAfter
  const parent = $pos.parent
  const nodes: NodeWithPos[] =
    self && predicate(self, parent) ? [{ node: self, pos: $pos.pos }] : []
  return nodes.concat(findParentNodes($pos, predicate))
}

/**
 * Modeled off of doc.hasMarkRange: https://github.com/ProseMirror/prosemirror-model/blob/b71f73f193b15ab1661451636352905b06a6fb0d/src/node.ts#L212-L221
 * but instead of to > from, use to >= from to include empty selections
 */
export const markHasRangeWithEmptySelection = (
  doc: Node,
  from: number,
  to: number,
  type: MarkType | Mark
) => {
  let found = false
  if (to >= from)
    doc.nodesBetween(from, to, (node) => {
      if (type.isInSet(node.marks)) found = true
      return !found
    })
  return found
}

// Handles stringifying/parsing complex attributes
export const configureJSONAttribute = (attrName: string) => {
  return {
    parseHTML: (el) => {
      const value = el.getAttribute(`data-${attrName}`)
      if (!value) return
      return JSON.parse(value)
    },
    renderHTML: (attrs) => {
      const attr = attrs[attrName]
      if (!attr) return {}
      return {
        [`data-${attrName}`]: JSON.stringify(attr),
      }
    },
  }
}

// When an attribute is an object with keys one level deep,
// pull each key into its own attribute.
export const configureObjectAttribute = (attrName: string) => {
  return {
    parseHTML: (el) => {
      const attrs = {}
      Object.keys(el.dataset).forEach((key) => {
        const keyParts = key.split('.')
        if (keyParts.length !== 2 || keyParts[0] !== attrName) return
        attrs[keyParts[1]] = el.dataset[key]
      })
      return attrs
    },
    renderHTML: (attrs) => {
      const obj = attrs[attrName]
      if (!obj) return {}
      const dataAttrs = {}
      Object.entries(obj).forEach(([key, value]) => {
        dataAttrs[`data-${attrName}.${key}`] = value
      })
      return dataAttrs
    },
  }
}

export const getCardIdFromHash = (url: string): string | null => {
  if (!url.length || !startsWithHttp(url)) return null
  try {
    const hash = new URL(url).hash
    const cardId = hash?.split(CARD_HASH_PREFIX)?.[1] || null
    return cardId
  } catch {
    return null
  }
}

export const doesMemoContainGivenCardFromUrl = (
  url: string,
  cardIds: CardId[]
) => {
  const cardId = getCardIdFromHash(url)
  return cardId ? cardIds.includes(cardId) : false
}

// Based on https://github.com/ProseMirror/prosemirror-model/blob/95298fb02744e1a8f41eae50f8a6afde583a8817/src/fragment.js#L46
// Only includes nodes that match `nodePredicate`, skipping over any that don't, including their children
export const textBetweenFiltered = (
  root: Node,
  from: number,
  to: number,
  nodePredicate: (node: Node) => boolean,
  blockSeparator: string,
  leafText?: string
) => {
  let text = '',
    separated = true
  root.nodesBetween(
    from,
    to,
    (node, pos): false | void => {
      if (!nodePredicate(node)) return false // Prevents children from being iterated over
      if (node.isText && node.text) {
        text += node.text.slice(Math.max(from, pos) - pos, to - pos)
        separated = !blockSeparator
      } else if (node.isLeaf && leafText) {
        text += leafText
        separated = !blockSeparator
      } else if (!separated && node.isBlock) {
        text += blockSeparator
        separated = true
      }
    },
    0
  )
  return text
}

export const editorHasFocus = (editor: Editor) => {
  return (
    editor.view.hasFocus() ||
    Boolean(document.activeElement?.closest('[data-in-editor-focus]')) // Add this attr to drawers/popovers that are still considered part of the editor
  )
}

// Checks whether a node can be inserted at a given point
// Used in the slash menu and insert widget
export const canInsertNodeAtSelection = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  const canInsertAtCursor = $from.parent.canReplaceWith(
    $from.index(),
    $from.index(),
    nodeType
  )
  const canInsertAfterBlock = range.parent.canReplaceWith(
    range.endIndex,
    range.endIndex,
    nodeType
  )

  return canInsertAfterBlock || canInsertAtCursor
}

// Checks whether a node can be replaced with a given type
// Used in the formatting menu
export const canChangeSelectedNodeType = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  const canReplaceWithBlock = range.parent.canReplaceWith(
    range.startIndex,
    range.endIndex,
    nodeType
  )

  return canReplaceWithBlock
}

// Checks whether the selected range can be wrapped in the given node
// Used in the formatting menu
export const canWrapSelection = (editor: Editor, nodeName: string) => {
  const { selection, schema } = editor.state
  const { $from } = selection
  const range = $from.blockRange(selection.$to)
  const nodeType = schema.nodes[nodeName]
  if (!range) return false

  return !!findWrapping(range, nodeType)
}

export const selectionHasMatchingNodes = (
  editor: Editor,
  predicate: (node: Node) => boolean
) => {
  const { selection, doc } = editor.state
  const { from, to } = selection
  const nodes = findChildrenInRange(doc, { from, to }, predicate)
  return nodes.length > 0
}

export const selectionAllowsMark = (editor: Editor, markName: string) => {
  return selectionHasMatchingNodes(editor, (n) =>
    n.type.allowsMarkType(editor.schema.marks[markName])
  )
}

export const selectionAllowsAttr = (editor: Editor, attrName: string) => {
  return selectionHasMatchingNodes(
    editor,
    (n) => !!n.type.spec.attrs?.[attrName]
  )
}

export const fragmentToArray = (fragment: Fragment): Node[] => {
  return _range(fragment.childCount).map((i) => fragment.child(i))
}

export const rectAtPos = (
  pos: number,
  view: EditorView
): DOMRect | undefined => {
  const dom = view.nodeDOM(pos) as HTMLElement | null
  return dom?.getBoundingClientRect()
}

export const hasImageNodeWithNoSrc = (node) => {
  if (!node) return false
  return (
    findChildren(node, (n) => n.type.name === 'image' && !n.attrs.src).length >
    0
  )
}

export const findDirectChildren = (
  node: Node,
  predicate: (node: Node) => boolean
): NodeWithPos[] => {
  const nodesWithPos: NodeWithPos[] = []
  node.forEach((child, offset) => {
    if (predicate(child)) {
      nodesWithPos.push({ node: child, pos: offset })
    }
  })
  return nodesWithPos
}

// breadth first search of JSONContent function
export const traverseJSONContent = (
  content: JSONContent,
  fn: (content: JSONContent) => void
) => {
  content.forEach((node) => {
    fn(node)
  })
  content.forEach((node) => {
    if (node.content) {
      traverseJSONContent(node.content, fn)
    }
  })
}

export const findExtensionFromNodeType = (
  editor: Editor,
  type: NodeType | MarkType
) => {
  const extension = editor.extensionManager.extensions.find(
    (e) => e.name === type.name
  ) as TiptapNode | TiptapMark | undefined
  return extension
}

/**
 * Checks if at least one step of the transaction replaced the whole document.
 */
export function isReplaceDoc(tr: Transaction): boolean {
  const docEnd = tr.before.content?.size ?? 0
  const mapping = tr.mapping

  for (let i = 0; i < tr.steps.length; i++) {
    const step = tr.steps[i]
    const map = mapping.slice(0, i)
    if (
      step instanceof ReplaceStep &&
      step.from === 0 &&
      map.map(step.to) === map.map(docEnd) &&
      step.slice.content.childCount === 1 &&
      step.slice.content.child(0).type.name === 'document'
    ) {
      return true
    }
  }

  return false
}

export function isReplaceStep(
  step: Step
): step is ReplaceStep | ReplaceAroundStep {
  return step instanceof ReplaceStep || step instanceof ReplaceAroundStep
}

export function isMarkStep(
  step: Step
): step is AddMarkStep | RemoveMarkStep | AddNodeMarkStep | RemoveNodeMarkStep {
  return (
    step instanceof AddMarkStep ||
    step instanceof RemoveMarkStep ||
    step instanceof AddNodeMarkStep ||
    step instanceof RemoveNodeMarkStep
  )
}

export function isAttrStep(step: Step): step is AttrStep {
  return step instanceof AttrStep
}

export interface ProcessTransactionCallback {
  (props: {
    node: Node
    pos: number
    tr: Transaction
    schema: Schema
    parent: Node | null
  }): boolean
}

/**
 * Finds which nodes in the `newState` document were modified by `transactions`
 * and calls `processChangedNode` with each of them.
 *
 * `processChangedNode` will receive a Transaction `tr` which will be returned
 * by this function if it will modify the doc.
 *
 * @return - The transaction with the steps added by `processChangedNode` or
 * `null` if the transaction does not modify the doc.
 */
export function processTransactionsChangedNodes(
  transactions: readonly Transaction[],
  newState: EditorState,
  processChangedNode: ProcessTransactionCallback
): Transaction | null {
  /**
   * Maps the positions of `range` to point to `newState`.doc
   *
   * @param startIx - The index of the transaction this ranges represent.
   */
  const mapRangeThrough = (range: ChangedRange, startIx: number) => {
    const mappedRange = { ...range }

    // Ranges represent the positions after all previous transactions have been
    // applied. This loop maps them through the remaining transactions.
    for (let i = startIx; i < transactions.length; i++) {
      const transaction = transactions[i]

      if (
        transaction.getMeta('processTransactions') ||
        !transaction.docChanged
      ) {
        continue
      }

      Object.keys(mappedRange).forEach((k) => {
        mappedRange[k] = transaction.mapping.map(mappedRange[k])
      })
    }

    return mappedRange
  }

  // Get the ranges that each transaction touched and map them to newState.
  const ranges = transactions.flatMap((transaction, ix) =>
    getChangedRanges(transaction).map((range) => mapRangeThrough(range, ix + 1))
  )

  if (!ranges.length) {
    return null
  }

  const { doc, tr, schema } = newState

  // This maps which nodes we alredy passed to the callback so we don't do it
  // again in the case different transactions have overlapping ranges.
  const processedPositions = new Set<number>()

  // Get a slice of the doc for each changed range and calls
  // `processChangedNode` once for each node within those slices.
  ranges.forEach((range) => {
    const slice = doc.slice(range.newStart, range.newEnd)
    slice.content.descendants((node, pos, parent) => {
      const mappedPos = pos + range.newStart - slice.openStart
      if (processedPositions.has(mappedPos)) {
        return
      }

      processChangedNode({ node, pos: mappedPos, tr, schema, parent })
      processedPositions.add(mappedPos)
    })
  })

  tr.setMeta('processTransactions', true)

  // If the transaction changes the doc, return it so the caller can apply it.
  if (tr.docChanged) {
    return tr
  }

  return null
}
