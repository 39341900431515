import { FilmstripState } from './reducer'

class FilmstripStateBuilder {
  constructor(private state: FilmstripState) {}

  anchor(id: string): this {
    if (!this.validateCardId(id)) {
      return this
    }

    this.state.anchorId = id
    return this
  }

  select(id: string): this {
    if (!this.validateCardId(id)) {
      return this
    }

    if (this.state.selectedCardIds.includes(id)) {
      return this
    }

    this.state.selectedCardIds.push(id)
    return this
  }

  deselect(id: string): this {
    if (!this.validateCardId(id)) {
      return this
    }

    if (this.state.selectedCardIds.includes(id)) {
      const ind = this.state.selectedCardIds.indexOf(id)
      this.state.selectedCardIds.splice(ind, 1)
      return this
    }

    return this
  }

  clear(): this {
    this.state.selectedCardIds = []
    return this
  }

  selectFromAnchor(id: string): this {
    if (!this.validateCardId(id)) {
      return this
    }
    if (!this.state.anchorId || !this.validateCardId(this.state.anchorId)) {
      return this
    }

    const anchorIndex = this.state.allCardIds.indexOf(this.state.anchorId)
    const headIndex = this.state.allCardIds.indexOf(id)
    const from = Math.min(anchorIndex, headIndex)
    const to = Math.max(anchorIndex, headIndex)
    const toSelect = this.state.allCardIds.slice(from, to + 1)

    for (const cardId of toSelect) {
      this.select(cardId)
    }
    return this
  }

  private validateCardId(id: string | null): boolean {
    if (!id) {
      return false
    }
    return this.state.allCardIds.includes(id)
  }
}

export const filmstripStateBuilder = (state: FilmstripState) =>
  new FilmstripStateBuilder(state)
