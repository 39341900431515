import { BackgroundOptions, BackgroundType } from './types'

export const LIST_BLOCK_CLASSES = '.block-bullet, .block-numbered, .block-todo'
export const LIST_VERTICAL_MARGIN = '0.35em'

export const BLOCK_MARGIN = (isMobileDevice: boolean) =>
  isMobileDevice ? '0.75em' : '1.125em' // Vertical padding between all blocks

// The first item in this array is for the smallest viewport width.
export const FONT_SIZES = {
  blockquote: '1.15em',
  codeBlock: ['0.85em', '1em'],
}

export const HEADING_MARGINS = (isMobileDevice: boolean) => {
  return {
    default: {
      mt: isMobileDevice ? '1em' : '1.5em',
      mb: isMobileDevice ? '1em' : '1.5em',
    },
    compact: {
      mt: isMobileDevice ? '0.8em' : '1em',
      mb: isMobileDevice ? '0.8em' : '1em',
    },
    relaxed: {
      mt: isMobileDevice ? '1.6em' : '2em',
      mb: isMobileDevice ? '1.6em' : '2em',
    },
    tight: {
      mt: '0.6em',
      mb: '0.6em',
    },
  }
}

export const PARAGRAPH_MARGINS = (isMobileDevice: boolean) => {
  return {
    compact: {
      my: isMobileDevice ? '0.8em' : '0.9em',
      [`:is(${LIST_BLOCK_CLASSES})`]: {
        my: LIST_VERTICAL_MARGIN,
      },
    },
    tight: {
      my: '0.6em',
      [`:is(${LIST_BLOCK_CLASSES})`]: {
        my: '0.3em',
      },
    },
  }
}

// By default, docs should inherit from the theme's background
export const DEFAULT_DOC_BACKGROUND = {
  type: BackgroundType.NONE,
  source: undefined,
}

export const DEFAULT_THEME_BACKGROUND = {
  type: BackgroundType.COLOR,
  color: {
    hex: '#FAFAFA',
  },
}

export const DEFAULT_ACCENT_IMAGE_BACKGROUND: BackgroundOptions = {
  type: BackgroundType.IMAGE,
  source: 'image.custom',
}
