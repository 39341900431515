import { t } from '@lingui/macro'
import { sortBy } from 'lodash'

// Pseudo locale setup for debugging/QA purposes
export const PSEUDO_LOCALE_KEY = 'pseudo-LOCALE'
export const DEFAULT_GAMMA_LOCALE = 'en'

/**
 * The status of a locale rollout.
 * - `private_beta`: You must be feature flagged to be able to choose this locale.
 *                   Reserved for early translations of new locales being developed.
 *
 * - `public_beta`:  Any user can choose this locale on an opt-in basis.
 *
 * - `ga`:           We default new users to this locale if their navigator.locale matches.
 *                   We prompt/notify existing users that this locale is available if they dont have one set.
 *
 * See https://gamma.app/docs/5ubhk5zewalv11t#card-0enl3iw76n0o8w6
 */
export type LocaleRolloutStatus = 'private_beta' | 'public_beta' | 'ga'

export type SupportedLocaleKey = typeof SUPPORTED_LOCALE_KEYS[number]

export const LOCALE_ROLLOUT_MAP: Record<
  keyof typeof SUPPORTED_LOCALE_OPTIONS,
  LocaleRolloutStatus
> = {
  de: 'public_beta', // German
  en: 'ga', // English (source language)
  es: 'ga', // Spanish
  fr: 'ga', // French
  id: 'public_beta', // Indonesian
  ja: 'ga', // Japanese
  ko: 'ga', // Korean
  'pt-br': 'ga', // Portuguese (Brazil)
  ru: 'public_beta', // Russian
  th: 'public_beta', // Thai
  tr: 'public_beta', // Turkish
  'zh-cn': 'ga', // Simplified Chinese
  'zh-tw': 'ga', // Traditional Chinese
} as const

// Locales for which we have translations.
export const SUPPORTED_LOCALE_KEYS = [
  DEFAULT_GAMMA_LOCALE,
  'pt-br',
  'de',
  'id',
  'ja',
  'zh-cn',
  'zh-tw',
  'es',
  'fr',
  'ko',
  'ru',
  'th',
  'tr',
  PSEUDO_LOCALE_KEY,
] as const

// If we don't support a locale, always fall back to English.
export const DEFAULT_FALLBACK_LOCALE = DEFAULT_GAMMA_LOCALE

// If a translation is missing, look in the default locale for the translation
// languages from http://www.lingoes.net/en/translator/langcode.htm
// No need to map English regions because the default locale is En.
export const FALLBACK_LOCALES: Record<string, SupportedLocaleKey> = {
  default: DEFAULT_FALLBACK_LOCALE,
  PSEUDO_LOCALE_KEY: DEFAULT_FALLBACK_LOCALE,

  // German
  'de-at': 'de',
  'de-be': 'de',
  'de-li': 'de',
  'de-lu': 'de',
  'de-ch': 'de',

  'ja-jp': 'ja',
  'ko-kr': 'ko',

  // French
  'fr-be': 'fr',
  'fr-ca': 'fr',
  'fr-ch': 'fr',
  'fr-fr': 'fr',
  'fr-lu': 'fr',
  'fr-mc': 'fr',

  'pt-pt': 'pt-br',

  // simplified chinese
  'zh-hans': 'zh-cn',
  'zh-sg': 'zh-cn',
  zh: 'zh-cn',

  // traditional chinese
  'zh-hant': 'zh-tw',
  'zh-hk': 'zh-tw',
  'zh-mo': 'zh-tw',

  // spanish
  'es-ar': 'es',
  'es-bo': 'es',
  'es-cl': 'es',
  'es-co': 'es',
  'es-cr': 'es',
  'es-do': 'es',
  'es-ec': 'es',
  'es-es': 'es',
  'es-gt': 'es',
  'es-hn': 'es',
  'es-mx': 'es',
  'es-ni': 'es',
  'es-pa': 'es',
  'es-pe': 'es',
  'es-pr': 'es',
  'es-py': 'es',
  'es-sv': 'es',
  'es-uy': 'es',
  'es-ve': 'es',
} as const

type SupportedLocaleKeyWithoutPseudoLocale = Exclude<
  SupportedLocaleKey,
  typeof PSEUDO_LOCALE_KEY
>
// These will show in the language switcher.
export const SUPPORTED_LOCALE_OPTIONS: Record<
  SupportedLocaleKeyWithoutPseudoLocale,
  {
    key: SupportedLocaleKey
    displayName: string // The name of the language in the language itself
    displayNameLocalized: () => string // The name of the language localized to the current locale (written in English and wrapped for translation)
    contextName: string // The name of the language when used in a sentence
  }
> = {
  // IMPORTANT! Do NOT add pseudo-LOCALE in here.
  [DEFAULT_GAMMA_LOCALE]: {
    key: DEFAULT_GAMMA_LOCALE,
    displayName: 'English',
    displayNameLocalized: () => t`English`,
    contextName: 'English',
  },
  de: {
    key: 'de',
    displayName: 'Deutsch',
    displayNameLocalized: () => t`German`,
    contextName: 'Deutsch',
  },
  'pt-br': {
    key: 'pt-br',
    displayName: 'Português (Brasil)',
    displayNameLocalized: () => t`Portuguese (Brazil)`,
    contextName: 'português',
  },
  id: {
    key: 'id',
    displayName: 'Bahasa Indonesia',
    displayNameLocalized: () => t`Indonesian`,
    contextName: 'Bahasa Indonesia',
  },
  ja: {
    key: 'ja',
    displayName: '日本語',
    displayNameLocalized: () => t`Japanese`,
    contextName: '日本語',
  },
  'zh-cn': {
    key: 'zh-cn',
    displayName: '简体中文',
    displayNameLocalized: () => t`Chinese (Simplified)`,
    contextName: '简体中文',
  },
  'zh-tw': {
    key: 'zh-tw',
    displayName: '繁體中文',
    displayNameLocalized: () => t`Chinese (Traditional)`,
    contextName: '繁體中文',
  },
  es: {
    key: 'es',
    displayName: 'Español',
    displayNameLocalized: () => t`Spanish`,
    contextName: 'español',
  },
  fr: {
    key: 'fr',
    displayName: 'Français',
    displayNameLocalized: () => t`French`,
    contextName: 'français',
  },
  ko: {
    key: 'ko',
    displayName: '한국어',
    displayNameLocalized: () => t`Korean`,
    contextName: '한국어',
  },
  ru: {
    key: 'ru',
    displayName: 'Русский',
    displayNameLocalized: () => t`Russian`,
    contextName: 'Русский',
  },
  th: {
    key: 'th',
    displayName: 'ไทย',
    displayNameLocalized: () => t`Thai`,
    contextName: 'ไทย',
  },
  tr: {
    key: 'tr',
    displayName: 'Türkçe',
    displayNameLocalized: () => t`Turkish`,
    contextName: 'Türkçe',
  },
} as const

export const getSupportedLocaleOptionsSorted = (
  canUsePrivateBetaLocales: boolean
) =>
  sortBy(Object.values(SUPPORTED_LOCALE_OPTIONS), (l) =>
    l.displayNameLocalized()
  ).sort((a, b) => {
    if (a.key === DEFAULT_GAMMA_LOCALE) {
      return -1
    }
    if (b.key === DEFAULT_GAMMA_LOCALE) {
      return 1
    }
    const aStatus = LOCALE_ROLLOUT_MAP[a.key]
    const bStatus = LOCALE_ROLLOUT_MAP[b.key]

    if (canUsePrivateBetaLocales || aStatus === bStatus) {
      return 0
    }

    // If we can't use private beta locales, sort them to the bottom.
    // They'll be displayed as "Coming soon" in the UI.
    if (aStatus === 'private_beta') {
      return 1
    } else if (bStatus === 'private_beta') {
      return -1
    }

    return 0
  })

export const FORCE_LANG_QUERY_PARAM = 'lang'
