import { useEffect } from 'react'

import { userEventEmitter } from '../UserEventEmitter'

/**
 * Hook that listens for signedIn events and invokes passed-in callback
 */
export const useUserSignedIn = (callback: () => void) => {
  useEffect(() => {
    return userEventEmitter.on('signedIn', callback)
  }, [callback])
}
