import { Organization } from 'modules/api'
import { featureFlags } from 'modules/featureFlags'
import { getCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'
import { getStore } from 'modules/redux'

import { DEFAULT_CREDIT_COSTS } from './constants'
import { selectAvailableCredits } from './reducer'
import { LowCreditStatus, CreditCostTypes } from './types'

export const getCreditThresholdStatus = (
  credits: number | null,
  interactionCost: number,
  numOfInteractions: number
): LowCreditStatus | null => {
  if (credits === null) {
    return null
  }
  if (credits <= 0) {
    return LowCreditStatus.EXHAUSTED
  }
  if (credits <= interactionCost * numOfInteractions) {
    return LowCreditStatus.LOW
  }
  return null
}

export const checkCredits = (
  type: CreditCostTypes,
  workspace?: Organization
): boolean => {
  const hasUnlimitedCredits = getCanUseProductFeature(
    'unlimited_credits',
    workspace
  )
  const creditCosts = featureFlags.get('aiDebits')
  const value = creditCosts[type] ?? DEFAULT_CREDIT_COSTS[type]
  if (value === 0 || hasUnlimitedCredits) return true

  const store = getStore()
  const state = store.getState()
  const availableCredits = selectAvailableCredits(state)
  return !!availableCredits && availableCredits >= value
}
